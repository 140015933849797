import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { projectsActions } from '../projects-duck';
import UserTableRow from './UserTableRow';
import AddUserForm from '../../invites/AddUserForm';
import { Modal } from '../../ui/modal';
import {
  ProjectUsersHeaderText,
  ProjectDetailsStage,
  ProjectUsersHeader,
  ProjectUsersTable,
  TableRow,
  TableHead,
  AddButton,
  AddIcon,
  AddText,
} from './styles';

function ProjectUsers({ users, channels, projectId, isProjectAdmin, isProjectOwner }) {
  const { isGlobalAdmin, userId } = useSelector((state) => state.auth);
  const [isAddUserFormOpen, setIsAddUserFormOpen] = useState(false);
  const toggleAddUserForm = (state) => setIsAddUserFormOpen(state);
  const dispatch = useDispatch();

  const handleAddUserSuccess = () => {
    if (isGlobalAdmin) {
      dispatch(projectsActions.readAll(true, null));
    } else {
      dispatch(projectsActions.readAll(false, userId));
    }
    toggleAddUserForm(false);
  };

  return (
    <ProjectDetailsStage>
      <ProjectUsersHeader>
        <ProjectUsersHeaderText>Users and Roles</ProjectUsersHeaderText>
        {(isGlobalAdmin || isProjectAdmin || isProjectOwner) && (
          <AddButton type="submit" onClick={() => toggleAddUserForm(true)}>
            <AddIcon className="far fa-plus" />
            <AddText>User</AddText>
          </AddButton>
        )}
      </ProjectUsersHeader>
      <ProjectUsersTable>
        <TableHead>
          <TableRow>
            <td>Name</td>
            <td>Email</td>
            <td>Phone</td>
            <td>Channels</td>
            <td>Access Level</td>
            <td>Project Role</td>
            <td>Status</td>
            <td>Actions</td>
          </TableRow>
        </TableHead>
        <tbody>
          {users.map((user) => (
            <UserTableRow user={user} key={user.id} channels={channels} projectId={projectId} />
          ))}
        </tbody>
      </ProjectUsersTable>
      <Modal isOpen={isAddUserFormOpen} onRequestClose={() => toggleAddUserForm(false)}>
        <AddUserForm
          onFinish={handleAddUserSuccess}
          projectChannels={channels}
          projectId={projectId}
          isProjectOwner={isProjectOwner}
        />
      </Modal>
    </ProjectDetailsStage>
  );
}

export default ProjectUsers;

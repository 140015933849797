import { useSelector } from 'react-redux';
import styled from 'styled-components';

function ProjectsNav() {
  const { name = '' } = useSelector((state) => state.auth);
  return (
    <StyledProjectsNav>
      <StyledProjectsNavInner>
        <NavHeader>Hello {name.split(' ')[0]}</NavHeader>
        <NavList>
          <NavItem active>All Projects</NavItem>
        </NavList>
      </StyledProjectsNavInner>
    </StyledProjectsNav>
  );
}

const NavHeader = styled.h4`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 20px;
`;

const StyledProjectsNav = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0px;
  overflow: hidden;
  border-bottom: 1px solid #d4d4d4;
  background: #fff;
`;

const StyledProjectsNavInner = styled.div`
  height: 80px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const NavList = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0px;
  padding: 0px;
  align-items: flex-end;
}
`;
const NavItem = styled.li`
  margin-right: 20px;
  padding-bottom: 2px;
  cursor: pointer;
  border-bottom: ${(props) => (props.active ? '1px solid #000000' : 'inherit')};
`;
export default ProjectsNav;

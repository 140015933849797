import { CallAvatar } from '../styles';
import { Modal } from '../../ui/modal';
import {
  IncomingCallModalRow,
  DeclineButton,
  AcceptButton,
  IncomingCallModalMessage,
  IncomingCallModalActions,
} from './styles';

function IncomingCallModal({ close, isOpen, invitingUser, acceptCallHandler }) {
  const url = invitingUser
    ? invitingUser.profile && invitingUser.profile[0]?.profilePictureUrl
      ? invitingUser.profile[0]?.profilePictureUrl
      : `https://ui-avatars.com/api/?name=${encodeURIComponent(
          invitingUser?.profile[0]?.name ?? invitingUser.name
        )}`
    : '';
  return (
    <Modal
      onRequestClose={close}
      isOpen={isOpen}
      content={{ backgroundColor: '#000000', color: '#ffffff', width: 400 }}
      closeIcon={false}
    >
      <IncomingCallModalRow>
        <CallAvatar src={url} />
      </IncomingCallModalRow>
      <IncomingCallModalMessage>{`${
        invitingUser?.profile[0]?.name ?? invitingUser?.name
      } is calling you`}</IncomingCallModalMessage>
      <IncomingCallModalActions>
        <DeclineButton onClick={close} type="submit">
          Decline
        </DeclineButton>
        <AcceptButton type="submit" onClick={acceptCallHandler}>
          Answer
        </AcceptButton>
      </IncomingCallModalActions>
    </Modal>
  );
}

export default IncomingCallModal;

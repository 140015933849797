import { useState } from 'react';
import { Modal } from '../../ui/modal';
import { ErrorIcon } from '../../ui/iconography';
import { InputLabel, PrimaryButton, TextInput } from '../../ui/inputs';
import { AnchorLink, ErrorText, ModalHeading, SuccessText } from '../../ui/texts';
import {
  NewProjectRow,
  ProjectAvatarImage,
  ModalButtonsGroup,
  HiddenFileInput,
  EmptyAvatarImage,
} from './styles';
import backHttpClient from '../../../app/backend-http-client';
import { PROJECT_LOGO_MAX_SIZE } from '../../../app/constants';

function EditProject({ ...props }) {
  const project = props.project;

  const [projectLogo, setProjectLogo] = useState(project.logoUrl);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsUpdating(true);
    setErrorMessage('');

    if (projectLogo === project.logoUrl) {
      setErrorMessage('Choose a new logo');
      return;
    }

    const payload = {
      logoBase64: projectLogo,
      projectId: project.id,
    };

    const onError = (error) => {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Unknown error while updating project logo, please contact support team.');
      }
      setIsUpdating(false);
    };

    try {
      const res = await backHttpClient.patch('/project/update-logo', payload);
      setSuccessMessage(res.data.message);
      setIsUpdating(false);
      props.onSuccess();
    } catch (error) {
      onError(error);
    }
  };

  function cleanUpAndClose() {
    setProjectLogo('');
    setErrorMessage('');
    setSuccessMessage('');
    props.close();
  }

  function handleLogoSelected(event) {
    if (event.target.files[0].size > PROJECT_LOGO_MAX_SIZE) {
      setErrorMessage('Logo should be less than or equal to  1MB in size');
    } else {
      const fr = new FileReader();
      fr.onload = (e) => {
        setProjectLogo(e.target.result);
      };
      fr.readAsDataURL(event.target.files[0]);
    }
  }
  return (
    <Modal onRequestClose={cleanUpAndClose} {...props}>
      <form onSubmit={handleSubmit}>
        <header>
          <ModalHeading>Edit {project.name}</ModalHeading>
        </header>
        {errorMessage && (
          <p>
            <ErrorIcon /> <ErrorText>{errorMessage}</ErrorText>
          </p>
        )}
        {successMessage && (
          <p>
            <SuccessText>{successMessage}</SuccessText>
          </p>
        )}
        <NewProjectRow>
          {projectLogo ? (
            <ProjectAvatarImage src={projectLogo || project.logoUrl} alt="image" />
          ) : (
            <EmptyAvatarImage />
          )}
          <label to="#" htmlFor="logo-upload">
            Update Project Logo
            <HiddenFileInput
              type="file"
              id="logo-upload"
              accept="image/*"
              onChange={handleLogoSelected}
            />
          </label>
        </NewProjectRow>
        <InputLabel>
          <span>Project Name*</span>
          <TextInput name="name" defaultValue={project.name} autoComplete="off" disabled />
        </InputLabel>
        <br />
        <InputLabel>
          <span>Brand/Company Name*</span>
          <TextInput
            name="companyName"
            autoComplete="off"
            defaultValue={project.companyName}
            disabled
          />
        </InputLabel>
        <br />
        <InputLabel>
          <span>Project Owner Name*</span>
          <TextInput
            name="userName"
            autoComplete="off"
            defaultValue={project.owner.name}
            disabled
          />
        </InputLabel>
        <br />
        <InputLabel>
          <span>Project Owner Email*</span>
          <TextInput
            name="email"
            type="email"
            autoComplete="off"
            defaultValue={project.owner.email}
            disabled
          />
        </InputLabel>
        <br />
        <ModalButtonsGroup>
          <PrimaryButton type="submit" disabled={isUpdating}>
            Save Changes
          </PrimaryButton>
          <AnchorLink to="#" onClick={cleanUpAndClose}>
            Cancel
          </AnchorLink>
        </ModalButtonsGroup>
      </form>
    </Modal>
  );
}

export default EditProject;

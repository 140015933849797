import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import Popover from 'react-popover';
import { clearToken } from '../auth/auth-slice';
import { projectsActions } from '../projects/projects-duck';
import OffsetLogoPicture from '../../images/offset_logo_picture_gold.svg';
import { Modal } from '../ui/modal';
import { PrimaryButton } from '../ui/inputs';
import { AnchorLink } from '../ui/texts';

import { stopChat } from '../messages/messages-slice';
import { ModalMessage } from './project-detail/styles.js';

export default function ProjectsTopBar({ toggleEditProjectModal }) {
  const { name } = useSelector((state) => state.auth);

  // header
  const [isBarOptionsOpen, setBarOptionsOpen] = useState(false);
  const closeBarOptions = () => setBarOptionsOpen(false);
  const toggleBarOptions = () => setBarOptionsOpen(!isBarOptionsOpen);

  // render
  return (
    <StylingProjectTopBar>
      <StylingSidebarHeader>
        <TopBarPopover
          className="tip-right"
          place="below"
          isOpen={isBarOptionsOpen}
          onOuterAction={closeBarOptions}
          body={
            <BarOptionsBody
              close={closeBarOptions}
              openProjectEditModal={() => toggleEditProjectModal(true)}
            />
          }
        >
          <ProjectTopBarBranding>
            <ProjectBrand />
            <ProjectRight role="button" onClick={toggleBarOptions}>
              <AuthName>{name}</AuthName>
              <ProjectOptionsIcon className="fas fa-chevron-down" />
            </ProjectRight>
          </ProjectTopBarBranding>
        </TopBarPopover>
      </StylingSidebarHeader>
    </StylingProjectTopBar>
  );
}

const TopBarPopover = styled(Popover)`
  right: 8px !important;
  left: inherit !important;
  top: 55px !important;
`;

const StylingSidebarHeader = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  background: #000000;
  z-index: 3;
`;

const ProjectBrand = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: none;
  background-image: url(${OffsetLogoPicture});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const ProjectRight = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }
`;
const AuthName = styled.p`
  margin: 0;
  font-weight: normal;
  font-size: 16px;
  color: #fff;
`;

const ProjectOptionsIcon = styled.i`
  display: block;
  margin-left: 10px;
`;

const ProjectTopBarBranding = styled.header`
  height: 80px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  color: #aeaeae;
  flex: 1;

  ${ProjectBrand} {
    margin-right: 10px;
  }
`;

const ModalButtonsGroup = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 30px;

  ${PrimaryButton} {
    width: 100%;
    margin-bottom: 5px;
  }
`;

const StylingProjectTopBar = styled.div``;

function BarOptionsBody({ close, openProjectEditModal }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMatchedProjectSettingsRoute = useRouteMatch('/projects/:id/settings');

  const makeItemClickHandler = (handler) => async () => {
    await handler();
    close();
  };

  const handleSignOutClick = makeItemClickHandler(() => {
    dispatch(clearToken());
    dispatch(projectsActions.readAll(null, null, true));
    dispatch(stopChat());
  });

  const [isSignOutOpen, setSignOutOpen] = useState(false);
  const openSignOut = () => setSignOutOpen(true);
  const closeSignOut = () => setSignOutOpen(false);

  return (
    <>
      <StylingProjectTopBarBody>
        <BarOptionsItem onClick={() => history.push('/projects')}>
          <span>View All Projects</span>
        </BarOptionsItem>
        {isMatchedProjectSettingsRoute && (
          <BarOptionsItem onClick={openProjectEditModal}>
            <span>Edit Project</span>
            <i className="fal fa-edit"></i>
          </BarOptionsItem>
        )}
        <BarOptionsItem onClick={openSignOut}>
          <span>Sign out</span>
          <i className="fal fa-sign-out"></i>
        </BarOptionsItem>
      </StylingProjectTopBarBody>
      <Modal isOpen={isSignOutOpen} onRequestClose={closeSignOut}>
        <ModalMessage>Are you sure you want to sign out?</ModalMessage>
        <ModalButtonsGroup>
          <PrimaryButton type="button" onClick={handleSignOutClick}>
            Sign out
          </PrimaryButton>
          <AnchorLink to="#" onClick={closeSignOut}>
            Cancel
          </AnchorLink>
        </ModalButtonsGroup>
      </Modal>
    </>
  );
}

const StylingProjectTopBarBody = styled.div`
  width: 280px;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    width: 95vw;
    margin: 0 auto;
  }
`;

const BarOptionsItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 15px 10px;

  &:hover {
    background-color: rgba(10, 10, 10, 0.1);
  }
`;

import { useState } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import backHttpClient from '../../app/backend-http-client';
import { PrimaryButton, InputLabel, TextInput } from '../ui/inputs';
import { ErrorText, AnchorLink } from '../ui/texts';
import { ErrorIcon } from '../../features/ui/iconography.js';
import OffsetBlackLogo from '../../images/offset_black_logo.svg';
import { toast } from 'react-toastify';

export default function ResetOrConfirmPage() {
  const isAuthenticated = useSelector((state) => !!state.auth.token);

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  if (isAuthenticated) {
    return <Redirect to="/channels" />;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    setErrorMessage('');

    try {
      const payload = { email: event.target.email.value.trim() };
      await backHttpClient.post('/auth/ask-reset-password', payload);
      toast.success(
        `E-mail sent! Hold on a few minutes and check your e-mail (even on spam folder) for a reset link.`,
        {
          theme: 'light',
        }
      );
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(
          'Unknown error while requesting password reset, please contact support team.'
        );
        console.error('Error on requesting password reset.', error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Main>
      <img src={OffsetBlackLogo} height="52" alt="Offset" />
      <AskResetPasswordForm onSubmit={handleSubmit}>
        <header>
          <h2>Forgot password</h2>
        </header>
        {errorMessage && (
          <p>
            <ErrorIcon /> <ErrorText>{errorMessage}</ErrorText>
          </p>
        )}
        <InputLabel>
          <span>Your e-mail:</span>
          <TextInput name="email" type="email" disabled={isLoading} autoComplete="off" required />
        </InputLabel>
        {isLoading && <p>Sending...</p>}
        <PrimaryButton type="submit" disabled={isLoading}>
          Send reset link
        </PrimaryButton>
        <AnchorLink to="/login">Sign in</AnchorLink>
      </AskResetPasswordForm>
    </Main>
  );
}

const Main = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 30px;
  }
`;

const AskResetPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 580px;
  box-sizing: border-box;
  padding: 30px;
  background: #ffffff;
  border-radius: 6px;

  ${InputLabel} {
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 40px;

    input {
      font-size: 16px;
      box-sizing: border-box;
    }
  }

  ${PrimaryButton} {
    width: 100%;
  }

  ${AnchorLink} {
    margin: auto;
    margin-top: 10px;
  }
`;

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MAX_FILE_SIZE } from './messages-constants';
import { sendMessage } from './messages-slice';
import NoiseBgHelper from '../../images/noise_bg_helper.png';
import { toast } from 'react-toastify';

export default function MessageForm(props) {
  const { isReady, isSendingMessage, channel } = useSelector((state) => state.messages);

  const [message, setMessage] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [fileInfo, setFileInfo] = useState(null);
  const handleMessageChange = (event) => setMessage(event.target.value);

  const dispatch = useDispatch();

  const disabledSendButton = (!message && !attachment) || isSendingMessage;

  const messageCallback = () => {
    setMessage('');
    setAttachment(null);
  };

  const sendThisMessage = () => {
    if (disabledSendButton) return;
    if (attachment) {
      dispatch(
        sendMessage({
          channel,
          text: fileInfo.name,
          file: {
            attachment,
            type: fileInfo.type,
            name: fileInfo.name,
            projectId: props.projectId,
          },
          messageCallback,
        })
      );
    } else {
      dispatch(
        sendMessage({
          channel,
          text: message,
          messageCallback,
        })
      );
    }
  };

  const handleTextKeyDown = (event) => {
    if (isReady && event.key === 'Enter' && !event.getModifierState('Shift')) {
      event.preventDefault();
      sendThisMessage();
    }
  };

  const parseAttachment = (fileData) => {
    if (fileData.size > MAX_FILE_SIZE) {
      toast.error('Upload a smaller file');
    } else {
      setFileInfo(fileData);
      const formData = new FormData();
      formData.append('attachment', fileData);
      setAttachment(formData);
    }
  };

  const handleOnPaste = (event) => {
    const fileAttachment = event.clipboardData.files[0];
    if (fileAttachment) parseAttachment(fileAttachment);
  };

  const handleAttachmentUpload = (event) => {
    const data = event.target.files[0];
    parseAttachment(data);
  };

  return (
    <StylingMessageForm {...props}>
      <MessageTextWrap>
        <HiddenLabel htmlFor="message-input">Message:</HiddenLabel>

        <MessageTextArea
          id="message-input"
          placeholder="Type your message..."
          value={message}
          onChange={handleMessageChange}
          onKeyDown={handleTextKeyDown}
          onPaste={handleOnPaste}
        />
        {attachment && (
          <AttachmentPreview>
            {!isSendingMessage && (
              <button onClick={() => setAttachment(null)}>
                <i className="fas fa-times"></i>
              </button>
            )}
            {isSendingMessage && (
              <button>
                <i className="fas fa-spinner fa-spin" />
              </button>
            )}
            <p>{fileInfo.name}</p>
          </AttachmentPreview>
        )}
        <ChatActionWrap>
          <label to="#" htmlFor="attachment-upload">
            <UploadDocumentAttachmentButton
              className="fa fa-image"
              role="button"
              disabled={isSendingMessage}
            >
              <HiddenFileInput
                type="file"
                id="attachment-upload"
                accept="image/*, .pdf"
                onChange={handleAttachmentUpload}
              />
            </UploadDocumentAttachmentButton>
          </label>
          <SendButtonIconWrap>
            <SendButtonIconBg>
              <SendButtonIcon
                className={isSendingMessage ? 'fas fa-spinner fa-spin' : 'fa fa-paper-plane'}
                role="button"
                onClick={sendThisMessage}
                disabled={disabledSendButton}
                $disabledSendButton={disabledSendButton}
              />
            </SendButtonIconBg>
          </SendButtonIconWrap>
        </ChatActionWrap>
      </MessageTextWrap>
    </StylingMessageForm>
  );
}

const HiddenLabel = (props) => <label {...props} aria-hidden="false" style={{ display: 'none' }} />;

const MessageTextWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 68px;
  resize: none;
  box-sizing: border-box;
  padding-left: 25px;
  padding-right: 25px;
  background: none;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.25);
`;

const MessageTextArea = styled.textarea`
  box-sizing: border-box;
  outline: none;
  border: none;
  width: 100%;
  padding-top: 22px;
  background: none;
  color: #fff;
  font-size: 16px;
  resize: none;
`;

const ChatActionWrap = styled.div`
  display: flex;
  align-items: center;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const SendButtonIconWrap = styled.div`
  box-shadow: 0px 1px 0px rgb(255 255 255 / 20%);
  border-radius: 8px;
  background: #1a1a1a;
  margin-left: 15px;
`;

const SendButtonIconBg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 36px;
  height: 36px;
  margin: 2px;
  border-radius: 6px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(128, 128, 128, 0.2) 100%),
    #b7cd88;
  background-blend-mode: overlay, overlay, normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5), inset 0px 2px 2px rgba(255, 255, 255, 0.5),
    inset 0px -2px 2px rgba(0, 0, 0, 0.25);

  &:hover {
    box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.6), inset 0px 2px 2px rgba(255, 255, 255, 0.4),
      inset 0px -2px 2px rgba(0, 0, 0, 0.2);
  }
`;

const SendButtonIcon = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 36px;
  height: 36px;
  color: #333333;
  font-weight: bold;
  font-size: 16px;
  cursor: ${(props) => (props.$disabledSendButton ? 'not-allowed' : 'pointer')};
`;

const UploadDocumentAttachmentButton = styled.i`
  display: block;
  cursor: pointer;
  font-size: 20px;
`;

const StylingMessageForm = styled.form`
  display: ${(props) => (props.$collapsed ? 'none' : 'block')};
  position: relative;
  background-color: #fff;
  background-image: url(${NoiseBgHelper});
  background-blend-mode: overlay;
  background-color: #262626;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.25);
  color: rgba(255, 255, 255, 75%);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0 25px 25px;
`;

const AttachmentPreview = styled.div`
  position: absolute;
  width: calc(100% - 230px);
  padding: 0 10px;
  background: #bdd093;
  color: #000;
  border-radius: 4px;
  height: 40px;
  font-weight: bold;
  display: flex;
  align-items: center;

  p {
    margin: 0;
    font-size: 12px;
  }

  button {
    position: absolute;
    display: block;
    width: 25px;
    height: 25px;
    color: #fff;
    background: #000;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
  }
`;

import { createSlice } from '@reduxjs/toolkit';
import backHttpClient from '../../app/backend-http-client';
import vonageClient from '../../app/vonage-client';
import { toast } from 'react-toastify';

export const callsSlice = createSlice({
  name: 'calls',
  initialState: {
    activeTopic: '',
    activeEntityId: -1,
    apiKey: '',
    session: null,
    sessionId: '',
    token: '',
    publisher: null,
    isMicrophoneActive: false,
  },
  reducers: {
    setActiveCall: (state, action) => {
      state.activeTopic = action.payload.topic;
      state.activeEntityId = action.payload.id;
    },
    setCallCredentials: (state, action) => {
      state.apiKey = action.payload.apiKey;
      state.sessionId = action.payload.sessionId;
      state.token = action.payload.token;

      if (action.payload.session) {
        state.session = action.payload.session;
      }
    },
    clearCallsData: (state) => {
      state.activeTopic = '';
      state.activeEntityId = -1;
      state.apiKey = '';
      state.sessionId = '';
      state.token = '';
      state.session = null;
      state.publisher = null;
    },
    setCallSession: (state, action) => {
      state.session = action.payload;
    },
    setCallPublisher: (state, action) => {
      state.publisher = action.payload;
    },
    setMicrophoneActive: (state, action) => {
      state.isMicrophoneActive = !!action.payload;
    },
  },
});

export const startCall = (topic, entityId) => async (dispatch, getState) => {
  const { calls } = getState();
  if (topic === calls.activeTopic && entityId === calls.activeEntityId) {
    stopCall()(dispatch, getState);
    return;
  } else if (calls.session) {
    calls.session.disconnect();
    calls.session.off();
  }

  dispatch(callsSlice.actions.setActiveCall({ topic, id: entityId }));

  const response = await backHttpClient.post(`topics/${topic}/${entityId}`);
  const { apiKey, sessionId, token } = response.data;
  const session = vonageClient.initSession(apiKey, sessionId);
  dispatch(callsSlice.actions.setCallCredentials({ apiKey, sessionId, token, session }));

  const callPubSub = vonageClient.initCallPubSub(
    session,
    (error) => {
      if (error) {
        console.error('Error on publisher stream.', error);
        toast.error('Unknown error on call publisher.', {
          theme: 'light',
        });
        return;
      }

      const shouldActiveMic = getState().calls.isMicrophoneActive;
      getState().calls.publisher.publishAudio(shouldActiveMic);
      dispatch(callsSlice.actions.setMicrophoneActive(shouldActiveMic));
    },
    (error) => {
      if (error) {
        console.error('Error on subscriber stream.', error);
        toast.error('Unknown error on call subscriber.', {
          theme: 'light',
        });
      }
    }
  );
  dispatch(callsSlice.actions.setCallPublisher(callPubSub));

  await vonageClient.connectSession(session, token);

  session.publish(callPubSub);
};

export const stopCall = () => (dispatch, getState) => {
  const { session } = getState().calls;
  if (session) {
    session.disconnect();
    session.off();
  }

  dispatch(callsSlice.actions.setMicrophoneActive(false));
  dispatch(callsSlice.actions.clearCallsData());
};

export const toggleMicrophone = () => async (dispatch, getState) => {
  const { isMicrophoneActive, publisher } = getState().calls;
  if (!publisher) {
    return;
  }

  const toggled = !isMicrophoneActive;
  publisher.publishAudio(toggled);
  dispatch(callsSlice.actions.setMicrophoneActive(toggled));
};

export default callsSlice.reducer;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AVControlsModal from '../broadcast/AVControlsModal';
import { startCall, stopCall } from '../calls/calls-slice';
import { TOPIC_USER } from '../calls/calls-constants';
import { setCurrentTopic, startChat } from '../messages/messages-slice';
import {
  makeTopicIdForChannels,
  makeTopicIdForUsersFn,
  useSelectedChatName,
} from '../messages/selected-chat-utils';

import {
  toggleVideoCallMic,
  toggleVideoCallVideo,
  rejectSessionInvite,
  acceptSessionInvite,
  cancelSessionInvite,
  endVideoCall,
  toggleUI,
} from '../video/video-slice';
import incomingTone from '../../tones/incoming.wav';
import inprogressTone from '../../tones/inprogress.wav';
import { Channels } from './Channels';
import { Groups } from './Groups';
import { Users } from './Users';
import SideBarControls from './project-main-sidebar/SidebarControls';
import CallModal from './project-main-sidebar/CallModal';
import MiniVideoCallModal from './project-main-sidebar/MiniVideoCallModal';
import IncomingCallModal from './project-main-sidebar/IncomingCallModal';

import {
  StylingMainSidebar,
  StylingMainSidebarInner,
  ScrollableMenuBody,
  SidebarBottom,
  MinimizedCallActions,
  GroupName,
  StylingMainOptionsBody,
  MainOptionsItem,
} from './project-main-sidebar/styles';
import SidebarBottomCallActions from '../../components/SidebarBottomCallActions';
import Speaker from '../../components/Speaker';
import {
  displayMiniVideo,
  setScreenToDisplay,
  startGroupVideoCallPublisher,
  stopGroupVideoPublisher,
} from '../video/group-video-slice';
import GroupCallModal from '../modals/GroupCallModal';
import Popover from 'react-popover';
import useChannelCallHelpers from '../../hooks/useChannelCallHelpers';
import FileSendingNotificationModal from './FileSendingNotificationModal';
import useInitializeMessages from '../../hooks/useInitializeMessages';

export default function ProjectMainSidebar({ project = { channels: [] } }) {
  const dispatch = useDispatch();

  const { pubnub } = useInitializeMessages(project);
  const [isAcceptingCall, setIsAcceptingCall] = useState(false);
  const [isVideoMinimized, setIsVideoMinimized] = useState(false);

  const { updatedProjectInfo } = useSelector((state) => state.main);
  const combinedProject = { ...project, ...updatedProjectInfo };

  const { userId } = useSelector((state) => state.auth);
  const user = combinedProject.users?.find((_user) => _user.id === userId);
  const canBroadcast = user && user.ProjectUsers && user.ProjectUsers.canBroadcast;

  // header
  const [isMainOptionsOpen, setMainOptionsOpen] = useState(false);
  const closeMainOptions = () => setMainOptionsOpen(false);
  const toggleMainOptions = () => setMainOptionsOpen(!isMainOptionsOpen);

  // Manage FileSending Notification Modal
  const [shouldDisplayFileSendingPrompt, setShouldDisplayFileSendingPrompt] = useState(false);
  const {
    isInAudioRoom,
    activeChannelId: activeAudioCallChannelId,
    isJoiningRoom,
  } = useSelector((state) => state.audio);

  const { channel: activeMessageChannel } = useSelector((state) => state.messages);
  const activeGroupCallChannel = activeAudioCallChannelId
    ? `video-call-channel-${project.id}-${activeAudioCallChannelId}`
    : `video-call-${activeMessageChannel}`;

  const groupPubnubChannel = activeAudioCallChannelId
    ? `channel-${project.id}-${activeAudioCallChannelId}`
    : activeMessageChannel;

  // topic selection
  const isInCall = useSelector((state) => !!state.calls.session);
  const [[activeTopic, activeEntityId], setSelection] = useState(['', -1]);

  const makeTopicIdForUsers = makeTopicIdForUsersFn(project.id, userId);

  // Camera activation of existing audio call
  const [isAudioCallCameraActive, setIsAudioCallCameraActive] = useState(false);

  const {
    groupName,
    displayScreen,
    isInGroupVideoCall,
    groupCallState,
    camera: userSelectedCamera,
    microphone: userSelectedMicrophone,
  } = useSelector((state) => state.groupVideoCall);

  const isAudioCall = true;
  const selectedChatName = useSelectedChatName();

  // Used to set the currently active messaging channel topic. either user or channel
  const activateTopicFn =
    (topic, id, shouldStartChat = true) =>
    () => {
      if (isItemActive(topic, id)) {
        return;
      }
      let topicId = '';
      if (topic === TOPIC_USER) {
        topicId = makeTopicIdForUsers(id);
      } else {
        topicId = makeTopicIdForChannels(project.id, id);
      }
      if (shouldStartChat) {
        setSelection([topic, topicId]);
        dispatch(setCurrentTopic(topic));
      }

      if (isInCall) {
        dispatch(stopCall());

        // Stop video call if you are joining a new channel
        // Allow a video call in the same audio call channel. Don't allow one audio call in 1 channel
        // and 1 video call in another
        dispatch(stopGroupVideoPublisher());
      }

      if (shouldStartChat) dispatch(startChat({ topic, entityId: topicId, pubnub }));
    };

  // Check the active state of a channel
  const isItemActive = (topic, id) => {
    return topic === TOPIC_USER
      ? activeEntityId === makeTopicIdForUsers(id)
      : activeEntityId === makeTopicIdForChannels(project.id, id);
  };

  useEffect(() => {
    const handler = () => {
      const data = {
        isInAudioRoom: sessionStorage.getItem('isInAudioRoom'),
        audioChannelId: sessionStorage.getItem('audioChannelId'),
        groupVideoChannel: sessionStorage.getItem('groupVideoChannel'),
        isInGroupVideoCall: sessionStorage.getItem('isInGroupVideoCall'),
        projectId: sessionStorage.getItem('projectId'),
      };

      window.navigator.sendBeacon(
        `${process.env.REACT_APP_BACKEND}/api/pubnub/force-leave-update`,
        JSON.stringify({ ...data })
      );
    };

    window.addEventListener('beforeunload', handler, true);
    return () => {
      window.removeEventListener('beforeunload', handler, true);
    };
  }, []);

  /**
   * We have 3 modes.
   * 1. Adding a video to an existing audio call in the group.
   * 2. A user started a video call which is displayed on your screen and you want to join
   * @param {*} camera
   * @param {*} microphone
   */
  const handleCameraActivation = (camera, microphone) => {
    dispatch(setScreenToDisplay('minimized'));
    // Starting or joining an existing video call because someone sent an invite
    if (
      (!isInGroupVideoCall || !groupCallState[activeGroupCallChannel]?.inGroupCall) &&
      groupCallState[activeGroupCallChannel]?.callParticipants <= 0
    ) {
      console.log('starting a fresh call');
      dispatch(
        startGroupVideoCallPublisher({
          projectId: project.id,
          inviteeId: null,
          groupName: null,
          camera,
          microphone,
          isAcceptingInvite: false,
          shouldShowIncomingCallModal: false,
          shouldSendInvite: true,
          shouldPublishAudio: false,
        })
      );
    } else {
      console.log('joining an ongoing or existing call');
      dispatch(setScreenToDisplay('minimized'));
      dispatch(
        startGroupVideoCallPublisher({
          projectId: project.id,
          inviteeId: null,
          groupName: null,
          camera,
          microphone,
          isAcceptingInvite: false,
          shouldShowIncomingCallModal: false,
          shouldSendInvite: false,
          shouldPublishAudio: false,
        })
      );
    }
  };

  const handleAddVideoToAudioCall = () => {
    if (userSelectedCamera && userSelectedMicrophone) {
      dispatch(setScreenToDisplay('minimized'));
      handleCameraActivation(userSelectedCamera, userSelectedMicrophone);
    } else {
      setIsAudioCallCameraActive(true);
    }

    dispatch(displayMiniVideo(true));
    dispatch(setScreenToDisplay('minimized'));
  };

  const {
    invitingUser,
    invitingSessionId,
    isIncomingCall,
    isInVideoCall,
    invitedUser,
    isWaitingForCall,
  } = useSelector((state) => state.videoCall);

  const { isMicMuted: isVideCallMicMuted, isVideoMuted } = useSelector((state) => state.videoCall);
  const handleVideoToggle = () => {
    dispatch(toggleVideoCallVideo());
  };
  const handleMicToggle = () => {
    dispatch(toggleVideoCallMic());
  };
  const handleAcceptCall = (camera, microphone) => {
    setIsVideoMinimized(false);
    dispatch(acceptSessionInvite(project.id, camera, microphone));
    setIsAcceptingCall(false);
  };
  const handleRejectCall = () => {
    dispatch(rejectSessionInvite(project.id, invitingSessionId, invitingUser.id));
  };
  const handleEndCall = () => {
    if (isWaitingForCall) {
      dispatch(cancelSessionInvite(project.id, invitingSessionId, invitedUser));
    }
    if (isInVideoCall && invitingUser) {
      dispatch(endVideoCall(project.id, invitingSessionId, invitingUser.id));
    }
    if (isInVideoCall && invitedUser) {
      dispatch(endVideoCall(project.id, invitingSessionId, invitedUser));
    }
  };
  const peer = invitedUser
    ? combinedProject.users.find((_user) => _user.id === invitedUser)
    : invitingUser
    ? combinedProject.users.find((_user) => _user.id === invitingUser.id)
    : null;
  const handleMinimizeToggle = () => {
    const newVideoMinimizedState = !isVideoMinimized;
    setIsVideoMinimized(newVideoMinimizedState);
    dispatch(toggleUI(newVideoMinimizedState));
  };

  // unreadMessageCountByChannel
  const unreadMessageCountByChannel = useSelector(
    (state) => state.messages.unreadMessageCountByChannel
  );

  const [isOpenChannelSelectionDropdown, setIsOpenChanneSelectionDropwdown] = useState(false);
  const channels = (user && user.Channels) || [];
  const participatingChannels = channels.filter((c) => c.projectId === project.id);
  const { handleJoinAudioRoom } = useChannelCallHelpers({
    projectId: project.id,
    activateTopicFn,
  });

  const selectChannel = (event, channel) => {
    handleJoinAudioRoom(event, channel);
    setIsOpenChanneSelectionDropwdown(false);
  };

  const handleDropDownOpen = () => {
    if (!isJoiningRoom) setIsOpenChanneSelectionDropwdown(true);
  };

  const activeAudioCallChannelName = participatingChannels.find(
    (channel) => channel.id === activeAudioCallChannelId
  )?.name;
  const displayedChannelName = activeAudioCallChannelName?.toLowerCase().replace(' ', '_');

  const ChannelSelectPopUp = ({ buttonLabel }) => (
    <Popover
      place={isInGroupVideoCall && displayScreen === 'large' ? 'above' : 'below'}
      className="tip-right"
      isOpen={isOpenChannelSelectionDropdown}
      onOuterAction={() => setIsOpenChanneSelectionDropwdown(false)}
      body={
        <StylingMainOptionsBody>
          {participatingChannels.length === 0 && (
            <MainOptionsItem>Not in any channels.</MainOptionsItem>
          )}
          {participatingChannels.map(
            (channel) =>
              activeAudioCallChannelId !== channel.id && (
                <MainOptionsItem
                  key={channel.id}
                  onClick={(event) => selectChannel(event, channel)}
                >
                  {channel.name.toLowerCase().replace(' ', '_')}
                </MainOptionsItem>
              )
          )}
        </StylingMainOptionsBody>
      }
    >
      <GroupName onClick={handleDropDownOpen}>
        <span>{buttonLabel}</span>
      </GroupName>
    </Popover>
  );

  const defaultChannelSelectButtonLabel = isJoiningRoom ? 'Joining Call...' : 'Select channel';

  return (
    <StylingMainSidebar>
      <SideBarControls
        project={combinedProject}
        closeMainOptions={closeMainOptions}
        isMainOptionsOpen={isMainOptionsOpen}
        toggleMainOptions={toggleMainOptions}
        canBroadcast={canBroadcast}
      ></SideBarControls>
      <SidebarBottom>
        <Speaker />

        {!isInGroupVideoCall && (
          <>
            {isInCall || isInAudioRoom ? (
              <SidebarBottomCallActions
                handleAddVideoToAudioCall={handleAddVideoToAudioCall}
                inCallSpeakerToggle={() => {
                  if (isInCall) {
                    dispatch(stopCall());
                  } else {
                    dispatch(startCall(activeTopic, activeEntityId));
                  }
                }}
                groupName={displayedChannelName}
                isAudioCall={isAudioCall}
              >
                <ChannelSelectPopUp buttonLabel={displayedChannelName} />
              </SidebarBottomCallActions>
            ) : (
              <MinimizedCallActions>
                <ChannelSelectPopUp buttonLabel={defaultChannelSelectButtonLabel} />
              </MinimizedCallActions>
            )}
          </>
        )}

        <MiniVideoCallModal
          isInVideoCall={isInVideoCall}
          isVideoMinimized={isVideoMinimized}
          handleMinimizeToggle={handleMinimizeToggle}
          handleEndCall={handleEndCall}
          handleMicToggle={handleMicToggle}
          isVideCallMicMuted={isVideCallMicMuted}
          handleVideoToggle={handleVideoToggle}
          isVideoMuted={isVideoMuted}
          peer={peer}
        />
        {isAudioCallCameraActive && (
          <AVControlsModal
            close={() => setIsAudioCallCameraActive(false)}
            onDoneBroadcast={handleCameraActivation}
            actionText="Activate Camera"
          />
        )}
        {isInGroupVideoCall && (
          <GroupCallModal
            groupName={groupName || displayedChannelName || selectedChatName}
            groupPubnubChannel={groupPubnubChannel}
            startMinimized={displayScreen === 'minimized'}
            handleAddVideoToAudioCall={handleAddVideoToAudioCall}
          >
            <ChannelSelectPopUp
              buttonLabel={groupName || displayedChannelName || selectedChatName}
              style={{ marginTop: 0 }}
            />
          </GroupCallModal>
        )}

        {/* Various Modals */}
        {isAcceptingCall && (
          <AVControlsModal
            close={() => setIsAcceptingCall(false)}
            onDoneBroadcast={handleAcceptCall}
            actionText="Join Call"
          />
        )}
        <IncomingCallModal
          isOpen={isIncomingCall}
          invitingUser={combinedProject.users.find((u) => invitingUser && u.id === invitingUser.id)}
          acceptCallHandler={() => setIsAcceptingCall(true)}
          close={handleRejectCall}
        />
        <CallModal
          isOpen={isInVideoCall}
          peer={peer}
          acceptCallHandler={() => setIsAcceptingCall(true)}
          close={handleEndCall}
          onToggleMinimize={handleMinimizeToggle}
          isVideoMinimized={isVideoMinimized}
        />

        <FileSendingNotificationModal
          isOpen={shouldDisplayFileSendingPrompt}
          handleClose={() => setShouldDisplayFileSendingPrompt(false)}
        />

        {/* End of Modals */}
      </SidebarBottom>
      <StylingMainSidebarInner>
        <ScrollableMenuBody>
          <Channels
            project={combinedProject}
            activateTopicFn={activateTopicFn}
            isItemActive={isItemActive}
            unreadMessagesCount={unreadMessageCountByChannel}
            handleAddVideoToAudioCall={handleAddVideoToAudioCall}
            openFileSendingPrompt={() => setShouldDisplayFileSendingPrompt(true)}
          />
          <Groups
            project={combinedProject}
            activateTopicFn={activateTopicFn}
            isItemActive={isItemActive}
            unreadMessagesCount={unreadMessageCountByChannel}
            openFileSendingPrompt={() => setShouldDisplayFileSendingPrompt(true)}
          />
          <Users
            project={combinedProject}
            activateTopicFn={activateTopicFn}
            isItemActive={isItemActive}
            unreadMessagesCount={unreadMessageCountByChannel}
            openFileSendingPrompt={() => setShouldDisplayFileSendingPrompt(true)}
          />
        </ScrollableMenuBody>
      </StylingMainSidebarInner>

      <div id="ot-call" style={{ display: 'none' }} />
      <audio id="inprogress-tone" src={inprogressTone} loop muted="muted" />
      <audio id="incoming-tone" src={incomingTone} loop muted="muted" />
    </StylingMainSidebar>
  );
}

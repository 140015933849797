import { useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MessagesList from '../messages/MessagesList';
import { useSelectedChatName } from '../messages/selected-chat-utils';
import MessageForm from '../messages/MessageForm';
import Draggable from 'react-draggable';
import VideoSettings, { LUTS, RATIO_16_PER_9 } from '../broadcast/VideoSettings';
import { useResizeDetector } from 'react-resize-detector';
import { startVideoCallPublisher } from '../video/video-slice';
import BroadcastHeader from './BroadcastHeader';
import AVControlsModal from '../broadcast/AVControlsModal';
import StartGroupCallModal from '../modals/StartGroupCallModal';
import {
  BroadcastSection,
  BroadcastingWrapper,
  BroadcastOT,
  OtBroadcastingUIWrapper,
  StyledOTBroadcast,
  VideoCover,
  AspectRatioUIWrapper,
  DraggableChild,
  StyledNoConnection,
} from './styles';
import {
  ConfigButton,
  ConfigButtonWrap,
  ConfigButtonShell,
} from '../../features/main/project-main-sidebar/styles';
import SliderIconSvg from '../../images/icon-sliders.svg';

import ChannelMemberViewModals from './ChannelMemberViewModals';
import { toggleBroadcastMute } from '../broadcast/broadcast-slice';
import NoConnection from '../../components/NoConnection';
import useGroupVideoCallHelpers from '../../hooks/useGroupVideoCallHelpers';
import useInitializeVideoCall from '../../hooks/useInitializeVideoCall';

export default function BroadcastPanel({ project = {} }) {
  const dispatch = useDispatch();
  const [ingoingAspectRatio, setIngoingAspectRatio] = useState(RATIO_16_PER_9);
  const [isChatOpen, setChatOpen] = useState(true);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [childrenPosition, setChildrenPosition] = useState({ x: '0%', y: '0%' });
  const aspectRatioRef = useRef(null);
  const { height: aspectRatioWrapperHeight, ref: aspectRatioWrapperRef } = useResizeDetector();
  const [lutsValues, setLutsValues] = useState({
    [LUTS.BRIGHTNESS]: {
      min: 30,
      value: '',
      max: 200,
    },
    [LUTS.CONTRAST]: {
      min: 20,
      value: '',
      max: 150,
    },
    [LUTS.TEMPERATURE]: {
      min: -50,
      value: '',
      max: 50,
    },
  });
  const [isAVModalOpen, setAVModalOpen] = useState(false);
  const [requestPeerId, setRequestPeerId] = useState(null);
  const { userId: id } = useSelector((state) => state.auth);
  const { updatedProjectInfo } = useSelector((state) => state.main);
  const combinedProject = useMemo(
    () => ({ ...project, ...updatedProjectInfo }),
    [project, updatedProjectInfo]
  );

  useInitializeVideoCall(project);

  // handle one-on-one calls
  const handleVideoCall = (camera, microphone) => {
    dispatch(startVideoCallPublisher(project.id, requestPeerId, camera, microphone));
  };

  const isPublisherOfBroadcast = useSelector((state) => !!state.broadcast.publisher);
  const { isBroadcasting, broadcastVolume, publisher, isNoConnection } = useSelector(
    (state) => state.broadcast
  );

  const [isOpenCameraSwitchModal, setIsOpenCameraSwitchModal] = useState(false);
  const [isJoiningOngoingCall, setIsJoiningOngoingCall] = useState(false);
  const handleCameraChange = async (camearDevice) => {
    await publisher.setVideoSource(camearDevice.deviceId);
  };

  const channel = useSelector((state) => state.messages.channel) || '';
  const selectedChatName = useSelectedChatName();

  const userId = channel
    .split('-')
    .slice(2)
    .filter((_id) => Number(_id) !== id)
    .map((_id) => Number(_id))[0];

  const setPositionTo = ({ x, y }) => {
    setChildrenPosition({
      x: `${x}px`,
      y: `${y}px`,
    });
    setPosition({ x: x, y: y });
  };

  const handleDrag = (_e, data) => {
    setPositionTo({ x: data.x, y: data.y });
  };

  const openChat = () => {
    setChatOpen(true);
    setSettingsOpen(false);
  };
  const closeChat = () => {
    setChatOpen(false);
  };

  const toggleSettings = () => {
    setSettingsOpen(!isSettingsOpen);
    if (!isSettingsOpen) {
      closeChat();
    }
  };

  const isIngoingAspectRatioChecked = (value) => value === ingoingAspectRatio;
  const handleIngoingAspectRatioClickFn = (value) => {
    setIngoingAspectRatio(value);
    setPositionTo({ x: 0, y: 0 });
  };

  const getAspectRatioWidth = () => {
    const dims = ingoingAspectRatio.split('-'); // this returns an array of the form ['ratio', '16', '9'] if a given aspect ratio has been selected
    if (dims.length === 3) {
      return (Number(dims[1]) * Number(aspectRatioWrapperHeight)) / Number(dims[2]);
    }
    return 0;
  };

  // Group and Channel Video Call
  const [isOpenGroupCallModal, setIsOpenGroupCallModal] = useState(false);
  const { groupName } = useSelector((state) => state.groupVideoCall);
  const [shouldDisplayVideo, setShouldDisplayVideo] = useState(false);

  // handle group call start
  const { handleGroupVideoCall } = useGroupVideoCallHelpers({
    projectId: project.id,
    requestPeerId,
    setShouldDisplayVideo,
  });

  // View channel members modal
  const [isOpenChannelMembersView, setIsOpenChannelMembersView] = useState(false);
  const [isOpenAdhocMembersView, setIsOpenAdhocMembersView] = useState(false);

  const activeGroupOrChannelId = Number(channel.split('-')[2]);
  // Data needed for ChannelMembersView
  const channelInfo = combinedProject?.channels.find(
    (singleChannel) => singleChannel.id === activeGroupOrChannelId
  );
  const activeChannelMembers = channelInfo?.participantsIds?.map(
    (participantId) =>
      combinedProject.users.filter((projectUser) => projectUser.id === participantId)[0]
  );

  // Adhoc Group Members Data
  const adhocGroupInfo = combinedProject.groups.find(
    (group) => group.id === activeGroupOrChannelId
  );

  const activeGroupMembers = adhocGroupInfo?.participantsIds?.map(
    (participantId) =>
      combinedProject.users.filter((projectUser) => projectUser.id === participantId)[0]
  );

  return (
    <BroadcastSection>
      <BroadcastingWrapper isBroadcasting={isBroadcasting} $fullHeight={!isChatOpen}>
        <BroadcastOT className={ingoingAspectRatio}>
          <OtBroadcastingUIWrapper
            $brightness={lutsValues[LUTS.BRIGHTNESS].value}
            $contrast={lutsValues[LUTS.CONTRAST].value}
          >
            <VideoCover $temperature={lutsValues[LUTS.TEMPERATURE].value} />

            <StyledOTBroadcast id="ot-broadcast" />
          </OtBroadcastingUIWrapper>
          <AspectRatioUIWrapper ref={aspectRatioWrapperRef}>
            <Draggable
              onDrag={handleDrag}
              nodeRef={aspectRatioRef}
              bounds="parent"
              position={position}
            >
              <DraggableChild
                ref={aspectRatioRef}
                position={childrenPosition}
                height={aspectRatioWrapperHeight}
                width={getAspectRatioWidth()}
              ></DraggableChild>
            </Draggable>
          </AspectRatioUIWrapper>
          <ConfigButtonWrap>
            <ConfigButtonShell>
              <ConfigButton
                className={`yellow ${isSettingsOpen ? 'on' : 'off'} active`}
                onClick={toggleSettings}
              >
                <img src={SliderIconSvg} />
              </ConfigButton>
            </ConfigButtonShell>

            {!isPublisherOfBroadcast && (
              <ConfigButtonShell>
                <ConfigButton className="yellow" onClick={() => dispatch(toggleBroadcastMute())}>
                  {broadcastVolume === 0 ? (
                    <i className="fa fa-volume-mute"></i>
                  ) : (
                    <i className="fa fa-volume-up" />
                  )}
                </ConfigButton>
              </ConfigButtonShell>
            )}

            {!!isPublisherOfBroadcast && (
              <ConfigButtonShell>
                <ConfigButton className="yellow" onClick={() => setIsOpenCameraSwitchModal(true)}>
                  <i className="fa fa-wrench"></i>
                </ConfigButton>
              </ConfigButtonShell>
            )}
          </ConfigButtonWrap>
          {isNoConnection && (
            <StyledNoConnection>
              <NoConnection />
            </StyledNoConnection>
          )}
        </BroadcastOT>
        {isSettingsOpen && (
          <VideoSettings
            lutsValues={lutsValues}
            setLutsValues={setLutsValues}
            closeSettings={() => setSettingsOpen(false)}
            isIngoingAspectRatioChecked={isIngoingAspectRatioChecked}
            handleIngoingAspectRatioClickFn={handleIngoingAspectRatioClickFn}
          />
        )}
      </BroadcastingWrapper>

      <BroadcastHeader
        id={id}
        userId={userId}
        isBroadcasting={isBroadcasting}
        closeChat={closeChat}
        openChat={openChat}
        isChatOpen={isChatOpen}
        setAVModalOpen={setAVModalOpen}
        selectedChatName={selectedChatName}
        setRequestPeerId={setRequestPeerId}
        setIsJoiningOngoingCall={setIsJoiningOngoingCall}
        setIsOpenGroupCallModal={setIsOpenGroupCallModal}
        viewChannelMembers={setIsOpenChannelMembersView}
        viewAdhocGroupMembers={setIsOpenAdhocMembersView}
        activeChannelMembers={activeChannelMembers}
        activeGroupMembers={activeGroupMembers}
      />
      <MessagesList
        $collapsed={isBroadcasting && !isChatOpen}
        $fullHeight={!isBroadcasting}
        project={combinedProject}
        selectedChatName={selectedChatName}
      />
      {!!selectedChatName && (
        <MessageForm $collapsed={isBroadcasting && !isChatOpen} projectId={project.id} />
      )}

      {isAVModalOpen && (
        <AVControlsModal
          close={() => setAVModalOpen(false)}
          onDoneBroadcast={handleVideoCall}
          actionText="Start Call"
        />
      )}

      {isOpenCameraSwitchModal && (
        <AVControlsModal
          close={() => setIsOpenCameraSwitchModal(false)}
          onDoneBroadcast={handleCameraChange}
          actionText="Change Camera"
        />
      )}

      <StartGroupCallModal
        isOpen={isOpenGroupCallModal}
        close={() => setIsOpenGroupCallModal(false)}
        groupName={groupName || selectedChatName}
        handleVideoCall={handleGroupVideoCall}
        isJoiningOngoingCall={isJoiningOngoingCall}
        shouldDisplayVideo={shouldDisplayVideo}
      />

      {isOpenChannelMembersView && (
        <ChannelMemberViewModals
          project={combinedProject}
          activeChannelName={channelInfo.name}
          channelMembers={activeChannelMembers}
          isOpen={isOpenChannelMembersView}
          close={() => setIsOpenChannelMembersView(false)}
        />
      )}

      {isOpenAdhocMembersView && (
        <ChannelMemberViewModals
          project={combinedProject}
          channelMembers={activeGroupMembers}
          activeChannelName={adhocGroupInfo.name}
          isOpen={isOpenAdhocMembersView}
          close={() => setIsOpenAdhocMembersView(false)}
          isAdhocGroup={true}
        />
      )}
    </BroadcastSection>
  );
}

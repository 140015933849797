import { makeReducerAssets } from 'resource-toolkit';
import backHttpClient from '../../app/backend-http-client';
import { get } from 'lodash';
const resource = makeReducerAssets({
  name: 'projects',
  idKey: 'id',
  gateway: {
    fetchOne: async (id) => {
      return (await backHttpClient.get(`/projects/${id}`)).data.item;
    },
    fetchMany: async (ids = null, ...args) => {
      const [isGlobalAdmin, userId, emptyProjects] = args;
      if (emptyProjects) return [];
      return isGlobalAdmin
        ? (await backHttpClient.get('/projects/')).data.items
        : (await backHttpClient.get(`/projects/user/${userId}`)).data.items;
    },
    create: async (payload, successCallback, errorCallback) => {
      try {
        const res = (await backHttpClient.post('/projects/', payload)).data.item;
        successCallback(res.name);
        return res;
      } catch (error) {
        errorCallback(error);
      }
    },
    update: async (projectId, payload = {}, onSuccess, onError) => {
      try {
        let res;
        if (payload.type && payload.type.startsWith('user-')) {
          res = (await backHttpClient.patch(`/projects/${projectId}/${payload.type}`, payload)).data
            .item;
          if (onSuccess) onSuccess();
          return res;
        }
        if (payload.type && payload.type.startsWith('remove-channel')) {
          res = (
            await backHttpClient.delete(
              `/projects/${projectId}/channel/${payload.channelId}`,
              payload
            )
          ).data.item;
          if (onSuccess) onSuccess();
          return res;
        }
        if (payload.type && payload.type === 'add-group') {
          res = (await backHttpClient.patch(`/projects/${projectId}/group`, payload)).data.item;
          if (onSuccess) onSuccess();
          return res;
        }
        if (payload.type && payload.type === 'update-group') {
          res = (await backHttpClient.patch(`/projects/${projectId}/group`, payload)).data.item;
          if (onSuccess) onSuccess();
          return res;
        }
        // Update a channel
        if (payload.channelId) {
          res = (await backHttpClient.patch(`/projects/${projectId}/channel`, payload)).data.item;
          if (onSuccess) onSuccess();
          return res;
        }

        // Create a new channel
        if (payload.type && payload.type === 'add-channel') {
          res = (await backHttpClient.patch(`/projects/${projectId}/channel`, payload)).data.item;
          if (onSuccess) onSuccess();
          return res;
        }
      } catch (error) {
        onError(get(error, 'response.data.message', ''), error);
        throw error;
      }
    },
  },
});

export const projectsActions = resource.actionThunks;

export default resource.reducer;

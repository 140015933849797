import get from 'lodash.get';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import backHttpClient from '../../app/backend-http-client';

export const initialState = {
  status: '',
  data: null,
  error: null,
};

// Fetch user profile info
export const fetchProfile = createAsyncThunk(
  'profile/fetchProfile',
  async (projectId, _onSuccess, onError) => {
    try {
      const response = await backHttpClient.get(`/users/profile/${projectId}`);
      return response.data.item;
    } catch (error) {
      if (onError) onError(get(error, 'response.data.message', ''), error);
      return error.response.data.message;
    }
  }
);

export const createProfile = createAsyncThunk(
  'profile/createProfile',
  async ({ payload, onSuccess, onError }) => {
    try {
      const response = await backHttpClient.post('/users/profile', payload);

      if (onSuccess) onSuccess();
      return response.data.item;
    } catch (error) {
      if (onError) onError(get(error, 'response.data.message', ''), error);
      return error.response.data.message;
    }
  }
);

export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  async ({ payload, onSuccess, onError }) => {
    try {
      const response = await backHttpClient.patch('/users/profile', payload);
      if (onSuccess) onSuccess();
      return response.data.item;
    } catch (error) {
      if (onError) onError(get(error, 'response.data.message', ''), error);
      return error.response.data.message;
    }
  }
);

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'rejected';
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = '';
      });

    builders
      .addCase(createProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'rejected';
      })
      .addCase(createProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = '';
      });

    builders
      .addCase(updateProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'rejected';
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = '';
      });
  },
});

export default profileSlice.reducer;

import ReactModal from 'react-modal';
import styled from 'styled-components';
import { CloseIcon } from '../ui/iconography';

export function Modal({
  style = {},
  content = {},
  overlay = {},
  children,
  closeIcon = true,
  ...props
}) {
  const finalStyle = {
    ...style,
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: '500px',
      border: 'none',
      padding: '30px',

      h4: {
        textAlign: 'center',
      },
      ...content,
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      zIndex: '10',
      ...(overlay || {}),
    },
  };

  return (
    <ReactModal {...props} style={finalStyle}>
      <ModalCloseWrapper role="button" onClick={props.onRequestClose}>
        {closeIcon && <CloseIcon />}
      </ModalCloseWrapper>
      {children}
    </ReactModal>
  );
}

const ModalCloseWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  background-size: cover;
  cursor: pointer;
`;

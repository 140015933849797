import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CallAvatar,
  CallModalHeader,
  CallModalHeaderControls,
  VideoCallActionButtonGroup,
} from '../styles';
import { toggleVideoCallMic, toggleVideoCallVideo } from '../../video/video-slice';
import { Modal } from '../../ui/modal';
import {
  RemoteVideo,
  VideoCallActionSquareButton,
  LocalVideo,
  MinimizedCallActions,
  ConfigButton,
} from './styles';
import styled from 'styled-components';
import NoiseBgHelper from '../../../images/noise_bg_helper.png';
import AVControlsModal from '../../broadcast/AVControlsModal';

function CallModal({ close, isOpen, peer, onToggleMinimize, isVideoMinimized }) {
  const dispatch = useDispatch();
  const { isMicMuted, isVideoMuted, isWaitingForCall, publisher } = useSelector(
    (state) => state.videoCall
  );
  const [isOpenCameraSwitchModal, setIsOpenCameraSwitchModal] = useState(false);
  const handleCameraChange = async (camearDevice) => {
    await publisher.setVideoSource(camearDevice.deviceId);
  };

  const handleVideoToggle = () => {
    dispatch(toggleVideoCallVideo());
  };
  const handleMicToggle = () => {
    dispatch(toggleVideoCallMic());
  };
  const url = peer
    ? peer.profile && peer.profile[0]?.profilePictureUrl
      ? peer.profile[0]?.profilePictureUrl
      : `https://ui-avatars.com/api/?name=${encodeURIComponent(
          peer?.profile[0]?.name ?? peer.name
        )}`
    : '';
  return (
    <>
      <Modal
        onRequestClose={null}
        isOpen={isOpen}
        content={{
          backgroundColor: '#000000',
          color: '#ffffff',
          width: '70%',
          height: '70%',
          maxWidth: null,
          display: 'flex',
          flexDirection: 'column',
          padding: 0,
        }}
        overlay={isVideoMinimized ? { height: 0, zIndex: -1 } : null}
        closeIcon={false}
      >
        <CallModalHeaderMax className="call-modal-header" id="full-player">
          {peer && <p>{`In Call with ${peer?.profile[0]?.name ?? peer.name}`}</p>}
          <CallModalHeaderControls>
            <button onClick={onToggleMinimize}>
              <i className="far fa-minus-circle" />
            </button>
            <button onClick={close}>
              <i className="far fa-times-circle" />
            </button>
          </CallModalHeaderControls>
        </CallModalHeaderMax>
        <RemoteVideo id="remote-video">
          {isWaitingForCall && (
            <RemoteVideoPreview>
              <CallAvatar src={url} />
              <div>Waiting for user to Join </div>
            </RemoteVideoPreview>
          )}
          <div id="remote-video-stage" />
        </RemoteVideo>
        <VideoCallActionButtonNoise>
          <MinimizedCallActions className="actions-wrap">
            <VideoCallActionSquareButton
              type="button"
              className={`orange ${isVideoMuted ? 'off' : 'on'}`}
              onClick={handleVideoToggle}
            >
              {isVideoMuted ? (
                <i className="fa fa-video-slash active" />
              ) : (
                <i className="fa fa-video active" />
              )}
            </VideoCallActionSquareButton>
            <VideoCallActionSquareButton
              type="button"
              className={`green ${isMicMuted ? 'off' : 'on'}`}
              onClick={handleMicToggle}
            >
              {isMicMuted ? (
                <i className="fa fa-microphone-slash active" />
              ) : (
                <i className="fa fa-microphone active" />
              )}
            </VideoCallActionSquareButton>
            <VideoCallActionSquareButton type="button" onClick={close} className="red">
              <i className="fa fa-phone-slash active" />
            </VideoCallActionSquareButton>
          </MinimizedCallActions>
        </VideoCallActionButtonNoise>
        <LocalVideoMax id="local-video" />
        {publisher && (
          <StyledConfigButton className="yellow" onClick={() => setIsOpenCameraSwitchModal(true)}>
            <i className="fa fa-wrench"></i>
          </StyledConfigButton>
        )}
      </Modal>
      {/* Camera Change Modal */}
      {isOpenCameraSwitchModal && (
        <AVControlsModal
          close={() => setIsOpenCameraSwitchModal(false)}
          onDoneBroadcast={handleCameraChange}
          actionText="Change Camera"
        />
      )}
    </>
  );
}

const VideoCallActionButtonNoise = styled(VideoCallActionButtonGroup)`
  position: initial;
  width: 100%;
  box-sizing: border-box;
  background-image: url(${NoiseBgHelper});
  background-color: #333333;
  background-blend-mode: overlay;
  box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.35),
    inset 0px 1.5px 0.5px rgba(0, 0, 0, 0.5);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: rgba(255, 255, 255, 75%);
  bottom: 0;
  padding: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .actions-wrap {
    width: 165px;
  }
`;

const LocalVideoMax = styled(LocalVideo)`
  bottom: 120px;
`;

const RemoteVideoPreview = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

const CallModalHeaderMax = styled(CallModalHeader)`
  position: initial;
  min-height: 52px;
  box-sizing: border-box;
  background-image: url(${NoiseBgHelper});
  background-color: #333333;
  background-blend-mode: overlay;
  box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.35),
    inset 0px 1.5px 0.5px rgba(0, 0, 0, 0.5);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: rgba(255, 255, 255, 75%);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const StyledConfigButton = styled(ConfigButton)`
  position: absolute;
  bottom: 120px;
  left: 20px;
  z-index: 10;
`;

export default CallModal;

import './App.css';
import './icons/fontawesome-pro-5.15.3-web/css/all.css';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import LoginPage from './features/auth/LoginPage';
import ResetPasswordPage from './features/auth/ResetPasswordPage';
import InvitePage from './features/invites/InvitePage';
import ResetOrConfirmPage from './features/invites/ResetOrConfirmPage';
import ProjectsPage from './features/projects/ProjectsPage';
import ProjectDetail from './features/projects/ProjectDetail';
import ProjectMainPage from './features/main/ProjectMainPage';
import { ToastContainer } from 'react-toastify';

export default function App() {
  return (
    <div>
      <ToastContainer />
      <Router>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/forgot-password">
            <ResetPasswordPage />
          </Route>
          <Route path="/set-password">
            <ResetOrConfirmPage />
          </Route>
          <LoggedRoute path="/invite-user">
            <InvitePage />
          </LoggedRoute>
          <LoggedRoute path="/" exact>
            <LoginPage />
          </LoggedRoute>
          <LoggedRoute path="/projects" exact>
            <ProjectsPage />
          </LoggedRoute>
          <LoggedRoute path="/projects/:id/room" exact>
            <ProjectMainPage />
          </LoggedRoute>
          <LoggedRoute path="/projects/:id/settings" exact>
            <ProjectDetail />
          </LoggedRoute>
        </Switch>
      </Router>
    </div>
  );
}

function LoggedRoute({ children, ...props }) {
  const { token, expiresIn } = useSelector((state) => state.auth);
  const isAuthenticated = !!token && expiresIn > Date.now();
  return <Route {...props}>{isAuthenticated ? children : <Redirect to="/login" />}</Route>;
}

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TOPIC_GROUP } from '../calls/calls-constants';
import {
  ActionButton,
  ChannelsNavLink,
  CheckLabel,
  ModalButtonsGroup,
  PrimaryButton,
  ChannelIcon,
  ChannelItemLeft,
  ChannelItemRight,
  ChannelIndicatorWrapper,
  ChannelIndicator,
} from '../ui/inputs';
import { AnchorLink, GroupsHeading, UsersListScroll } from '../ui/texts';
import { Modal } from '../ui/modal';
import get from 'lodash.get';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { makeTopicIdForGroups } from '../messages/selected-chat-utils';
import { createAdhocGroup } from './main-slice';

export function Groups({
  activateTopicFn,
  isItemActive,
  project,
  unreadMessagesCount,
  openFileSendingPrompt,
}) {
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.auth);
  const { updatedProjectInfo } = useSelector((state) => state.main);
  const { isSendingMessage } = useSelector((state) => state.messages);
  const [isGroupAddOpen, setIsGroupAddOpen] = useState(false);
  const [groupForUpdate, setGroupForUpdate] = useState(null);
  const { groups = [] } = { ...project, ...updatedProjectInfo };
  const { users = [] } = { ...project, ...updatedProjectInfo };

  const { groupCallState } = useSelector((state) => state.groupVideoCall);

  const getVideoCallParticipantsCount = (groupId) => {
    return groupCallState[`video-call-${TOPIC_GROUP}-${makeTopicIdForGroups(project.id, groupId)}`]
      ?.callParticipants;
  };

  const openGroupAdd = () => {
    setIsGroupAddOpen(true);
    if (groupForUpdate) {
      setGroupForUpdate(null);
    }
  };
  const closeGroupAdd = () => {
    if (isGroupAddOpen) {
      setIsGroupAddOpen(false);
    }

    if (groupForUpdate) {
      setGroupForUpdate(null);
    }
  };

  const handleAddGroupSubmit = (event) => {
    event.preventDefault();
    const participantsIds = Array.from(event.target.groupingUser)
      .filter((it) => it.checked)
      .map((it) => parseInt(it.value, 10));
    if (participantsIds.length < 3) {
      toast.error('Select at least 2 more people for the group.', {
        theme: 'light',
      });
      return;
    }

    let payload = {};
    if (groupForUpdate) {
      const existingParticipants = groupForUpdate.participantsIds;
      const newParticipants = participantsIds.filter(
        (selected) => !existingParticipants.includes(selected)
      );

      payload = {
        groupId: groupForUpdate.id,
        type: 'update-group',
        participantsIds: newParticipants,
      };
    } else {
      payload = {
        type: 'add-group',
        participantsIds,
      };
    }

    dispatch(createAdhocGroup(project.id, payload));
    closeGroupAdd();
  };

  const summarizedGroups = groups
    .filter((group) => group.participantsIds?.includes(userId))
    .map((group) => ({
      ...group,
      amount: group.participantsIds.length,
      name:
        group.name ||
        group.participantsIds
          .map((participantId) => {
            const userNameObject =
              users.find((user) => user.id === participantId).profile[0] ??
              users.find((user) => user.id === participantId);
            return get(userNameObject, 'name', 'Someone');
          })
          .join(', '),
    }));

  const handleGroupClick = (event, groupId) => {
    event.preventDefault();
    event.stopPropagation();
    if (isSendingMessage) {
      openFileSendingPrompt();
      return;
    }
    activateTopicFn(TOPIC_GROUP, groupId)();
  };

  return (
    <>
      {(isGroupAddOpen || groupForUpdate) && (
        <GroupParticipationModal
          groupForUpdate={groupForUpdate}
          close={closeGroupAdd}
          users={users}
          userId={userId}
          onSubmit={handleAddGroupSubmit}
          isOpen
        />
      )}
      <>
        {summarizedGroups.map((group) => (
          <ChannelsNavLink
            key={group.id}
            to="#"
            onClick={(event) => handleGroupClick(event, group.id)}
            $isActive={isItemActive(TOPIC_GROUP, group.id)}
          >
            <ChannelIconGroup>
              <span>
                <i className="fa fa-users"></i>
              </span>
            </ChannelIconGroup>

            <ChannelItemLeft
              title={group.name}
              unread={
                unreadMessagesCount &&
                unreadMessagesCount[
                  `${TOPIC_GROUP}-${makeTopicIdForGroups(project.id, group.id)}`
                ] > 0
              }
            >
              ({group.amount}) {group.name}
            </ChannelItemLeft>

            <ChannelItemRight>
              <ChannelIndicatorWrapper>
                {getVideoCallParticipantsCount(group.id) > 0 && <ChannelIndicator />}
              </ChannelIndicatorWrapper>
            </ChannelItemRight>
          </ChannelsNavLink>
        ))}
      </>
      <ActionButtonThread onClick={openGroupAdd}>
        <i className="fal fa-plus"></i> Start Thread
      </ActionButtonThread>
    </>
  );
}

function GroupParticipationModal(props) {
  const isSelfUser = (user) => user.id === props.userId;

  const isDefaultChecked = (user) => {
    if (isSelfUser(user)) {
      return true;
    }

    const { groupForUpdate } = props;
    if (!groupForUpdate) {
      return false;
    }

    return groupForUpdate.participantsIds.includes(user.id);
  };

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.close}>
      <GroupsHeading>Select users for the creating group:</GroupsHeading>
      <form onSubmit={props.onSubmit}>
        <UsersListScroll>
          {props.users.map((user) => (
            <CheckLabel key={user.id}>
              <input
                name="groupingUser"
                type="checkbox"
                value={user.id}
                disabled={isDefaultChecked(user)}
                defaultChecked={isDefaultChecked(user)}
              />
              <span>{user?.profile[0]?.name ?? user.name}</span> <em>({user.department})</em>{' '}
              {isSelfUser(user) && (
                <small>
                  <em>(You)</em>
                </small>
              )}
            </CheckLabel>
          ))}
        </UsersListScroll>
        <ModalButtonsGroup>
          <PrimaryButton type="submit">
            {props.groupForUpdate ? 'Update group' : 'Create group'}
          </PrimaryButton>
          <AnchorLink to="#" onClick={props.close}>
            Cancel
          </AnchorLink>
        </ModalButtonsGroup>
      </form>
    </Modal>
  );
}

const ActionButtonThread = styled(ActionButton)`
  position: fixed;
  bottom: 15px;
  left: 15px;
  z-index: 99;
  width: 350px;
  box-sizing: border-box;
`;

export const ChannelIconGroup = styled(ChannelIcon)`
  span {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(128, 128, 128, 0.2) 100%),
      #f5ebc0;
  }
`;

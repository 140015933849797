import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: '',
    uuid: '',
    userId: -1,
    canBroadcast: false,
    isGlobalAdmin: false,
    expiresIn: '',
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload.token;
      state.userId = action.payload.userId;
      state.uuid = action.payload.uuid;
      state.canBroadcast = action.payload.canBroadcast;
      state.isGlobalAdmin = action.payload.isGlobalAdmin;
      state.name = action.payload.name;
      state.expiresIn = action.payload.expiresIn;
    },
    clearToken: (state) => {
      state.token = '';
      state.userId = -1;
      state.canBroadcast = false;
      state.isGlobalAdmin = false;
      state.expiresIn = '';
    },
  },
});

export const { setToken, clearToken } = authSlice.actions;

export default authSlice.reducer;

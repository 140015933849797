import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import backHttpClient from '../../../app/backend-http-client';
import { ProjectAvatarImage } from '../project-main/styles';
import {
  StyledProjectDetailNav,
  StyledProjectsNavInner,
  NavHeader,
  NavItem,
  NavList,
  ProjectToggleText,
  ModalMessageDisabled,
  StyledProjectsNavInnerHeader,
  ActionsWrapper,
} from './styles';
import { AnchorLink } from '../../ui/texts';
import {
  Toggle,
  ToggleLabel,
  ToggleWrapper,
  ModalButtonsGroup,
  PrimaryButton,
} from '../../ui/inputs';
import { Modal } from '../../ui/modal';

function ProjectDetailNav({
  initialIndex = 0,
  onIndexChanged,
  projectTitle,
  projectId,
  projectLogo,
  isGlobalAdmin,
  isProjectAdmin,
  isProjectOwner,
  isProjectMember,
  toggleEditProjectModal,
  enabled,
}) {
  const [activeIndex, setActiveIndex] = useState(initialIndex);
  const [projectEnabled, setProjectEnabled] = useState(enabled);
  const [isDisabledMessageOpen, setIsDisabledMessageOpen] = useState(false);
  const handleIndexUpdate = (index) => {
    setActiveIndex(index);
    onIndexChanged(index);
  };

  const history = useHistory();
  const handleProjectRoomClick = () => {
    history.push(`/projects/${projectId}/room`);
  };

  const updateLogo = () => {
    toggleEditProjectModal(true);
  };

  const handleToggleClick = async () => {
    if (projectEnabled) {
      setIsDisabledMessageOpen(true);
    } else {
      await backHttpClient.post(`projects/${projectId}/disable`, { disabled: false });
      setProjectEnabled(true);
      history.push(`/projects`);
    }
  };

  const handleCloseDisableDialog = () => {
    setIsDisabledMessageOpen(false);
  };

  const disableProject = async () => {
    await backHttpClient.post(`projects/${projectId}/disable`, { disabled: true });
    setProjectEnabled(false);
    handleCloseDisableDialog();
    history.push(`/projects`);
  };

  return (
    <StyledProjectDetailNav>
      <StyledProjectsNavInner>
        <StyledProjectsNavInnerHeader>
          <NavHeader>
            <div className="image-area" onClick={updateLogo}>
              <ProjectAvatarImage
                src={
                  projectLogo ||
                  `https://ui-avatars.com/api/?name=${encodeURIComponent(
                    projectTitle || 'No Image'
                  )}`
                }
                alt="Project logo"
              />
              <span className="overlay">
                <i className="fal fa-edit"></i>
              </span>
            </div>
            <span>{`${projectTitle || 'No Project Loaded'}`}</span>
          </NavHeader>
          {isGlobalAdmin ? (
            <ActionsWrapper>
              <ToggleWrapper>
                <Toggle
                  id="enabledToggle"
                  type="checkbox"
                  checked={projectEnabled}
                  onClick={handleToggleClick}
                />
                <ToggleLabel htmlFor="enabledToggle" />
              </ToggleWrapper>
              <ProjectToggleText>{projectEnabled ? 'Enabled' : 'Disabled'}</ProjectToggleText>
            </ActionsWrapper>
          ) : null}
        </StyledProjectsNavInnerHeader>
        <NavList>
          <NavItem onClick={() => handleIndexUpdate(0)} active={activeIndex === 0}>
            Users and Roles
          </NavItem>
          <NavItem onClick={() => handleIndexUpdate(1)} active={activeIndex === 1}>
            Channels
          </NavItem>
          {(!isGlobalAdmin || isProjectAdmin || isProjectOwner || isProjectMember) && (
            <NavItem onClick={handleProjectRoomClick}>Video Room</NavItem>
          )}
        </NavList>
      </StyledProjectsNavInner>
      <Modal isOpen={isDisabledMessageOpen} onRequestClose={handleCloseDisableDialog}>
        <ModalMessageDisabled>Are you sure you want to disable this project?</ModalMessageDisabled>
        <ModalButtonsGroup>
          <PrimaryButton type="button" onClick={disableProject}>
            Disable
          </PrimaryButton>
          <AnchorLink to="#" onClick={handleCloseDisableDialog}>
            Cancel
          </AnchorLink>
        </ModalButtonsGroup>
      </Modal>
    </StyledProjectDetailNav>
  );
}

export default ProjectDetailNav;

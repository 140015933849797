import styled from 'styled-components';
import RedExclamation from '../../icons/red_exclamation.svg';
import TimesCircle from '../../icons/times-circle.svg';

export const ErrorIcon = styled.i`
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url(${RedExclamation});
  background-repeat: no-repeat;
  background-position: center;
`;

export const CloseIcon = styled.i`
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url(${TimesCircle});
  background-repeat: no-repeat;
  background-position: center;
`;

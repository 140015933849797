import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { parseISO, format } from 'date-fns';
import { Tooltip } from '../../ui/inputs';
import ProjectTile from './ProjectTile';
import {
  StyledProjectsCol,
  StyledProjectLogo,
  CameraLogoIcon,
  StyledProjectDetails,
  StyledProjectDetailName,
  StyledProjectDetail,
  StyledProjectTile,
  AddProjectIcon,
  CreateLabel,
} from './styles';

function AdminProjects({ projects, onRequestAddProject }) {
  const { isGlobalAdmin } = useSelector((state) => state.auth);
  const history = useHistory();
  async function handleProjectClicked(id, enabled) {
    if (enabled || isGlobalAdmin) history.push(`/projects/${id}/settings`);
  }

  return (
    <>
      <StyledProjectsCol>
        <StyledProjectTile onClick={onRequestAddProject} enabled={true}>
          <AddProjectIcon className="fa fa-plus-circle" />
          <CreateLabel>Create Project</CreateLabel>
        </StyledProjectTile>
      </StyledProjectsCol>
      {projects.map((project) => (
        <StyledProjectsCol
          key={project.id}
          onClick={() => handleProjectClicked(project.id, project.enabled)}
        >
          <ProjectTile project={project}>
            <StyledProjectLogo>
              {project.logoUrl ? (
                <img src={project.logoUrl} />
              ) : (
                <CameraLogoIcon className="fa fa-image" />
              )}
            </StyledProjectLogo>

            <StyledProjectDetails>
              {project.enabled ? (
                <>
                  <StyledProjectDetailName>{project.name}</StyledProjectDetailName>
                  <StyledProjectDetail>{project.owner?.name}</StyledProjectDetail>
                  <StyledProjectDetail>
                    {format(parseISO(project.createdAt), 'MMMM d, yyyy')}
                  </StyledProjectDetail>
                </>
              ) : (
                <Tooltip toolTipText="Project has been disabled">
                  <StyledProjectDetailName>{project.name}</StyledProjectDetailName>
                  <StyledProjectDetail>{project.owner?.name}</StyledProjectDetail>
                  <StyledProjectDetail>
                    {format(parseISO(project.createdAt), 'MMMM d, yyyy')}
                  </StyledProjectDetail>
                </Tooltip>
              )}
            </StyledProjectDetails>
          </ProjectTile>
        </StyledProjectsCol>
      ))}
    </>
  );
}

export default AdminProjects;

import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import backHttpClient from '../../app/backend-http-client';
import { PrimaryButton, InputLabel, TextInput, CheckLabel, CheckboxGroup } from '../ui/inputs';
import { AnchorLink, Heading, ErrorText } from '../ui/texts';
import { Modal } from '../ui/modal';
import { ErrorIcon } from '../../features/ui/iconography.js';
import { ModalButtonsGroup, DeleteConfirmText } from '../projects/project-detail/styles';
import { ModalMessage } from '../main/project-main-sidebar/styles.js';
import { toast } from 'react-toastify';

function AddUserForm({ projectChannels, onFinish, projectId, isProjectOwner }) {
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [channels, setChannels] = useState([]);
  const [isConfirmingProjectOwnerChange, setIisConfirmingProjectOwnerChange] = useState(false);
  const projectOwnerRef = useRef(null);
  const userNameRef = useRef(null);

  const channelSelectOptions = projectChannels.map(({ id, label, color }) => ({
    value: id,
    label,
    color,
  }));

  const handleChannelSelect = (valueType, ActionType) => {
    const choice = valueType.map((selectedChannel) => selectedChannel.value);
    setChannels(choice);
  };

  const handleCheckProjectOwner = (event) => {
    if (event.target.checked) {
      setIisConfirmingProjectOwnerChange(true);
    }
  };

  const handleProjectOwnerPrompt = (action = 'CONTINUE') => {
    if (action === 'CANCEL') {
      projectOwnerRef.current.checked = false;
    }
    setIisConfirmingProjectOwnerChange(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    setErrorMessage('');

    try {
      const invitation = {
        email: event.target.email.value.trim(),
        name: event.target.name.value.trim(),
        channel: channels,
        projectRole: event.target.projectRole.value.trim(),
        department: event.target.department.value.trim(),
        canBroadcast: event.target.canBroadcast.checked,
        isProjectAdmin: event.target.isProjectAdmin.checked || event.target.isProjectOwner.checked, // If a projectOwner is checked, the person should also be a ProjectAdmin
        isProjectOwner: event.target.isProjectOwner.checked,
        projectId,
      };
      const { data: invited } = await backHttpClient.post('/auth/invite', invitation);
      toast.success(`Invited ${invited.name} ${invited.email}.`, {
        theme: 'light',
      });
      onFinish();
    } catch (error) {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Unknown error while sending invite, please contact support team.');
        console.error('Error on invite.', error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <main>
        <StyledAddUserForm onSubmit={handleSubmit}>
          <header>
            <Heading>Add User</Heading>
          </header>
          {errorMessage && (
            <p>
              <ErrorIcon /> <ErrorText>{errorMessage}</ErrorText>
            </p>
          )}
          <InputLabel>
            <span>Email*</span>
            <TextInput
              name="email"
              type="email"
              disabled={isLoading}
              placeholder="User Email"
              autoComplete="off"
              required
            />
          </InputLabel>

          <InputLabel>
            <span>Name*</span>
            <TextInput
              name="name"
              ref={userNameRef}
              disabled={isLoading}
              placeholder="Name"
              autoComplete="off"
              required
            />
          </InputLabel>

          <InputLabel>
            <span>Department*</span>
            <TextInput
              name="department"
              disabled={isLoading}
              placeholder="Department"
              autoComplete="off"
              required
            />
          </InputLabel>

          <InputLabel>
            <span>Project Role*</span>
            <TextInput
              name="projectRole"
              disabled={isLoading}
              placeholder="Project Role"
              autoComplete="off"
              required
            />
          </InputLabel>

          {/* All users are automatically added to the default channels so list only the custom channels. */}
          {/* Do we add the ability for the admin to add a new channel if it doesn't exist during user creation? */}
          {channelSelectOptions.length ? (
            <InputLabel>
              <span>Channels</span>
              <Select
                options={channelSelectOptions}
                isMulti
                aria-label="Channel Channels"
                placeholder="Choose Channels"
                name="channel"
                onChange={handleChannelSelect}
                required
              />
            </InputLabel>
          ) : null}

          <CheckboxGroup>
            <CheckLabel>
              <input name="canBroadcast" type="checkbox" disabled={isLoading} />
              <span>Broadcaster</span>
            </CheckLabel>

            <CheckLabel>
              <input name="isProjectAdmin" type="checkbox" disabled={isLoading} />
              <span>Admin</span>
            </CheckLabel>

            <CheckLabel>
              <input
                name="isProjectOwner"
                ref={projectOwnerRef}
                type="checkbox"
                disabled={isLoading}
                onChange={handleCheckProjectOwner}
              />
              <span>Project Owner</span>
            </CheckLabel>
          </CheckboxGroup>

          {isLoading && <p>Creating user...</p>}

          <CreateButton type="submit" disabled={isLoading}>
            Create
          </CreateButton>
          <CancelAnchorLink to="#" onClick={onFinish}>
            Cancel
          </CancelAnchorLink>
        </StyledAddUserForm>

        <Modal
          isOpen={isConfirmingProjectOwnerChange}
          onRequestClose={() => setIisConfirmingProjectOwnerChange(false)}
        >
          <DeleteConfirmText>
            <ModalMessage>
              {`You have chosen to assign ${
                userNameRef.current?.value ? userNameRef.current.value : 'this user'
              } as the project owner to this project. Performing this action will unassign this role if it is currently assigned to another user`}
              .
            </ModalMessage>
            <ModalMessage>Do you want to continue?</ModalMessage>
          </DeleteConfirmText>
          <ModalButtonsGroup>
            <PrimaryButton onClick={() => handleProjectOwnerPrompt('CONTINUE')}>
              Continue
            </PrimaryButton>
            <AnchorLink to="#" onClick={() => handleProjectOwnerPrompt('CANCEL')}>
              Cancel
            </AnchorLink>
          </ModalButtonsGroup>
        </Modal>
      </main>
    </>
  );
}

const StyledAddUserForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 30px;
  background: white;

  ${InputLabel} {
    width: 100%;
    margin: 0.5rem 0;
  }
`;

const CreateButton = styled(PrimaryButton)`
  width: 100%;
  margin: 1rem 0;
`;

const CancelAnchorLink = styled(AnchorLink)`
  width: 100%;
  text-align: center;
`;

export default AddUserForm;

import { useHistory } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { Tooltip } from '../../ui/inputs';
import { useSelector } from 'react-redux';
import ProjectTile from './ProjectTile';
import {
  StyledProjectsCol,
  StyledProjectLogo,
  CameraLogoIcon,
  StyledProjectDetails,
  StyledProjectDetailName,
  StyledProjectDetail,
} from './styles';

function UserProjects({ projects }) {
  const history = useHistory();
  const { isGlobalAdmin } = useSelector((state) => state.auth);
  async function handleProjectClicked(id, enabled) {
    if (enabled || isGlobalAdmin) history.push(`/projects/${id}/room`);
  }

  return projects.map((project) => (
    <StyledProjectsCol
      key={project.id}
      onClick={() => handleProjectClicked(project.id, project.enabled)}
    >
      <ProjectTile project={project}>
        <StyledProjectLogo>
          {project.logoUrl ? (
            <img src={project.logoUrl} />
          ) : (
            <CameraLogoIcon className="fa fa-image" />
          )}
        </StyledProjectLogo>
        <StyledProjectDetails>
          {project.enabled ? (
            <>
              <StyledProjectDetailName>{project.name}</StyledProjectDetailName>
              <StyledProjectDetail>{project.owner?.name}</StyledProjectDetail>
              <StyledProjectDetail>
                {format(parseISO(project.createdAt), 'MMMM d, yyyy')}
              </StyledProjectDetail>
            </>
          ) : (
            <Tooltip toolTipText="Project has been disabled">
              <StyledProjectDetailName>{project.name}</StyledProjectDetailName>
              <StyledProjectDetail>{project.owner?.name}</StyledProjectDetail>
              <StyledProjectDetail>
                {format(parseISO(project.createdAt), 'MMMM d, yyyy')}
              </StyledProjectDetail>
            </Tooltip>
          )}
        </StyledProjectDetails>
      </ProjectTile>
    </StyledProjectsCol>
  ));
}

export default UserProjects;

import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import uniqBy from 'lodash.uniqby';
import sortBy from 'lodash.sortby';
import backHttpClient from '../../app/backend-http-client';
import { toast } from 'react-toastify';

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    channel: '',
    publishKey: '',
    subscribeKey: '',
    uuid: '',
    pubsub: null,
    isReady: false,
    items: {},
    isLoadingMessage: true,
    unreadMessageCountByChannel: {},
    chatViewTypeIsFilesByChannel: {},
    chatChannels: [],
    currentTopic: '',
    listener: null,
    isSendingMessage: false,
    isOpenAttachmentPreviewModal: false,
    previewAttachment: null,
  },
  reducers: {
    setActiveChatChannel: (state, action) => {
      state.channel = `${action.payload.topic}-${action.payload.id}`;
    },
    setChatCredentials: (state, action) => {
      state.publishKey = action.payload.publishKey;
      state.subscribeKey = action.payload.subscribeKey;
      state.uuid = state.uuid || action.payload.uuid;
    },
    clearChatData: (state) => {
      state.channel = '';
      state.publishKey = '';
      state.subscribeKey = '';
      state.pubsub = null;
      state.items = {};
      state.isLoadingMessage = true;
      state.unreadMessageCountByChannel = {};
      state.chatViewTypeIsFilesByChannel = {};
      state.chatChannels = [];
      state.currentTopic = '';
    },
    setChatPubSubClient: (state, action) => {
      state.pubsub = action.payload;
    },
    setReady: (state, action) => {
      state.isReady = action.payload;
    },

    setLoadingState: (state, action) => {
      state.isLoadingMessage = action.payload;
    },
    setCurrentTopic: (state, action) => {
      state.currentTopic = action.payload;
    },

    addMessageItem: (state, action) => {
      state.items = {
        ...state.items,
        [action.payload.channel]: sortBy(
          uniqBy([...(state.items[action.payload.channel] || []), action.payload], 'uid'),
          'timetoken'
        ),
      };
    },

    addMessagesHistory: (state, action) => {
      state.items = {
        ...state.items,
        [action.payload.channel]: sortBy(
          uniqBy(
            [...action.payload.messages, ...(state.items[action.payload.channel] || [])],
            'uid'
          ),
          'timetoken'
        ),
      };
      state.isLoadingMessage = false;
    },

    setChannelMessageUnreadCount: (state, action) => {
      state.unreadMessageCountByChannel = {
        ...state.unreadMessageCountByChannel,
        ...action.payload,
      };
    },

    setInitialChatViewTypeIsFiles: (state, action) => {
      state.chatViewTypeIsFilesByChannel = {
        ...state.chatViewTypeIsFilesByChannel,
        ...action.payload,
      };
    },

    updateChatViewTypeIsFiles: (state, action) => {
      state.chatViewTypeIsFilesByChannel = {
        ...state.chatViewTypeIsFilesByChannel,
        [action.payload]: !state.chatViewTypeIsFilesByChannel[action.payload],
      };
    },

    setChatChannels: (state, action) => {
      state.chatChannels = [...state.chatChannels, ...action.payload];
    },

    setChatEventListener: (state, action) => {
      state.listener = action.payload;
    },
    setSendingMessageProgress: (state, action) => {
      state.isSendingMessage = action.payload;
    },
    toggleAttachmentPreview: (state) => {
      state.isOpenAttachmentPreviewModal = !state.isOpenAttachmentPreviewModal;
    },
    setPreviewAttachment: (state, action) => {
      state.previewAttachment = action.payload;
    },
    resetPreviewAttachment: (state) => {
      state.previewAttachment = null;
    },
  },
});

export const getUnreadMessageCount = (pubnub, userChannels) => async (dispatch, getState) => {
  const { uuid } = getState().auth;
  const membershipRes = await pubnub.objects.getMemberships({
    uuid,
    include: {
      customFields: true,
    },
  });

  // Get unread message count for the channels
  if (membershipRes.data.length) {
    const userChannelsTimetokens = membershipRes.data.filter((singleData) =>
      userChannels.includes(singleData.channel.id)
    );
    pubnub.messageCounts(
      {
        channels: userChannelsTimetokens.map((data) => data.channel.id),
        channelTimetokens: userChannelsTimetokens.map((data) => data.custom.lastReadTimetoken),
      },
      (status, results) => {
        if (status.statusCode === 200 && results) {
          dispatch(messagesSlice.actions.setChannelMessageUnreadCount(results.channels));
        }
      }
    );
  }
};

export const initMessagePubNub = (pubnub, userChannels) => async (dispatch) => {
  dispatch(messagesSlice.actions.setChatChannels(userChannels));
  dispatch(messagesSlice.actions.setChatPubSubClient(pubnub));

  // Given the various channels, set the initial count to 0 inside unreadMessageCountByChannel.
  // This way all the channels a user subscribes to will be initialized with an unreadCount of 0
  const initialUnreadCount = userChannels.reduce((countByChannel, channelName) => {
    countByChannel[channelName] = 0;
    return countByChannel;
  }, {});
  dispatch(messagesSlice.actions.setChannelMessageUnreadCount(initialUnreadCount));

  // Initialize channels and their initial view state: i.e viewing messages or viewing attachments.
  const initialChatViewTypeIsFiles = userChannels.reduce((viewTypeByChannel, channelName) => {
    viewTypeByChannel[channelName] = false;
    return viewTypeByChannel;
  }, {});
  dispatch(messagesSlice.actions.setInitialChatViewTypeIsFiles(initialChatViewTypeIsFiles));

  if (userChannels.length) {
    dispatch(getUnreadMessageCount(pubnub, userChannels));
  }
};

export const updateUreadMessageCount =
  ({ pubnub, channel, lastMessage }) =>
  async (dispatch) => {
    // Get unread message count for the channels
    pubnub.messageCounts(
      {
        channels: [channel],
        channelTimetokens: [lastMessage.timetoken],
      },
      (status, results) => {
        if (status.statusCode === 200) {
          dispatch(messagesSlice.actions.setChannelMessageUnreadCount(results.channels));
        }
      }
    );
  };

export const setChannelMembershipData = async ({ pubnub, uuid, channel, lastMessage }) => {
  await pubnub.objects.setMemberships({
    uuid,
    channels: [
      {
        id: channel,
        custom: {
          lastReadTimetoken: lastMessage.timetoken,
        },
      },
    ],
  });
};

export const handleIncomingMessage = (event) => (dispatch, getState) => {
  const { channel: activeChannel } = getState().messages;
  // When a message is received from a channel which is not the currently active channel
  // update the unread count. get the currently active channel

  if (activeChannel !== event.channel) {
    dispatch(messagesSlice.actions.setChannelMessageUnreadCount({ [event.channel]: 1 }));
  }

  dispatch(
    messagesSlice.actions.addMessageItem({
      channel: event.channel,
      timetoken: event.timetoken,
      uid: event.message.uid,
      authorId: event.message.authorId || -1,
      text: event.message.text || '',
      attachment: event.message.attachment || {},
      createdAt: event.message.createdAt || new Date().toISOString(),
    })
  );
};

export const fetchMessages = (pubnub) => async (dispatch, getState) => {
  const { channel } = getState().messages;
  const { uuid } = getState().auth;

  pubnub.fetchMessages({ channels: [channel], includeMeta: true }, async (_status, response) => {
    const messages =
      (response &&
        response.channels[channel] &&
        response.channels[channel].map((it) => ({
          ...it.message,
          timetoken: it.timetoken,
        }))) ||
      [];

    dispatch(
      messagesSlice.actions.addMessagesHistory({
        channel,
        messages,
      })
    );

    // set the lastReadTimetoken for the channel to the timetoken of the last message fetched
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      await setChannelMembershipData({ uuid, channel, pubnub, lastMessage });
      dispatch(updateUreadMessageCount({ pubnub, channel, lastMessage }));
    }
  });
};

export const startChat =
  ({ topic, entityId, pubnub }) =>
  async (dispatch, getState) => {
    const { uuid } = getState().auth;

    dispatch(messagesSlice.actions.setLoadingState(true));
    dispatch(messagesSlice.actions.setChatCredentials({ topic, entityId, uuid }));
    dispatch(messagesSlice.actions.setActiveChatChannel({ topic, id: entityId }));

    dispatch(fetchMessages(pubnub));
  };

export const stopChat = () => (dispatch, _getState) => {
  dispatch(messagesSlice.actions.setReady(false));
  dispatch(messagesSlice.actions.clearChatData());
};

export const setSendingMessageProgress = (isSending) => (dispatch) => {
  dispatch(messagesSlice.actions.setSendingMessageProgress(isSending));
};

export const sendMessage =
  ({ channel, text, file, messageCallback }) =>
  async (dispatch, getState) => {
    dispatch(setSendingMessageProgress(true));
    const { userId } = getState().auth;
    const { pubsub: pubnub } = getState().messages;

    let attachmentUrl = '';
    let attachmentType = '';
    let attachmentName = '';
    if (file) {
      try {
        const res = await backHttpClient.post(
          `/projects/${file.projectId}/attachment-upload`,
          file.attachment,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        attachmentUrl = res.data.attachmentUrl;
        attachmentType = file.type;
        attachmentName = file.name;
      } catch (error) {
        toast.error('There was an error trying to attach your file. Please try again later.', {
          theme: 'light',
        });

        dispatch(setSendingMessageProgress(false));
        return messageCallback();
      }
    }

    if ((!text || !text.trim()) && !file) {
      return;
    }

    pubnub.publish(
      {
        channel,
        message: {
          uid: uuidv4(),
          authorId: userId,
          createdAt: new Date().toISOString(),
          text,
          attachment: {
            url: attachmentUrl,
            type: attachmentType,
            name: attachmentName,
          },
        },
      },
      (status) => {
        if (status.error) {
          console.error('Error on message publish', status.error);
          toast.error('Unknown error: could not send message.', {
            theme: 'light',
          });
        }
        dispatch(setSendingMessageProgress(false));
        messageCallback();
      }
    );
  };

// Sets the currently active messaging channel inside the messaging stateåå
export const setCurrentTopic = (topic) => (dispatch) => {
  dispatch(messagesSlice.actions.setCurrentTopic(topic));
};

export const setIsReady = () => (dispatch) => {
  dispatch(messagesSlice.actions.setReady(true));
};

export const toggleChatViewTypePerChannel = (channel) => (dispatch) => {
  dispatch(messagesSlice.actions.updateChatViewTypeIsFiles(channel));
};

export const setMessagesPubnubListener = (listener) => (dispatch) => {
  dispatch(messagesSlice.actions.setChatEventListener(listener));
};

export const stopMessagesPubnubListener = () => (_dispatch, getState) => {
  const { pubsub: pubnub, listener } = getState().messages;
  if (pubnub) {
    pubnub.removeListener(listener);
    pubnub.unsubscribeAll();
  }
};

export const toggleAttachmentPreview = () => (dispatch) => {
  dispatch(messagesSlice.actions.toggleAttachmentPreview());
};

export const setPreviewAttachment = (attachmentData) => (dispatch) => {
  dispatch(messagesSlice.actions.setPreviewAttachment(attachmentData));
};

export const resetPreviewAttachment = () => (dispatch) => {
  dispatch(messagesSlice.actions.resetPreviewAttachment());
};

export default messagesSlice.reducer;

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { clearToken } from '../../auth/auth-slice';
import { projectsActions } from '../../projects/projects-duck';
import { Modal } from '../../ui/modal';
import { AnchorLink } from '../../ui/texts';
import { ModalButtonsGroup, PrimaryButton } from '../../ui/inputs';
import { StylingMainOptionsBody, MainOptionsItem, ModalMessage } from './styles';

function MainOptionsBody(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const makeItemClickHandler = (handler) => async () => {
    await handler();
    props.close();
  };

  const { isGlobalAdmin } = useSelector((state) => state.auth);
  const projectState = useSelector((state) => state.projects);
  const { items: projects } = projectState;
  const project = projects.find((_project) => _project.id === Number(props.id));
  const isProjectAdmin = project?.isProjectAdmin;
  const isProjectOwner = project?.isProjectOwner;

  const handleSignOutClick = makeItemClickHandler(() => {
    dispatch(clearToken());
    dispatch(projectsActions.readAll(null, null, true));
  });

  const [isSignOutOpen, setSignOutOpen] = useState(false);
  const openSignOut = () => setSignOutOpen(true);
  const closeSignOut = () => setSignOutOpen(false);

  return (
    <>
      <StylingMainOptionsBody>
        <MainOptionsItem onClick={() => history.push('/projects')}>
          <span>View All Projects</span>
        </MainOptionsItem>
        {(isProjectAdmin || isProjectOwner || isGlobalAdmin) && (
          <MainOptionsItem onClick={() => history.push(`/projects/${props.id}/settings`)}>
            <span>Project Settings</span>
          </MainOptionsItem>
        )}
        <MainOptionsItem onClick={props.openProfileForm}>
          <span>Profile</span>
          <i className="fal fa-user"></i>
        </MainOptionsItem>
        <MainOptionsItem onClick={openSignOut}>
          <span>Sign out</span>
          <i className="fal fa-sign-out"></i>
        </MainOptionsItem>
      </StylingMainOptionsBody>

      {/* Sign up Confirmation Modal */}
      <Modal isOpen={isSignOutOpen} onRequestClose={closeSignOut}>
        <ModalMessage>Are you sure you want to sign out?</ModalMessage>
        <ModalButtonsGroup>
          <PrimaryButton type="button" onClick={handleSignOutClick}>
            Sign out
          </PrimaryButton>
          <AnchorLink to="#" onClick={closeSignOut}>
            Cancel
          </AnchorLink>
        </ModalButtonsGroup>
      </Modal>
      {/* End of sign up confirmation modal */}
    </>
  );
}

export default MainOptionsBody;

import axios from 'axios';
import { TOPIC_CHANNEL, TOPIC_GROUP, TOPIC_USER } from '../features/calls/calls-constants';
import {
  makeTopicIdForChannels,
  makeTopicIdForUsersFn,
} from '../features/messages/selected-chat-utils';

/**
 * Get the access level for a Project User
 * @param {*} projectUser
 * @returns
 */
export const getUserAccessLevel = (projectUser) => {
  if (projectUser.isProjectOwner) {
    return 'Project Owner';
  }
  if (projectUser.isProjectAdmin) {
    return 'Project Admin';
  }
  return 'Project Member';
};

/**
 *
 * @param {*} array
 * @param {*} size
 * @returns
 */
export function createChunk(array, size) {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size));
  }
  return chunkedArray;
}

/**
 *
 * @param {*} project
 * @param {*} userId
 * @returns
 */
export const createUserTopics = (project, userId) =>
  project.users.map(
    (user) => `${TOPIC_USER}-${makeTopicIdForUsersFn(project.id, user.id)(userId)}`
  );

/**
 *
 * @param {*} project
 * @returns
 */
export const createChannelTopics = (project, userId) => {
  const authUser = project.users.find((user) => user.id === Number(userId));
  const authUserChannels = authUser.Channels.filter(
    (singleChannel) => singleChannel.projectId === project.id
  );
  return authUserChannels.map(
    (channel) => `${TOPIC_CHANNEL}-${makeTopicIdForChannels(project.id, channel.id)}`
  );
};

/**
 *
 * @param {*} project
 * @returns
 */
export const createGroupTopics = (project, userId) => {
  const authUserGroups = project.groups.filter((group) => group?.participantsIds?.includes(userId));
  return authUserGroups.map(
    (group) => `${TOPIC_GROUP}-${makeTopicIdForChannels(project.id, group.id)}`
  );
};
export const getAllPubnubMessagingChannels = (project, userId) => {
  return [
    ...createUserTopics(project, userId),
    ...createChannelTopics(project, userId),
    ...createGroupTopics(project, userId),
  ];
};

/**
 *
 * @param {*} project
 * @param {*} prop eg. groups, channels
 * @returns
 */
export const groupParticipantByProjectProp = (project, prop) =>
  project[prop].reduce((participantByProp, propVal) => {
    participantByProp[propVal.id] = propVal.participantsIds;
    return participantByProp;
  }, {});

/**
 * Download a file attachment
 * @param {*} url
 */
export const downloadFile = async (url, filename) => {
  try {
    const res = await axios({
      url: `${url}?not-from-cache-please`,
      method: 'GET',
      responseType: 'blob',
      crossdomain: true,
    });
    const newBlob = new Blob([res.data]);
    const blobUrl = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    return error;
  }
};

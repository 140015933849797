import styled from 'styled-components';
import { PrimaryButton } from '../../ui/inputs';
import { CloseIcon } from '../../ui/iconography';

export const DeleteConfirmText = styled.div`
  margin-bottom: 5px;
`;
export const StyledAddChannel = styled.div`
  width: ${(props) => (props.isOpen ? '30%' : `0%`)};
  display: ${(props) => (props.isOpen ? 'inherit' : `none`)};
  margin-left: 20px;
  background-color: #ffffff;
  padding: 20px;
`;
export const TableHead = styled.thead`
  td {
    font-weight: 900;
  }
`;
export const TableRow = styled.tr`
  border-bottom: 1px solid #8c8c8c55;
  height: 40px;
`;

export const ChannelLabel = styled.div.attrs((props) => ({
  style: {
    backgroundColor: props.isDefault ? '#8c8c8c55' : `${props.color}55`,
    borderColor: props.isDefault ? '#8c8c8c' : props.color,
    color: props.color,
  },
}))`
  display: inline;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-width: 1px;
  border-style: solid;
  padding: 4px 6px;
  font-size: 10px;
  font-weight: 800;
`;

export const EditCell = styled.p.attrs((props) => ({
  style: {
    cursor: 'pointer',
    color: '#000000',
  },
}))`
  margin: 0 10px 0 0;
  text-decoration: underline;
`;
export const DeleteCell = styled.p.attrs(({ editable }) => ({
  style: {
    cursor: editable ? 'pointer' : 'inherit',
    color: editable ? '#000000' : '#c4c4c4c4',
  },
}))`
  cursor: pointer;
  color: #000000;
  margin: 0 10px 0 0;
  text-decoration: underline;
`;
export const ReInviteCell = styled.p`
  cursor: pointer;
  color: #000000;
  margin: 0 10px 0 0;
  text-decoration: underline;
`;

export const ActionCell = styled.td`
  p {
    display: inline-block;
  }
`;

export const ProjectUsersTable = styled.table`
  display: inline-table;
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    padding: 2px 20px;
  }
`;
export const ProjectChannelsTable = styled.table`
  display: inline-table;
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    padding: 2px 20px;
  }
`;
export const AddButton = styled(PrimaryButton)`
  height: 24px;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;
export const AddIcon = styled.i``;
export const AddText = styled.p`
  margin: 0px 4px;
`;

export const StyledProjectChannels = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

export const ProjectDetailsStage = styled.div`
  background-color: #ffffff;
  height: 100%;
  border-radius: 6px;
  width: ${(props) => (props.isSidePanelOpen ? '70%' : `100%`)};
  overflow: auto;
`;

export const ProjectUsersHeader = styled.div`
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProjectUsersHeaderText = styled.h4`
  margin: 0px;
  display: flex;
  justify-content: space-between;
`;

export const NavHeader = styled.h4`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 20px;
  display: flex;
  align-items: center;

  .image-area {
    position: relative;
    display: inline-block;
    margin-right: 10px;
  }

  .overlay {
    display: none;
  }

  .image-area:hover .overlay {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    color: white;
    padding: 10px;
    font-size: 15px;
    cursor: pointer;
  }

  img {
    vertical-align: top;
  }
`;

export const StyledProjectDetailNav = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0px;
  border-bottom: 1px solid #d4d4d4;
  background: #fff;
`;

export const StyledProjectsNavInner = styled.div`
  height: 80px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ProjectDetailBody = styled.div`
  height: calc(100% - 120px);
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
`;

export const NavList = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0px;
  padding: 0px;
  align-items: flex-end;
`;

export const NavItem = styled.li`
  margin-right: 20px;
  padding-bottom: 2px;
  cursor: pointer;
  border-bottom: 1px solid;
  border-bottom-color: ${(props) => (props.active ? '#000000' : 'transparent')};

  &:hover {
    border-bottom: ${(props) => (!props.active ? '1px solid #000000' : '1px solid #000000')};
  }
`;

export const ProjectLoading = styled.p`
  display: flex;
`;

export const StylingProjectDetail = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const ModalButtonsGroup = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 30px;

  ${PrimaryButton} {
    width: 100%;
    margin-bottom: 5px;
  }
`;

export const NewChannelForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LabelColorInput = styled.input`
  height: 0px;
  width: 0px;
  border: none;
`;
export const UserChannelRow = styled.div`
  display: flex;
  position: relative;
`;
export const LabelColorField = styled.div`
  width: 100%;
  height: 25px;
  border: none;
  background: none;
  outline: none;
  border-bottom: 1px solid #c4c4c4;
  padding: 5px 0px;

  &:focus {
    border-bottom: 1px solid black;
  }

  p {
    margin: 0px;
    color: #8e8e8e;
  }
`;

export const ChannelListIcon = styled.i`
  display: block;
  margin-left: 10px;
  color: #8e8e8e;
  cursor: pointer;
`;
export const ChannelList = styled.div`
  position: absolute;
  max-width: 70%;
  background-color: #ffffff;
  top: 42px;
  z-index: 100;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  padding: 2px;
  border: 1px solid #c4c4c4;
`;
export const EmptyChannelList = styled.div`
  position: absolute;
  width: 200px;
  background-color: #ffffff;
  top: 25px;
  z-index: 100;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  padding: 2px;
  border: 1px solid #c4c4c4;
  text-align: center;
  padding: 5px;
`;

export const ListChannelLabel = styled(ChannelLabel)`
  margin-right: 2px;
  margin-top: 2px;
  border-width: 1px;
  cursor: pointer;
  padding: 2px;
  height: inherit;
  font-size: 10px;
  font-weight: 800;
  flex: 0 0 auto;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const RowListChannelLabel = styled(ChannelLabel)`
  margin-right: 5px;
  border-width: 1px;
  cursor: pointer;
  position: relative;
`;

export const SelectedChannelList = styled.div`
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  padding: 2px;
  border-bottom: 1px solid #c4c4c4;
`;
export const ChannelCell = styled.td`
  display: inline-flex;
  flex-wrap: wrap;
`;
export const ChannelRemoveIcon = styled(CloseIcon)`
  position: absolute;
  top: -5px;
  right: -6px;
  background-color: #cdc9c9;
  border-radius: 10px;
`;

export const ModalMessage = styled.p`
  margin: 0 0 15px;
  text-align: left;
  font-size: 18px;
  font-weight: normal;
`;

export const StyledProjectsNavInnerHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionsWrapper = styled.div`
  display: flex;
`;

export const ProjectToggleText = styled.h4`
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 4px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
`;

export const ModalMessageDisabled = styled.p`
  margin: 10px 0 30px;
  text-align: left;
  font-size: 20px;
  font-weight: normal;
`;

import React from 'react';
import styled from 'styled-components';
import useDownloadAttachment from '../../hooks/useDownloadAttachment';

function AttachmentDownloadButton({ attachmentUrl, attachmentName }) {
  const { downloadAttachment, isDownloading, fileName } = useDownloadAttachment();

  return (
    <AttachmentDownload onClick={() => downloadAttachment(attachmentUrl, attachmentName)}>
      <i
        className={
          isDownloading && fileName === attachmentUrl
            ? 'fas fa-spinner fa-spin'
            : 'fal fa-cloud-download'
        }
      ></i>
    </AttachmentDownload>
  );
}

const AttachmentDownload = styled.button`
  display: block;
  color: #fff;
  border: none;
  background: none;
  &:hover {
    cursor: pointer;
  }
`;

export default AttachmentDownloadButton;

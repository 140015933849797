import axios from 'axios';
import { store } from '../app/store';

const backHttpClient = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND}/api`,
});

backHttpClient.interceptors.request.use((config) => {
  config.headers.Authorization = store.getState().auth.token;
  return config;
});

if (process.env.NODE_ENV === 'development') {
  window.backHttpClient = backHttpClient;
}

export default backHttpClient;

import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../ui/modal';
import { ModalButtonsGroup, PrimaryButton } from '../ui/inputs';
import { MembersList } from './styles';
import { ProjectAvatarImage } from '../projects/project-main/styles';
import { useSelectedChatName } from '../messages/selected-chat-utils';
import styled from 'styled-components';
import { updateAdhocGroupName } from './main-slice';

export default function ChannelMemberViewModals({
  close,
  activeChannelName,
  channelMembers,
  project,
  isAdhocGroup = false,
  ...props
}) {
  const { channel, pubsub: pubnub } = useSelector((state) => state.messages);
  const [occupants, setOccupants] = useState([]);
  const [isOpenEditNameInput, setIsOpenEditNameInput] = useState(false);
  const [adhocGroupName, setAdhocGroupName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [erroMessage, setErrorMessage] = useState('');
  const selectedChatName = useSelectedChatName();
  const dispatch = useDispatch();

  const getOnlineState = useCallback(() => {
    pubnub.hereNow(
      {
        channels: [channel],
        includeState: true,
      },
      function (status, response) {
        if (!status.error) {
          setOccupants((currentOccupants) => [
            ...currentOccupants,
            ...response.channels[channel].occupants.map((singleOccupant) => singleOccupant.uuid),
          ]);
        }
      }
    );
  }, [channel, pubnub]);

  const handleUpdateAdhocGroupName = () => {
    if (!adhocGroupName?.trim()) {
      setErrorMessage('Please write a name to ad-hoc group');
      setIsError(true);
      return;
    }
    setIsLoading(true);
    const onSuccess = () => {
      setIsOpenEditNameInput(false);
      setIsLoading(false);
      setIsError(false);
      setAdhocGroupName('');
    };
    const onError = () => {
      setIsLoading(false);
      setErrorMessage('Sorry, error updating name. Contact admin');
      setIsError(true);
    };
    const groupId = [channel.substr(channel.lastIndexOf('-') + 1)];
    dispatch(
      updateAdhocGroupName(project.id, { groupId, name: adhocGroupName }, onSuccess, onError)
    );
  };

  useEffect(() => {
    getOnlineState();
  }, [getOnlineState]);

  return (
    <Modal onRequestClose={close} {...props} content={{ maxHeight: '500px' }}>
      <ModalButtonsGroup>
        {!isOpenEditNameInput && (
          <h2
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              flex: 1,
              margin: '0 auto',
            }}
          >
            {activeChannelName || selectedChatName}
          </h2>
        )}
        {/* Adhoc group display and name update */}
        {isAdhocGroup && (
          <StyledAdhocNameUpdateWrapper>
            {isOpenEditNameInput ? (
              <input
                type="text"
                name="adhoGroupName"
                onChange={(event) => setAdhocGroupName(event.target.value)}
                onFocus={() => setIsError(false)}
              />
            ) : (
              <button onClick={() => setIsOpenEditNameInput(true)}>Edit</button>
            )}
            <br />
            {isError && <span className="error">{erroMessage}</span>}
          </StyledAdhocNameUpdateWrapper>
        )}

        <MembersList>
          {channelMembers.map((member) => (
            <li key={member.id}>
              <div className="userInfo">
                <ProjectAvatarImage
                  src={
                    member?.profile[0]?.profilePictureUrl ||
                    `https://ui-avatars.com/api/?name=${
                      member?.profile[0]?.name ?? member.name
                    }&bold=true&background=000000&color=9d8c72&format=PNG`
                  }
                  key={member.id}
                />
              </div>
              <div className="userInfo">{member?.profile[0]?.name ?? member.name}</div>
              <div className="userInfo">{member.ProjectUsers.projectRole}</div>
              <div className="userInfo">
                <span className={`status ${occupants.includes(member.uuid) && 'online'}`}></span>
                <span>{occupants.includes(member.uuid) ? 'Online' : 'Offline'}</span>
              </div>
            </li>
          ))}
        </MembersList>
        {isOpenEditNameInput ? (
          <PrimaryButton onClick={handleUpdateAdhocGroupName} disabled={isLoading}>
            {isLoading ? 'Updating...' : 'Save Changes'}
          </PrimaryButton>
        ) : (
          <PrimaryButton onClick={close}>Close</PrimaryButton>
        )}
      </ModalButtonsGroup>
    </Modal>
  );
}

const StyledAdhocNameUpdateWrapper = styled.div`
  text-align: center;
  margin-top: 5px;

  button {
    cursor: pointer;
    background: transparent;
    outline: none;
    border: none;
    text-decoration: underline;
    font-size: 16px;
    font-weight: normal;
  }

  input,
  input:focus {
    min-width: 281px;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
  }

  .error {
    display: block;
    margin-top: 5px;
    color: crimson;
  }
`;

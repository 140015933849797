import { createSlice } from '@reduxjs/toolkit';
import PubNub from 'pubnub';
import backHttpClient from '../../app/backend-http-client';
import vonageClient from '../../app/vonage-client';
import { v4 as uuidv4 } from 'uuid';
import { CALL_ACTIONS, VONAGE_INSERT_MODES } from '../calls/calls-constants';
import { toast } from 'react-toastify';

export const videoCallSlice = createSlice({
  name: 'videocall',
  initialState: {
    camera: null,
    microphone: null,
    isInVideoCall: false,
    isWaitingForCall: false,
    invitedUser: null,
    isMicMuted: true,
    isVideoMuted: true,
    isAudioMuted: false,
    session: null,
    sessionId: '',
    subscriberStream: null,
    publisherToken: '',
    publisher: null,
    peerId: null,
    registeredPeers: [],
    pubSub: null,
    isIncomingCall: false,
    invitingUser: null,
    invitingSessionId: null,
    listener: null,
  },
  reducers: {
    setVideoSessionCredentials: (state, action) => {
      state.apiKey = action.payload.apiKey;
      state.sessionId = action.payload.sessionId;

      if (action.payload.session) {
        state.session = action.payload.session;
      }
    },
    setVideoSession: (state, action) => {
      state.session = action.payload;
    },
    setVideoPublisherToken: (state, action) => {
      state.publisherToken = action.payload;
    },
    setIsInVideoCall: (state, action) => {
      state.isInVideoCall = action.payload;
    },
    setIsMicMuted: (state, action) => {
      state.isMicMuted = action.payload;
    },
    setIsVideoMuted: (state, action) => {
      state.isVideoMuted = action.payload;
    },
    setIsWaitingForCall: (state, action) => {
      state.isWaitingForCall = action.payload;
    },
    setIsAudioMuted: (state, action) => {
      state.isAudioMuted = action.payload;
    },
    setVideoPublisher: (state, action) => {
      state.publisher = action.payload;
    },
    setSubscriberStream: (state, action) => {
      state.subscriberStream = action.payload;
    },
    setPeerId: (state, action) => {
      state.peerId = action.payload;
    },
    setUserMedia: (state, action) => {
      state.stream = action.payload.stream;
      state.microphone = action.payload.microphone;
    },
    setPubSub: (state, action) => {
      state.pubSub = action.payload;
    },
    setIsIncomingCall: (state, action) => {
      state.isIncomingCall = action.payload;
    },
    setIncomingCaller: (state, action) => {
      state.invitingUser = action.payload;
    },
    setIncomingSessionId: (state, action) => {
      state.invitingSessionId = action.payload;
    },
    setInvitedCaller: (state, action) => {
      state.invitedUser = action.payload;
    },
    setVideoEventListener: (state, action) => {
      state.listener = action.payload;
    },
  },
});

export const videoCallListener = (dispatch, userId) => {
  return {
    message: (event) => {
      if (!event.message.uid) {
        throw new Error('Missing message identifier.');
      }
      if (!event.message.inviterId || event.message.inviterId === userId) {
        return 'ignoring request';
      }
      if (event.message.type === CALL_ACTIONS.INVITE) {
        return inviteHandler(dispatch, event);
      }
      if (event.message.type === CALL_ACTIONS.REJECT) {
        return rejectHandler(dispatch);
      }
      if (event.message.type === CALL_ACTIONS.CANCEL) {
        return cancelHandler(dispatch, event);
      }
      if (event.message.type === CALL_ACTIONS.HANG_UP) {
        return hangUpHandler(dispatch);
      }
    },
  };
};

export const registerVideoPeers =
  (projectId, userList = []) =>
  async (dispatch, getState) => {
    const { userId, uuid } = getState().auth;
    let { pubSub, listener: videoListener } = getState().videoCall;

    const channels = userList.map(
      (peerId) => `video-call-${projectId}-${[userId, peerId].sort((a, b) => a - b).join('-')}`
    );

    const response = await backHttpClient.get('pubnub');
    const { publishKey, subscribeKey } = response.data;
    if (!pubSub) {
      pubSub = new PubNub({ publishKey, subscribeKey, uuid });
      dispatch(videoCallSlice.actions.setPubSub(pubSub));
    }

    // This condition prevents multiple listener creation when the registerVideoPeers
    // is called multiple times inside BroadcastPanel's useEffect
    if (!videoListener) {
      const listener = {
        message: (event) => {
          if (!event.message.uid) {
            throw new Error('Missing message identifier.');
          }
          if (!event.message.inviterId || event.message.inviterId === userId) {
            return 'ignoring request';
          }
          if (event.message.type === CALL_ACTIONS.INVITE) {
            return inviteHandler(dispatch, event);
          }
          if (event.message.type === CALL_ACTIONS.REJECT) {
            return rejectHandler(dispatch);
          }
          if (event.message.type === CALL_ACTIONS.CANCEL) {
            return cancelHandler(dispatch, event);
          }
          if (event.message.type === CALL_ACTIONS.HANG_UP) {
            return hangUpHandler(dispatch);
          }
        },
      };

      pubSub.addListener(listener);
      dispatch(videoCallSlice.actions.setVideoEventListener(listener));
    }
    pubSub.subscribe({ channels, withPresence: true });
  };

export const startVideoCallPublisher =
  (projectId, inviteeId, camera, microphone) => async (dispatch, getState) => {
    if (!camera && !microphone) {
      console.error(
        'Tried to start video call without devices, so dispatch is being ignored.',
        camera,
        microphone
      );
      return;
    }

    // Early dispatch to show call modal to get target element loaded in DOM
    dispatch(videoCallSlice.actions.setIsInVideoCall(true));
    const { isMicMuted, isVideoMuted } = getState().videoCall;
    const response = await backHttpClient.post(
      `/projects/${projectId}/videocall/invite/${inviteeId}`
    );
    const { sessionId, token, apiKey } = response.data;

    const session = vonageClient.initSession(apiKey, sessionId);
    const publisher = vonageClient.initVideoCallPublisher(
      'local-video',
      {
        publishAudio: !isMicMuted,
        publishVideo: !isVideoMuted,
      },
      (error) => {
        if (error) return console.error('Error on init publisher for one to one video.');
        console.log('video call publisher initialized');

        dispatch(
          videoCallSlice.actions.setVideoSessionCredentials({
            apiKey,
            sessionId,
            session,
          })
        );
        const inprogressElement = document.querySelector('audio#inprogress-tone');
        if (inprogressElement) {
          inprogressElement.muted = false;
          inprogressElement.play();
        }
        dispatch(videoCallSlice.actions.setVideoSession(session));
        dispatch(videoCallSlice.actions.setIsMicMuted(true));
        dispatch(videoCallSlice.actions.setIsVideoMuted(true));
        dispatch(videoCallSlice.actions.setVideoPublisherToken(token));
        dispatch(videoCallSlice.actions.setVideoPublisher(publisher));
      }
    );

    //  #TODO - allow user to close modal manually
    publisher.on('streamDestroyed', () => {
      dispatch(videoCallSlice.actions.setIsInVideoCall(false));
    });

    dispatch(videoCallSlice.actions.setIsWaitingForCall(true));
    dispatch(videoCallSlice.actions.setInvitedCaller(inviteeId));
    dispatch(sendSessionInvite(projectId, session.id, inviteeId));
    session.on('streamCreated', function streamCreated(event) {
      const subscriberOptions = {
        subscribeToVideo: true,
        subscribeToAudio: true,
        fitMode: 'cover',
        style: { buttonDisplayMode: 'off', audioLevelDisplayMode: 'off' },
        insertMode: VONAGE_INSERT_MODES.REPLACE,
      };
      session.subscribe(event.stream, 'remote-video-stage', subscriberOptions);

      dispatch(videoCallSlice.actions.setSubscriberStream(event.stream));
      dispatch(videoCallSlice.actions.setIsWaitingForCall(false));

      const incomingElement = document.querySelector('audio#incoming-tone');
      if (incomingElement) {
        incomingElement.pause();
      }
      const inprogressElement = document.querySelector('audio#inprogress-tone');
      if (inprogressElement) {
        inprogressElement.pause();
      }
    });
    session.on('streamDestroyed', function streamDestroyed(_event) {
      hangUpHandler(dispatch);
    });
    await vonageClient.connectSession(session, token);

    if (camera) {
      publisher.setVideoSource(camera.deviceId);
    }

    if (microphone) {
      publisher.setAudioSource(microphone.deviceId);
    }

    session.publish(publisher, (error) => {
      if (error) {
        console.error('Error on publishing stream.');
        dispatch(videoCallSlice.actions.setIsWaitingForCall(false));
      }

      dispatch(videoCallSlice.actions.setIsWaitingForCall(true));
      dispatch(videoCallSlice.actions.setPeerId(inviteeId));
    });
  };

export const stopVideoCallPublisher = () => (dispatch, getState) => {
  const { videoCall } = getState();
  const { session, publisher } = videoCall;
  if (!session || !publisher) {
    console.warn('Tried to stop video call but with no enough resources, ignoring it.');
    return;
  }

  session.unpublish(publisher);
  session.disconnect();

  dispatch(videoCallSlice.actions.setVideoPublisherToken(''));
  dispatch(videoCallSlice.actions.setVideoPublisher(null));
  dispatch(videoCallSlice.actions.setIsInVideoCall(false));
  dispatch(videoCallSlice.actions.setVideoSession(null));
  dispatch(videoCallSlice.actions.setVideoSessionCredentials({ apiKey: '', sessionId: '' }));
};

export const toggleVideoCallMic = () => (dispatch, getState) => {
  const { isMicMuted, publisher } = getState().videoCall;
  if (!publisher) {
    console.warn('Unable to toggle mic when not in audio room');
  }
  const newMicState = !isMicMuted;
  publisher.publishAudio(!newMicState);
  dispatch(videoCallSlice.actions.setIsMicMuted(newMicState));
};
export const toggleVideoCallVideo = () => (dispatch, getState) => {
  const { isVideoMuted, publisher } = getState().videoCall;
  if (!publisher) {
    console.warn('Unable to toggle mic when not in audio room');
  }
  const newVideoState = !isVideoMuted;
  publisher.publishVideo(!newVideoState);
  dispatch(videoCallSlice.actions.setIsVideoMuted(newVideoState));
};

export const toggleVideoCallAudio = () => (dispatch, getState) => {
  const { isAudioMuted, session } = getState().videoCall;
  if (!session) {
    console.warn('Unable to toggle mic when not in audio room');
  }
  const muteAllStreams = !isAudioMuted;
  dispatch(videoCallSlice.actions.setIsAudioMuted(muteAllStreams));
};

// Invite user to call (Caller)
export const sendSessionInvite = (projectId, sessionId, peerId) => (dispatch, getState) => {
  const { userId, name } = getState().auth;
  const { pubSub } = getState().videoCall;
  const channel = `video-call-${projectId}-${[userId, peerId].sort((a, b) => a - b).join('-')}`;

  pubSub.publish(
    {
      channel: channel,
      message: {
        uid: uuidv4(),
        type: 'invite',
        inviterId: userId,
        inviterName: name,
        createdAt: new Date().toISOString(),
        sessionId,
      },
    },
    (status) => {
      if (status.error) {
        console.error('Error on message publish', status.error);
        toast.error('Unknown error: could not publish message.', {
          theme: 'light',
        });
      }
    }
  );
};
// Cancel invite to call (Caller)
export const cancelSessionInvite = (projectId, sessionId, peerId) => (dispatch, getState) => {
  const { userId, name } = getState().auth;
  const { pubSub } = getState().videoCall;
  const channel = `video-call-${projectId}-${[userId, peerId].sort((a, b) => a - b).join('-')}`;
  pubSub.publish(
    {
      channel: channel,
      message: {
        uid: uuidv4(),
        type: 'cancel',
        inviterId: userId,
        inviterName: name,
        createdAt: new Date().toISOString(),
        sessionId,
      },
    },
    (status) => {
      if (status.error) {
        console.error('Error on message publish', status.error);
        toast.error('Unknown error: could not publish message.', {
          theme: 'light',
        });
      }
    }
  );

  dispatch(videoCallSlice.actions.setIsWaitingForCall(false));
  dispatch(videoCallSlice.actions.setInvitedCaller(null));
  dispatch(videoCallSlice.actions.setIsInVideoCall(false));
  dispatch(stopVideoCallPublisher());

  const inprogressElement = document.querySelector('audio#inprogress-tone');
  if (inprogressElement) {
    inprogressElement.pause();
  }
};

// Accept call invite  (Callee)
export const acceptSessionInvite =
  (projectId, camera, microphone) => async (dispatch, getState) => {
    const { userId } = getState().auth;
    const { pubSub, invitingSessionId, invitingUser, session, isMicMuted, isVideoMuted } =
      getState().videoCall;

    const response = await backHttpClient.post(
      `/projects/${projectId}/videocall/accept/${invitingSessionId}`,
      { role: 'publisher' }
    );
    const { token, apiKey } = response.data;

    if (session) session.disconnect();

    const newSession = vonageClient.initSession(apiKey, invitingSessionId);
    dispatch(videoCallSlice.actions.setIsInVideoCall(true));
    const publisher = vonageClient.initVideoCallPublisher(
      'local-video',
      {
        publishAudio: !isMicMuted,
        publishVideo: !isVideoMuted,
      },
      (error) => {
        if (error) return console.error('Error on init publisher for audio channel.');
        console.log('video call publisher initialized');
      },
      'replace'
    );
    dispatch(
      videoCallSlice.actions.setVideoSessionCredentials({
        apiKey,
        sessionId: invitingSessionId,
        session: newSession,
      })
    );
    publisher.on('streamDestroyed', () => {
      dispatch(videoCallSlice.actions.setIsInVideoCall(false));
    });

    dispatch(videoCallSlice.actions.setVideoSession(newSession));
    dispatch(videoCallSlice.actions.setVideoPublisherToken(token));
    dispatch(videoCallSlice.actions.setVideoPublisher(publisher));
    dispatch(videoCallSlice.actions.setIsMicMuted(true));

    newSession.on('streamCreated', function streamCreated(event) {
      const subscriberOptions = {
        subscribeToVideo: true,
        subscribeToAudio: true,
        fitMode: 'cover',
        style: { buttonDisplayMode: 'off', audioLevelDisplayMode: 'off' },
      };

      newSession.subscribe(event.stream, 'remote-video-stage', subscriberOptions);

      dispatch(videoCallSlice.actions.setSubscriberStream(event.stream));
    });
    newSession.on('streamDestroyed', function streamDestroyed(_event) {
      hangUpHandler(dispatch);
    });
    await vonageClient.connectSession(newSession, token);

    if (camera) {
      publisher.setVideoSource(camera.deviceId);
    }

    if (microphone) {
      publisher.setAudioSource(microphone.deviceId);
    }

    newSession.publish(publisher, (error) => {
      if (error) {
        console.error('Error on publishing stream.');
      }
      console.log('publishing in video call');
      dispatch(videoCallSlice.actions.setIsIncomingCall(false));
    });

    const channel = `video-call-${projectId}-${[userId, invitingUser]
      .sort((a, b) => a - b)
      .join('-')}`;
    const incomingElement = document.querySelector('audio#incoming-tone');
    if (incomingElement) {
      incomingElement.pause();
    }
    const inprogressElement = document.querySelector('audio#inprogress-tone');
    if (inprogressElement) {
      inprogressElement.pause();
    }
    pubSub.publish(
      {
        channel: channel,
        message: {
          uid: uuidv4(),
          type: CALL_ACTIONS.ACCEPT,
          createdAt: new Date().toISOString(),
          sessionId: invitingSessionId,
        },
      },
      (status) => {
        if (status.error) {
          console.error('Error on message publish', status.error);
          toast.error('Unknown error: could not publish message.', {
            theme: 'light',
          });
        }
        const inprogressElement = document.querySelector('audio#inprogress-tone');
        if (inprogressElement) {
          inprogressElement.pause();
        }
      }
    );
  };

// Reject call invite (Callee)
export const rejectSessionInvite = (projectId, sessionId, peerId) => (dispatch, getState) => {
  const { userId, name } = getState().auth;
  const { pubSub } = getState().videoCall;
  const channel = `video-call-${projectId}-${[userId, peerId].sort((a, b) => a - b).join('-')}`;

  pubSub.publish(
    {
      channel: channel,
      message: {
        uid: uuidv4(),
        type: CALL_ACTIONS.REJECT,
        inviterId: userId,
        inviterName: name,
        createdAt: new Date().toISOString(),
        sessionId,
      },
    },
    (status) => {
      if (status.error) {
        console.error('Error on message publish', status.error);
        toast.error('Unknown error: could not publish message.', {
          theme: 'light',
        });
      }
    }
  );

  const incomingElement = document.querySelector('audio#incoming-tone');
  if (incomingElement) {
    incomingElement.pause();
  }

  dispatch(videoCallSlice.actions.setIsIncomingCall(false));
  dispatch(videoCallSlice.actions.setIncomingCaller(null));
  dispatch(videoCallSlice.actions.setIncomingSessionId(null));
};

// Cancel invite to call (Caller)
export const endVideoCall = (projectId, sessionId, peerId) => (dispatch, getState) => {
  const { userId, name } = getState().auth;
  const { pubSub } = getState().videoCall;
  const channel = `video-call-${projectId}-${[userId, peerId].sort((a, b) => a - b).join('-')}`;
  pubSub.publish(
    {
      channel: channel,
      message: {
        uid: uuidv4(),
        type: CALL_ACTIONS.HANG_UP,
        inviterId: userId,
        inviterName: name,
        createdAt: new Date().toISOString(),
        sessionId,
      },
    },
    (status) => {
      if (status.error) {
        console.error('Error on message publish', status.error);
        toast.error('Unknown error: could not publish message.', {
          theme: 'light',
        });
      }
    }
  );

  dispatch(stopVideoCallPublisher());
};

export const toggleUI = (isMinimized) => (_dispatch, getState) => {
  const { publisher, session, subscriberStream } = getState().videoCall;
  if (!publisher || !session || !subscriberStream) {
    return;
  }
  const subscriber = session.getSubscribersForStream(subscriberStream)[0];

  if (isMinimized) {
    const miniRemote = document.getElementById('remote-video-minimized');
    const miniLocal = document.querySelector('#local-video-minimized');
    miniLocal.appendChild(publisher.element);
    miniRemote.appendChild(subscriber.element);
  } else {
    const maxRemote = document.getElementById('remote-video');
    const maxLocal = document.querySelector('#local-video');
    maxLocal.appendChild(publisher.element);
    maxRemote.appendChild(subscriber.element);
  }
};
export default videoCallSlice.reducer;

function hangUpHandler(dispatch) {
  console.log('user has left call');
  dispatch(stopVideoCallPublisher());
  dispatch(videoCallSlice.actions.setIsWaitingForCall(false));
  dispatch(videoCallSlice.actions.setInvitedCaller(null));
  dispatch(videoCallSlice.actions.setIsInVideoCall(false));
}

function cancelHandler(dispatch, event) {
  console.log('user has cancel call request');
  dispatch(videoCallSlice.actions.setIsIncomingCall(false));
  dispatch(videoCallSlice.actions.setIncomingCaller(null));
  dispatch(videoCallSlice.actions.setIncomingSessionId(event.message.sessionId));
  dispatch(stopVideoCallPublisher());
  const incomingElement = document.querySelector('audio#incoming-tone');
  if (incomingElement) {
    incomingElement.pause();
  }
}

function rejectHandler(dispatch) {
  console.log('user has rejected the call');
  dispatch(videoCallSlice.actions.setIsWaitingForCall(false));
  dispatch(videoCallSlice.actions.setInvitedCaller(null));
  dispatch(stopVideoCallPublisher());
  const inprogressElement = document.querySelector('audio#inprogress-tone');
  if (inprogressElement) {
    inprogressElement.pause();
  }
}

function inviteHandler(dispatch, event) {
  dispatch(videoCallSlice.actions.setIsIncomingCall(true));
  dispatch(
    videoCallSlice.actions.setIncomingCaller({
      name: event.message.inviterName || 'Anonymous',
      id: event.message.inviterId,
    })
  );
  dispatch(videoCallSlice.actions.setIncomingSessionId(event.message.sessionId));

  const incomingElement = document.querySelector('audio#incoming-tone');
  if (incomingElement) {
    incomingElement.muted = false;
    incomingElement.play();
  }
}

export const setVideoCallPubnubListener = (listener) => (dispatch) => {
  dispatch(videoCallSlice.actions.setVideoEventListener(listener));
};

export const setVideoCallPubnubInstance = (pubnub) => (dispatch) => {
  dispatch(videoCallSlice.actions.setPubSub(pubnub));
};

export const stopVideoCallPubnubListener = () => (_dispatch, getState) => {
  const { pubSub, listener } = getState().videoCall;
  if (pubSub) {
    pubSub.removeListener(listener);
    pubSub.unsubscribeAll();
  }
};

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { projectsActions } from '../../projects/projects-duck';
import { Modal } from '../../ui/modal';
import { ErrorIcon } from '../../ui/iconography';
import { InputLabel, PrimaryButton, TextInput } from '../../ui/inputs';
import { AnchorLink, ErrorText, ModalHeading } from '../../ui/texts';
import { toast } from 'react-toastify';

import {
  NewProjectRow,
  ProjectAvatarImage,
  ModalButtonsGroup,
  HiddenFileInput,
  EmptyAvatarImage,
} from './styles';
import { PROJECT_LOGO_MAX_SIZE } from '../../../app/constants';

function NewProject({ ...props }) {
  const [projectLogo, setProjectLogo] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setIsCreating(true);

    const newProject = {
      name: event.target.name.value.trim(),
      userName: event.target.userName.value.trim(),
      email: event.target.email.value.trim(),
      companyName: event.target.companyName.value.trim(),
      logoBase64: projectLogo,
    };
    const onSuccess = (name) => {
      toast.success(`Project ${name} created`, {
        theme: 'light',
      });
      cleanUpAndClose();
    };
    const onError = (error) => {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Unknown error while creating project, please contact support team.');
      }
      setIsCreating(false);
    };
    dispatch(projectsActions.create(newProject, onSuccess, onError));
  };

  function cleanUpAndClose() {
    setIsCreating(false);
    setProjectLogo('');
    props.close();
  }
  function handleLogoSelected(event) {
    if (event.target.files[0].size > PROJECT_LOGO_MAX_SIZE) {
      setErrorMessage('Logo should be less than or equal to  1MB in size');
    } else {
      const fr = new FileReader();
      fr.onload = (e) => {
        setProjectLogo(e.target.result);
      };
      fr.readAsDataURL(event.target.files[0]);
    }
  }
  return (
    <Modal onRequestClose={cleanUpAndClose} {...props}>
      <form onSubmit={handleSubmit}>
        <header>
          <ModalHeading>Create Project</ModalHeading>
        </header>
        {errorMessage && (
          <p>
            <ErrorIcon /> <ErrorText>{errorMessage}</ErrorText>
          </p>
        )}
        <NewProjectRow>
          {projectLogo ? (
            <ProjectAvatarImage src={projectLogo} alt="image" />
          ) : (
            <EmptyAvatarImage />
          )}
          <label to="#" htmlFor="logo-upload">
            Add Project Logo
          </label>
          <HiddenFileInput
            type="file"
            id="logo-upload"
            accept="image/*"
            onChange={handleLogoSelected}
          />
        </NewProjectRow>
        <InputLabel>
          <span>Project Name*</span>
          <TextInput name="name" placeholder="Add Project Name" autoComplete="off" required />
        </InputLabel>
        <br />
        <InputLabel>
          <span>Brand/Company Name*</span>
          <TextInput
            name="companyName"
            placeholder="Add Brand/Company Name"
            autoComplete="off"
            required
          />
        </InputLabel>
        <br />
        <InputLabel>
          <span>Project Owner Name*</span>
          <TextInput
            name="userName"
            placeholder="Add Project Owner Name"
            autoComplete="off"
            required
          />
        </InputLabel>
        <br />
        <InputLabel>
          <span>Project Owner Email*</span>
          <TextInput
            type="email"
            name="email"
            placeholder="Add Project Owner Email"
            autoComplete="off"
            required
          />
        </InputLabel>
        <br />
        <ModalButtonsGroup>
          <PrimaryButton type="submit" disabled={isCreating}>
            {isCreating ? <i className="fas fa-spinner fa-spin" /> : 'Create Project'}
          </PrimaryButton>
          <AnchorLink to="#" onClick={cleanUpAndClose}>
            Cancel
          </AnchorLink>
        </ModalButtonsGroup>
      </form>
    </Modal>
  );
}

export default NewProject;

import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePubNub } from 'pubnub-react';
import { getAllPubnubMessagingChannels } from '../utils/helpers';
import {
  handleIncomingMessage,
  setIsReady,
  setMessagesPubnubListener,
} from '../features/messages/messages-slice';

function useInitializeMessages(project) {
  const pubnub = usePubNub();
  const dispatch = useDispatch();
  const listener = useRef(null);

  const { userId } = useSelector((state) => state.auth);
  const { updatedProjectInfo } = useSelector((state) => state.main);

  const handleMessage = (event) => {
    dispatch(handleIncomingMessage(event));
  };

  const handleStatus = (event) => {
    if (event.category === 'PNConnectedCategory') {
      dispatch(setIsReady(true));
    } else if (event.category === 'PNNetworkUpCategory') {
      pubnub.reconnect();
    }
  };

  listener.current = {
    status: handleStatus,
    message: handleMessage,
  };

  useEffect(() => {
    if (project) {
      const updatedProj = { ...project, ...updatedProjectInfo };
      const allMessageChannels = getAllPubnubMessagingChannels(updatedProj, userId);
      pubnub.addListener(listener.current);
      pubnub.subscribe({ channels: allMessageChannels });

      dispatch(setMessagesPubnubListener(listener.current));
    }
  }, [pubnub, userId, project, updatedProjectInfo, dispatch]);

  return {
    pubnub,
    listener: listener.current,
  };
}
export default useInitializeMessages;

import React from 'react';
import { Modal } from '../ui/modal';
import NoiseBgHelper from '../../images/noise_bg_helper.png';

function AttachmentViewModal({ children, close, ...props }) {
  return (
    <Modal
      onRequestClose={close}
      closeIcon={false}
      {...props}
      content={{
        position: 'relative',
        color: '#ffffff',
        height: '70%',
        maxWidth: '100%',
        maxHeight: '545px',
        width: '576px',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        backgroundImage: `url(${NoiseBgHelper})`,
        backgroundColor: `#333333`,
        backgroundBlendMode: 'overlay',
        boxShadow: `0px -2px 1px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.35),
        inset 0px 1.5px 0.5px rgba(0, 0, 0, 0.5)`,
      }}
    >
      {children}
    </Modal>
  );
}

export default AttachmentViewModal;

import { StyledProjectTile } from './styles';
import { useSelector } from 'react-redux';
function ProjectTile({ project, children, ...props }) {
  const { isGlobalAdmin } = useSelector((state) => state.auth);
  return (
    <StyledProjectTile
      role="button"
      tabIndex={0}
      {...props}
      enabled={project?.enabled}
      isGlobalAdmin={isGlobalAdmin}
    >
      {children}
    </StyledProjectTile>
  );
}

export default ProjectTile;

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import authReducer from '../features/auth/auth-slice';
import channelsReducer from '../features/channels/channels-duck';
import usersReducer from '../features/users/users-duck';
import groupsReducer from '../features/groups/groups-duck';
import audioRoomReducer from '../features/audio/audio-slice';
import videoCallReducer from '../features/video/video-slice';
import groupVideoCallReducer from '../features/video/group-video-slice';
import broadcastReducer from '../features/broadcast/broadcast-slice';
import callsReducer from '../features/calls/calls-slice';
import messagesReducer from '../features/messages/messages-slice';
import projectsReducer from '../features/projects/projects-duck';
import profileReducer from '../features/users/profile-slice';
import mainReducer from '../features/main/main-slice';

const authPersistConfig = {
  key: 'auth',
  storage: storageSession,
};

const groupVideoCallPersistConfig = {
  key: 'groupVideoCall',
  storage: storageSession,
  whitelist: ['camera', 'microphone'],
  blacklist: ['listener'],
};

const audioCallPersistConfig = {
  key: 'audioCall',
  storage: storageSession,
  whitelist: ['isInAudioRoom'],
  blacklist: ['listener'],
};

export const store = configureStore({
  reducer: {
    main: mainReducer,
    calls: callsReducer,
    users: usersReducer,
    groups: groupsReducer,
    profile: profileReducer,
    channels: channelsReducer,
    projects: projectsReducer,
    messages: messagesReducer,
    broadcast: broadcastReducer,
    videoCall: videoCallReducer,
    auth: persistReducer(authPersistConfig, authReducer),
    audio: persistReducer(audioCallPersistConfig, audioRoomReducer),
    groupVideoCall: persistReducer(groupVideoCallPersistConfig, groupVideoCallReducer),
  },
  middleware: getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store);

if (process.env.NODE_ENV === `development`) {
  window.store = store;
}

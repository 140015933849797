import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AVControlsModal from '../broadcast/AVControlsModal';
import { startBroadcastPublisher, stopBroadcastPublisher } from '../broadcast/broadcast-slice';
import BroadcastVolumeControl from '../broadcast/BroadcastVolumeControl';
import { BroadcastButtonWrap, BroadcastButton } from '../ui/inputs';

export function BroadcastControls({ project, canBroadcast }) {
  // broadcast
  const dispatch = useDispatch();
  const isPublisherOfBroadcast = useSelector((state) => !!state.broadcast.publisher);
  const { isBroadcasting, isLoading } = useSelector((state) => state.broadcast);
  const [isBroadcastingModalOpen, setBroadcastingModalOpen] = useState(false);
  const openBroadcastModal = () => setBroadcastingModalOpen(true);
  const closeBroadcastModal = () => {
    setBroadcastingModalOpen(false);
  };
  const handleStartBroadcastingClick = () => {
    if (isLoading) return;
    openBroadcastModal();
  };

  const handleBroadcast = (camera, microphone) => {
    dispatch(startBroadcastPublisher(project.id, camera, microphone));
  };

  const handleStopBroadcastingClick = () => {
    dispatch(stopBroadcastPublisher(project.id));
  };

  const ButtonOrVolumeControl = () =>
    isPublisherOfBroadcast ? (
      <BroadcastButtonWrap>
        <BroadcastButton className="active" onClick={handleStopBroadcastingClick} isDanger>
          <span>Stop Broadcast</span>
        </BroadcastButton>
      </BroadcastButtonWrap>
    ) : (
      <BroadcastVolumeControl style={{ paddingLeft: '20px' }}></BroadcastVolumeControl>
    );

  return (
    <>
      {isBroadcasting ? (
        <ButtonOrVolumeControl />
      ) : (
        canBroadcast && (
          <BroadcastButtonWrap>
            <BroadcastButton onClick={handleStartBroadcastingClick} disabled={isLoading}>
              <span>{isLoading ? 'Loading.....' : 'Start Broadcast'}</span>
            </BroadcastButton>
          </BroadcastButtonWrap>
        )
      )}

      {isBroadcastingModalOpen && (
        <AVControlsModal close={closeBroadcastModal} onDoneBroadcast={handleBroadcast} />
      )}
    </>
  );
}

import { useEffect, useRef } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import BroadcastPanel from './BroadcastPanel';
import ProjectMainSidebar from './ProjectMainSidebar';
import { projectsActions } from '../projects/projects-duck';
import { fetchProfile } from '../users/profile-slice';
import { stopChat } from '../messages/messages-slice';
import OffsetBlackLogo from '../../images/offset_black_logo.svg';

import {
  initializeAllPubnubListeners,
  resetUpdatedProjectInfo,
  stopAllPublishers,
  stopAllPubnubListeners,
} from './main-slice';

import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import backHttpClient from '../../app/backend-http-client';

function ProjectNotFound({ isEnabled }) {
  return (
    <StyledProjectNotFound>
      <img src={OffsetBlackLogo} height="52" alt="Offset" />
      <h1> {isEnabled ? 'Unable to access project' : 'Project has been Disabled'}</h1>
      <div>
        <p>
          The project you are looking does not exist or you are may not be authorized to access it.
        </p>
        <p>If you feel you should have access to this project, please contact the administrator</p>
        <p>
          Otherwise click here to <Link to="/projects">go to your projects</Link>
        </p>
      </div>
    </StyledProjectNotFound>
  );
}
export default function ProjectMainPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isGlobalAdmin, userId, uuid } = useSelector((state) => state.auth);
  const { items: projects, isLoading, isReadingAll } = useSelector((state) => state.projects);
  const pubnub = useRef(null);

  useEffect(() => {
    const createPubNubInstance = async () => {
      const response = await backHttpClient.get('pubnub');
      const { publishKey, subscribeKey } = response.data;
      pubnub.current = new PubNub({
        publishKey,
        subscribeKey,
        uuid,
        autoNetworkDetection: true,
        restore: true,
        ssl: true,
      });
    };

    createPubNubInstance();
    return () => {};
  }, [uuid]);

  // Ths is commented out and might be deprecated because the user-update-project-status
  // response returns the same/similar results. Leads to some kind of race condition
  useEffect(() => {
    if (isGlobalAdmin) {
      dispatch(projectsActions.readAll(true, null));
    } else {
      dispatch(projectsActions.readAll(false, userId));
    }
  }, [dispatch, userId, isGlobalAdmin]);

  useEffect(() => {
    dispatch(fetchProfile(id));
  }, [dispatch, id, userId]);

  const project = projects.find((_project) => _project.id === Number(id));

  useEffect(() => {
    if (project) {
      // This is used to get the lastLoggedIn time and also invitation status
      const payload = {
        userId,
        type: 'user-update-project-status',
      };
      dispatch(projectsActions.update(id, payload, null, null));
    }
  }, [dispatch, project, id, userId]);

  const isProjectDataReady = !isLoading && !isReadingAll && pubnub.current;
  const hasProjects = project && Boolean(Object.keys(project).length);

  // initialize the various pubnub listeners
  useEffect(() => {
    if (project && pubnub.current) {
      initializeAllPubnubListeners(pubnub.current, project)(dispatch, userId);
    }
    return () => {
      // Stop pubnub event listeners in this clean up function instead.
      stopAllPubnubListeners()(dispatch);
      stopAllPublishers()(dispatch);
      dispatch(stopChat()); // commented out for now troubleshooting the chat errors

      // reset the updatedProjectInfo which is used to sync the project details whenever there is an update
      dispatch(resetUpdatedProjectInfo());
    };
  }, [dispatch, project, userId]);

  // Redirect a Global Admin to the projects page
  if (
    hasProjects &&
    isGlobalAdmin &&
    !(project.isProjectAdmin || project.isProjectOwner || project.isProjectMember)
  ) {
    return <Redirect to="/projects" />;
  }

  const ProjectViewComponent = () => (
    <StylingMainPage>
      <ProjectMainSidebar project={project} />
      <ChannelsMain>
        <BroadcastPanel project={project} />
      </ChannelsMain>
    </StylingMainPage>
  );
  const ProjectOrNotFound = () =>
    hasProjects && project?.enabled ? (
      <PubNubProvider client={pubnub.current}>
        <ProjectViewComponent />
      </PubNubProvider>
    ) : (
      <ProjectNotFound isEnabled={project?.enabled} />
    );
  return isProjectDataReady ? <ProjectOrNotFound /> : <p>Loading...</p>;
}

const StyledProjectNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  padding: 100px;
`;

const ChannelsMain = styled.main`
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 15px 15px 15px 0;
  background: #f5ebc0;
  box-shadow: inset 0px 2px 1px rgba(255, 255, 255, 0.5), inset 0px -2px 1px rgba(0, 0, 0, 0.1);
`;

const StylingMainPage = styled.div`
  display: flex;
  flex-direction: row;
`;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stopAudioPublisher, toggleRoomAudio, toggleRoomMic } from '../features/audio/audio-slice';
import {
  endVideoCall,
  toggleVideoCallMic,
  toggleVideoCallVideo,
  toggleVideoCallAudio,
} from '../features/video/group-video-slice';
import { toggleMicrophone } from '../features/calls/calls-slice';
import {
  MinimizedCallActions,
  VideoCallActionSelector,
  VideoCallActionSquareButton,
} from '../features/main/project-main-sidebar/styles';
import { selectIsSpeakerActive } from '../features/main/main-slice';

function SidebarBottomCallActions({
  children,
  groupPubnubChannel,
  inCallSpeakerToggle,
  handleAddVideoToAudioCall,
}) {
  const dispatch = useDispatch();
  const isInCall = useSelector((state) => !!state.calls.session);
  const { isInAudioRoom, isMicMuted: isAudioCallMicMuted } = useSelector((state) => state.audio);
  const isMicrophoneActive = useSelector((state) => state.calls.isMicrophoneActive);
  const {
    isReady,
    publisher,
    isVideoMuted,
    isMicMuted: isVideoCallMicMuted,
  } = useSelector((state) => state.groupVideoCall);

  const isSpeakerActive = useSelector(selectIsSpeakerActive);

  let isMicMuted;
  if (isInAudioRoom) {
    isMicMuted = !(isMicrophoneActive || !isAudioCallMicMuted);
  } else {
    isMicMuted = isVideoCallMicMuted;
  }

  const handleAudioCallMicToggle = () => {
    if (isInAudioRoom) {
      dispatch(toggleRoomMic());
    }
    if (isInCall) {
      dispatch(toggleMicrophone());
    }
  };

  const toggleUserMicrophone = () => {
    if (isInAudioRoom) {
      handleAudioCallMicToggle();
    } else {
      dispatch(toggleVideoCallMic());
    }
  };

  const toggleUserSpeaker = () => {
    if (isInAudioRoom) {
      dispatch(toggleRoomAudio());
    } else {
      if (inCallSpeakerToggle) inCallSpeakerToggle();
      dispatch(toggleVideoCallAudio());
    }
  };

  const handleVideoIconClick = () => {
    if (!publisher) {
      handleAddVideoToAudioCall();
    } else {
      dispatch(toggleVideoCallVideo());
    }
  };

  const handleEndCall = () => {
    dispatch(endVideoCall(groupPubnubChannel));
    dispatch(stopAudioPublisher());
  };

  const cameraIcon = isVideoMuted ? (
    <i className="fa fa-video-slash active" />
  ) : (
    <i className="fa fa-video" />
  );

  const microphoneIcon = isMicMuted ? (
    <i className="fa fa-microphone-slash active" />
  ) : (
    <i className="fa fa-microphone" />
  );

  const speakerIcon = isSpeakerActive ? (
    <i className="fa fa-volume-up active" />
  ) : (
    <i className="fa fa-volume-mute" />
  );

  return (
    <MinimizedCallActions className="actions-wrap">
      {/* The channel selector pop up is passed as children */}
      <VideoCallActionSelector>{children}</VideoCallActionSelector>
      <VideoCallActionSquareButton
        className={`orange ${isVideoMuted ? 'off' : 'on'}`}
        type="button"
        onClick={handleVideoIconClick}
        disabled={!isReady}
      >
        {cameraIcon}
      </VideoCallActionSquareButton>
      {/* TODO: Add a condition to disable toggling of the speaker button if there are no subscribers */}
      <VideoCallActionSquareButton
        className={`yellow ${isSpeakerActive ? 'on' : 'off'}`}
        type="button"
        onClick={toggleUserSpeaker}
      >
        {speakerIcon}
      </VideoCallActionSquareButton>
      <VideoCallActionSquareButton
        className={`green ${isMicMuted ? 'off' : 'on'}`}
        type="button"
        onClick={toggleUserMicrophone}
      >
        {microphoneIcon}
      </VideoCallActionSquareButton>

      <VideoCallActionSquareButton type="button" onClick={handleEndCall} className={'red'}>
        <i className="fa fa-phone-slash" />
      </VideoCallActionSquareButton>
    </MinimizedCallActions>
  );
}
export default SidebarBottomCallActions;

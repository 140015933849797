import get from 'lodash.get';
import { makeReducerAssets } from 'resource-toolkit';
import backHttpClient from '../../app/backend-http-client';

const resource = makeReducerAssets({
  name: 'users',
  idKey: 'id',
  gateway: {
    fetchMany: async () => {
      return (await backHttpClient.get('/users/')).data.items;
    },
    create: async (payload, successCallback, errorCallback) => {
      try {
        if (payload && payload.type === 'profile-update') {
          const { type, ...data } = payload;
          const res = (await backHttpClient.post('/users/profile', data)).data.item;
          if (successCallback) successCallback();
          return res;
        }
      } catch (error) {
        errorCallback(get(error, 'response.data.message', ''), error);
        throw error;
      }
    },
  },
});

export const usersActions = resource.actionThunks;

export default resource.reducer;

import React from 'react';
import styled from 'styled-components';

function NoConnection({ mode = 'light', isNotification = false }) {
  return (
    <StyledContainer $mode={mode} $isNotification={isNotification}>
      <div className="icon">
        <i className="fas fa-lg fa-signal-alt-slash"></i>
      </div>
      <div className="details">
        <h4>No connection</h4>
        <p>Go online to view broadcast.</p>
      </div>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  color: ${(props) => (props.$mode === 'light' ? '#FFFFFF' : '#000000')};
  .icon {
    margin-right: 10px;
  }
  .details {
    h4 {
      margin: 0;
      font-size: ${(props) => (props.$isNotification ? '18px' : '32px')};
      line-height: 15px;
      font-weight: 700;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 15px;
      margin-bottom: ${(props) => (props.$isNotification ? '0' : 'inherit')};
    }
  }
`;

export default NoConnection;

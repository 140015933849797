import { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { projectsActions } from '../projects-duck';
import styled from 'styled-components';
import Select from 'react-select';
import { Modal } from '../../ui/modal';
import { CheckLabel, PrimaryButton, TextInput, InputLabel, CheckboxGroup } from '../../ui/inputs';
import { ErrorText, AnchorLink, ModalHeading } from '../../ui/texts';
import { ErrorIcon } from '../../ui/iconography';
import { toast } from 'react-toastify';
import {
  TableRow,
  ModalButtonsGroup,
  DeleteCell,
  ActionCell,
  DeleteConfirmText,
  ChannelCell,
  EditCell,
  ListChannelLabel,
  ReInviteCell,
  ModalMessage,
} from './styles';
import { getUserAccessLevel } from '../../../utils/helpers';
import { sendProjectUpdateNotice } from '../../broadcast/broadcast-slice';

function UserTableRow({ user, channels = [], projectId }) {
  const dispatch = useDispatch();
  const isProjectOwnerRef = useRef(null);
  const { Channels: userChannels } = user;
  const { userId } = useSelector((state) => state.auth);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [isConfirmingReInvite, setIsConfirmingReInvite] = useState(false);
  const [isConfirmingProjectOwnerChange, setIisConfirmingProjectOwnerChange] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [updateEvent, setUpdateEvent] = useState({});
  const userProjectChannels = userChannels.filter((channel) => channel.projectId === projectId);
  const [selectedChannelIds, setSelectedChannelIds] = useState(
    userProjectChannels.map((channel) => channel.id)
  );
  const status = user.ProjectUsers.status[0].toUpperCase() + user.ProjectUsers.status.substring(1);
  const [errorMessage, setErrorMessage] = useState('');
  const channelsInList = [
    ...selectedChannelIds.map((id) => channels.find((channel) => channel.id === id)),
  ];
  const channelsNotList = channels.filter((channel) => {
    const index = channelsInList.find(({ id }) => channel.id === id);
    if (!index) return channel;
    return null;
  });

  const userChannelOptions = userProjectChannels.map(({ id, label, color }) => ({
    value: id,
    label,
    color,
  }));

  const nonUserChannelOptions = channelsNotList.map(({ id, label, color }) => ({
    value: id,
    label,
    color,
  }));

  //  Set default selected channels for this user
  const [selectedChannels, setSelectedChannels] = useState(
    userChannelOptions.map((option) => option.value)
  );

  const handleChannelSelect = (valueType, _ActionType) => {
    const choice = valueType.map((option) => option.value);
    setSelectedChannels(choice);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const hasBecomeProjectManager =
      !user.ProjectUsers.isProjectOwner &&
      isProjectOwnerRef.current &&
      isProjectOwnerRef.current.checked;

    setUpdateEvent(e);
    (hasBecomeProjectManager ? () => setIisConfirmingProjectOwnerChange(true) : handleEdit)(e);
  };

  const handleEdit = (e, deferred = false) => {
    setIisConfirmingProjectOwnerChange(false);
    e.preventDefault();
    const event = deferred ? updateEvent : e;

    const payload = {
      userId: user.id,
      name: event.target.name.value,
      email: event.target.email.value,
      projectRole: event.target.projectRole.value,
      canBroadcast: event.target.canBroadcast.checked,
      isProjectAdmin: event.target.isProjectAdmin.checked,
      isProjectOwner: event.target.isProjectOwner.checked,
      channels: selectedChannels,
      type: 'user-update-project-settings',
    };

    const onSuccess = () => {
      toast.success(`Project Owner Updated`, {
        theme: 'light',
      });
      setIsEditing(false);

      dispatch(sendProjectUpdateNotice(projectId));
    };

    const onError = (error) => {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Unknown error carrying out operation, please contact support team.');
        console.error('Error saving changes.', error);
      }
    };

    dispatch(projectsActions.update(user.ProjectUsers.projectId, payload, onSuccess, onError));
  };

  const handleEditCancel = () => {
    setSelectedChannelIds([]);
    setIsEditing(false);
  };
  const handleDelete = (e) => {
    e.preventDefault();
    const payload = {
      userId: user.id,
      type: 'user-remove',
    };
    const onSuccess = () => {
      setIsConfirmingDelete(false);
      dispatch(sendProjectUpdateNotice(user.ProjectUsers.projectId));
    };
    const onError = (error) => {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Unknown error carrying out operation, please contact support team.');
        console.error('Error deleting user.', error);
      }
    };
    dispatch(projectsActions.update(user.ProjectUsers.projectId, payload, onSuccess, onError));
  };
  const handleReInvite = (e) => {
    e.preventDefault();
    const payload = {
      userId: user.id,
      type: 'user-reinvite',
    };
    const onSuccess = () => {
      setIsConfirmingReInvite(false);
      dispatch(sendProjectUpdateNotice(user.ProjectUsers.projectId));
    };
    const onError = (error) => {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Unknown error carrying out operation, please contact support team.');
        console.error('Error re-inviting user.', error);
      }
    };
    dispatch(projectsActions.update(user.ProjectUsers.projectId, payload, onSuccess, onError));
  };

  const handleChannelSelected = (id) => {
    if (selectedChannelIds.includes(id)) {
      return;
    }
    setSelectedChannelIds([...selectedChannelIds, id]);
  };

  const userAccessLevel = getUserAccessLevel(user.ProjectUsers);

  return (
    <TableRow>
      <td>{user.profile[0]?.name ?? user.name}</td>
      <td>{user.email}</td>
      <td>{user.profile[0]?.phone ?? user?.profile.phone}</td>
      <ChannelCell>
        {userProjectChannels.map((channel) => (
          <ListChannelLabel
            key={channel.id}
            color={channel.color}
            onClick={() => handleChannelSelected(channel.id)}
          >
            {channel.label}
          </ListChannelLabel>
        ))}
      </ChannelCell>
      <td>{userAccessLevel}</td>
      <td>{user.ProjectUsers.projectRole}</td>
      <td>{status}</td>
      <ActionCell>
        {user.ProjectUsers.status === 'removed' ? (
          <ReInviteCell onClick={() => setIsConfirmingReInvite(true)}>Re-invite</ReInviteCell>
        ) : (
          <>
            <EditCell editable onClick={() => setIsEditing(true)}>
              Edit
            </EditCell>
            <DeleteCell
              editable={user.id !== userId}
              onClick={user.id !== userId ? () => setIsConfirmingDelete(true) : null}
            >
              Remove
            </DeleteCell>
          </>
        )}
      </ActionCell>
      <Modal isOpen={isConfirmingDelete} onRequestClose={() => setIsConfirmingDelete(false)}>
        <form onSubmit={handleDelete}>
          {errorMessage && (
            <p>
              <ErrorIcon /> <ErrorText>{errorMessage}</ErrorText>
            </p>
          )}
          <DeleteConfirmText>
            <ModalMessage>
              {`You have chosen to remove ${user.name} from this project`}.
            </ModalMessage>
            <ModalMessage>Do you want to continue?</ModalMessage>
          </DeleteConfirmText>
          <ModalButtonsGroup>
            <PrimaryButton type="submit">Remove</PrimaryButton>
            <AnchorLink to="#" onClick={() => setIsConfirmingDelete(false)}>
              Cancel
            </AnchorLink>
          </ModalButtonsGroup>
        </form>
      </Modal>
      <Modal isOpen={isEditing} onRequestClose={handleEditCancel}>
        <StyledForm onSubmit={handleSubmit}>
          <header>
            <ModalHeading>Edit User</ModalHeading>
          </header>
          {errorMessage && (
            <p>
              <ErrorIcon /> <ErrorText>{errorMessage}</ErrorText>
            </p>
          )}
          <InputLabel>
            <span>Email*</span>
            <TextInput
              name="email"
              type="email"
              placeholder="User Email"
              autoComplete="off"
              required
              disabled
              defaultValue={user.email}
            />
          </InputLabel>

          <InputLabel>
            <span>Name*</span>
            <TextInput
              name="name"
              placeholder="User Name"
              autoComplete="off"
              required
              defaultValue={user?.profile?.[0]?.name || user.name}
            />
          </InputLabel>

          <InputLabel>
            <span>Project Role</span>
            <TextInput
              name="projectRole"
              placeholder="User Project Role"
              autoComplete="off"
              required
              defaultValue={user.ProjectUsers.projectRole}
            />
          </InputLabel>

          <InputLabel>
            <span>Permissions</span>
            <CheckboxGroup>
              <CheckLabel>
                <input
                  name="canBroadcast"
                  type="checkbox"
                  defaultChecked={user.ProjectUsers.canBroadcast}
                />
                <span>Can Broadcast</span>
              </CheckLabel>

              <CheckLabel>
                <input
                  name="isProjectAdmin"
                  type="checkbox"
                  defaultChecked={user.ProjectUsers.isProjectAdmin}
                />
                <span>Admin</span>
              </CheckLabel>

              <CheckLabel>
                <input
                  ref={isProjectOwnerRef}
                  name="isProjectOwner"
                  type="checkbox"
                  defaultChecked={user.ProjectUsers.isProjectOwner}
                />
                <span>Project Owner</span>
              </CheckLabel>
            </CheckboxGroup>
          </InputLabel>

          {[...userChannelOptions, ...nonUserChannelOptions].length ? (
            <InputLabel>
              <span>Channels*</span>
              <Select
                defaultValue={userChannelOptions}
                options={[...userChannelOptions, ...nonUserChannelOptions]}
                isMulti
                aria-label="Channel Channels"
                placeholder="Choose Channels"
                name="channel"
                onChange={handleChannelSelect}
              />
            </InputLabel>
          ) : null}

          <ModalButtonsGroup>
            <PrimaryButton type="submit">Save Changes</PrimaryButton>
            <AnchorLink to="#" onClick={handleEditCancel}>
              Cancel
            </AnchorLink>
          </ModalButtonsGroup>
        </StyledForm>
      </Modal>

      <Modal isOpen={isConfirmingReInvite} onRequestClose={() => setIsConfirmingReInvite(false)}>
        <form onSubmit={handleReInvite}>
          {errorMessage && (
            <p>
              <ErrorIcon /> <ErrorText>{errorMessage}</ErrorText>
            </p>
          )}
          <DeleteConfirmText>
            {`You have chosen to re-invite ${user.name} to this project`}.<br />
            Do you want to continue?
          </DeleteConfirmText>
          <ModalButtonsGroup>
            <PrimaryButton type="submit">Continue</PrimaryButton>
            <AnchorLink to="#" onClick={() => setIsConfirmingReInvite(false)}>
              Cancel
            </AnchorLink>
          </ModalButtonsGroup>
        </form>
      </Modal>

      <Modal
        isOpen={isConfirmingProjectOwnerChange}
        onRequestClose={() => setIisConfirmingProjectOwnerChange(false)}
      >
        <form onSubmit={(e) => handleEdit(e, true)}>
          {errorMessage && (
            <p>
              <ErrorIcon /> <ErrorText>{errorMessage}</ErrorText>
            </p>
          )}
          <DeleteConfirmText>
            <ModalMessage>
              {`You have chosen to assign ${user.name} as the project owner to this project. Performing this action will unassign this role if it is currently assigned to another user`}
              .
            </ModalMessage>
            <ModalMessage>Do you want to continue?</ModalMessage>
          </DeleteConfirmText>
          <ModalButtonsGroup>
            <PrimaryButton type="submit">Continue</PrimaryButton>
            <AnchorLink to="#" onClick={() => setIisConfirmingProjectOwnerChange(false)}>
              Cancel
            </AnchorLink>
          </ModalButtonsGroup>
        </form>
      </Modal>
    </TableRow>
  );
}

const StyledForm = styled.form`
  ${InputLabel} {
    width: 100%;
    margin: 0.5rem 0;
  }
`;

export default UserTableRow;

import { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import backHttpClient from '../../app/backend-http-client';
import { PrimaryButton, InputLabel, TextInput, CheckLabel } from '../ui/inputs';
import { AnchorLink, Heading, ErrorText } from '../ui/texts';
import { ErrorIcon } from '../../features/ui/iconography.js';
import { toast } from 'react-toastify';

export default function InvitePage() {
  console.error('Deprecated page. To be removed when designs are ready.');

  const history = useHistory();
  const handleFinish = () => {
    history.push('/projects');
  };

  return (
    <main>
      <InviteForm onFinish={handleFinish} />
    </main>
  );
}

export function InviteForm(props) {
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    setErrorMessage('');

    try {
      const invitation = {
        name: event.target.name.value.trim(),
        email: event.target.email.value.trim(),
        department: event.target.department.value.trim(),
        canBroadcast: event.target.canBroadcast.checked,
        isGlobalAdmin: event.target.isGlobalAdmin.checked,
      };
      const { data: invited } = await backHttpClient.post('/auth/invite', invitation);
      toast.success(`Invited ${invited.name} (${invited.email}).`, {
        theme: 'light',
      });
      props.onFinish();
    } catch (error) {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Unknown error while sending invite, please contact support team.');
        console.error('Error on invite.', error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <StylingInviteForm onSubmit={handleSubmit}>
      <header>
        <Heading>Offset</Heading>
        <h2>Invite user</h2>
      </header>
      {errorMessage && (
        <p>
          <ErrorIcon /> <ErrorText>{errorMessage}</ErrorText>
        </p>
      )}
      <InputLabel>
        <span>Name:</span>
        <TextInput
          name="name"
          disabled={isLoading}
          placeholder="E.g.: John Doe"
          autoComplete="off"
          required
        />
      </InputLabel>
      <br />
      <InputLabel>
        <span>E-mail:</span>
        <TextInput
          name="email"
          type="email"
          disabled={isLoading}
          placeholder="E.g.: john@e.g.com"
          autoComplete="off"
          required
        />
      </InputLabel>
      <br />
      <InputLabel>
        <span>Department:</span>
        <br />
        <TextInput
          name="department"
          disabled={isLoading}
          placeholder="E.g.: Photography"
          autoComplete="off"
          required
        />
      </InputLabel>
      <br />
      <CheckLabel>
        <input name="canBroadcast" type="checkbox" disabled={isLoading} />
        <span>Can broadcast</span>
      </CheckLabel>
      <br />
      <CheckLabel>
        <input name="isGlobalAdmin" type="checkbox" disabled={isLoading} />
        <span>Administrator</span>
      </CheckLabel>
      <br />
      <br />
      {isLoading && <p>Inviting...</p>}
      <ButtonsGroup>
        <AnchorLink to="#" onClick={props.onFinish}>
          Cancel
        </AnchorLink>
        <PrimaryButton type="submit" disabled={isLoading}>
          Invite
        </PrimaryButton>
      </ButtonsGroup>
    </StylingInviteForm>
  );
}

const StylingInviteForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: auto;
  width: 100%;
  max-width: 350px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 30px;
  background: white;

  ${InputLabel} {
    width: 85%;
  }
`;

const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-around;

  ${PrimaryButton} {
    width: 50%;
  }
`;

import { useDispatch, useSelector } from 'react-redux';
import {
  stopGroupVideoPublisher,
  joinGroupCallAsSubscriber,
  setScreenToDisplay,
} from '../features/video/group-video-slice';
import { startAudioPublisher, stopAudioPublisher } from '../features/audio/audio-slice';
import { TOPIC_CHANNEL } from '../features/calls/calls-constants';
import backHttpClient from '../app/backend-http-client';
import { toast } from 'react-toastify';
import { channelsActions } from '../features/channels/channels-duck';

function useChannelCallHelpers({
  projectId,
  activateTopicFn,
  isChannelAddOpen,
  closeChannelAdd,
  openFileSendingPrompt,
}) {
  const dispatch = useDispatch();
  const { isInAudioRoom, sessionId, session } = useSelector((state) => state.audio);
  const { groupCallState, isInGroupVideoCall } = useSelector((state) => state.groupVideoCall);
  const { userId } = useSelector((state) => state.auth);
  const { isSendingMessage } = useSelector((state) => state.messages);

  const handleJoinAudioRoom = async (event, channel) => {
    event.stopPropagation();
    event.preventDefault();

    // get audio device
    let stream;
    let devices;
    try {
      stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
    } catch (error) {
      console.warn('Unable to set user devices', error);
      return;
    }
    stream.getTracks().forEach((t) => t.stop());
    try {
      devices = await navigator.mediaDevices.enumerateDevices();
    } catch (error) {
      console.warn('Error on enumerate handling', error);
      return;
    }
    let audioRoomDevice = null;
    const allowedDevices = devices.filter((it) => !!it.deviceId && !!it.label);
    if (allowedDevices.length === 0) {
      console.warn('No allowed devices, join audio channels will not be possible.');
      return;
    }
    const foundAudioDevices = allowedDevices.filter((it) => it.kind === 'audioinput');
    const defaultAudio =
      foundAudioDevices.find((it) => it.deviceId === 'default') || foundAudioDevices[0];
    if (defaultAudio) {
      audioRoomDevice = defaultAudio;
    }

    // connect to audio room
    const currentSessionId = sessionId;
    if (isInAudioRoom || session) {
      dispatch(stopAudioPublisher());

      // Stop video call if you are joining a new channel
      // Allow a video call in the same audio call channel. Don't allow one audio call in 1 channel
      // and 1 video call in another
      // the Channel is the pubNub Channel
      if (isInGroupVideoCall) {
        dispatch(stopGroupVideoPublisher());
      }
      if (currentSessionId === channel.sessionId) {
        return;
      }
    }
    if (!audioRoomDevice) {
      console.log('No audio device available');
      return;
    }
    const onError = () => {
      console.log('some error when join room');
    };
    const onSuccess = () => {
      console.log('Room Joined');
    };

    // activate the chat channel. The channel name is used when making a group video call
    // IMPROVEMENT: when starting and audio call. don't activate the chat channel
    activateTopicFn(TOPIC_CHANNEL, channel.id, false)();
    dispatch(startAudioPublisher(projectId, channel.id, audioRoomDevice, onSuccess, onError));

    // This was used to automatically turn on the video when there is an ongoing call in the
    // audio channel you wanted to join
    const activeGroupCallChannel = `video-call-channel-${projectId}-${channel.id}`;

    if (
      groupCallState[activeGroupCallChannel]?.isInGroupCall &&
      groupCallState[activeGroupCallChannel]?.callParticipants > 0
    ) {
      dispatch(setScreenToDisplay('minimized'));
      dispatch(joinGroupCallAsSubscriber(projectId, channel.id));
    }
  };

  const handleChannelClick = (event, channelId) => {
    event.preventDefault();
    event.stopPropagation();
    if (isSendingMessage) {
      openFileSendingPrompt();
      return;
    }
    activateTopicFn(TOPIC_CHANNEL, channelId)();
  };

  const handleChannelSelectionFn = (channel) => async () => {
    try {
      const response = await backHttpClient.post(`/channels/${channel.id}/user/${userId}`);
      toast.success(response.data.message, {
        theme: 'light',
      });

      if (isChannelAddOpen) {
        closeChannelAdd();
      }
    } catch (error) {
      toast.error('Could not add user as participant. Please, contact support team.', {
        theme: 'light',
      });
    } finally {
      dispatch(channelsActions.readAll());
    }
  };

  return { handleJoinAudioRoom, handleChannelClick, handleChannelSelectionFn };
}

export default useChannelCallHelpers;

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CallModalHeader, CallModalHeaderControls, VideoCallActionButtonGroup } from '../styles';
import {
  RemoteVideo,
  VideoCallActionSquareButton,
  LocalVideo,
  NoiseBgWrapHelper,
  ConfigButton,
} from './styles';
import styled from 'styled-components';
import AVControlsModal from '../../broadcast/AVControlsModal';

function MiniVideoCallModal({
  isInVideoCall,
  isVideoMinimized,
  handleMinimizeToggle,
  handleEndCall,
  handleMicToggle,
  isVideCallMicMuted,
  handleVideoToggle,
  isVideoMuted,
  peer,
}) {
  const { publisher } = useSelector((state) => state.videoCall);
  const [isOpenCameraSwitchModal, setIsOpenCameraSwitchModal] = useState(false);
  const handleCameraChange = async (camearDevice) => {
    await publisher.setVideoSource(camearDevice.deviceId);
  };
  return (
    <>
      <StyledMinimizedGroupCall $isHidden={!isInVideoCall || !isVideoMinimized} id="mini-player">
        <CallModalHeader className="call-modal-header">
          {peer && <p>{`In Call with ${peer?.profile[0]?.name ?? peer.name}`}</p>}
          <CallModalHeaderControls>
            <button onClick={handleMinimizeToggle}>
              <i style={{ color: '#ffffff' }} className="far fa-plus-circle" />
            </button>
            <button onClick={handleEndCall}>
              <i style={{ color: '#ffffff' }} className="far fa-times-circle" />
            </button>
          </CallModalHeaderControls>
        </CallModalHeader>
        <RemoteVideoMin id="remote-video-minimized" />
        <VideoCallActionButtonGroup className="call-controls">
          <VideoCallActionSquareButton
            className={`green ${isVideCallMicMuted ? 'off' : 'on'}`}
            type="button"
            onClick={handleMicToggle}
          >
            {isVideCallMicMuted ? (
              <i className="fa fa-microphone-slash active" />
            ) : (
              <i className="fa fa-microphone active" />
            )}
          </VideoCallActionSquareButton>
          <VideoCallActionSquareButton
            className={`orange ${isVideoMuted ? 'off' : 'on'}`}
            type="button"
            onClick={handleVideoToggle}
          >
            {isVideoMuted ? (
              <i className="fa fa-video-slash active" />
            ) : (
              <i className="fa fa-video active" />
            )}
          </VideoCallActionSquareButton>
          <VideoCallActionSquareButton type="button" className="red" onClick={handleEndCall}>
            <i className="fa fa-phone-slash active" />
          </VideoCallActionSquareButton>
        </VideoCallActionButtonGroup>
        <LocalVideoMin id="local-video-minimized" />
        {publisher && (
          <StyledConfigButton className="yellow" onClick={() => setIsOpenCameraSwitchModal(true)}>
            <i className="fa fa-wrench"></i>
          </StyledConfigButton>
        )}
      </StyledMinimizedGroupCall>
      {/* Camera Change Modal */}
      {isOpenCameraSwitchModal && (
        <AVControlsModal
          close={() => setIsOpenCameraSwitchModal(false)}
          onDoneBroadcast={handleCameraChange}
          actionText="Change Camera"
        />
      )}
    </>
  );
}

const StyledMinimizedGroupCall = styled(NoiseBgWrapHelper)`
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  display: ${(props) => (props.$isHidden ? 'none' : 'flex')};
  height: ${(props) => (props.$isHidden ? '0px' : '100%')};
  width: ${(props) => (props.$isHidden ? '0px' : '100%')};
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  padding: 8px;
  z-index: 10;

  ${VideoCallActionButtonGroup} {
    display: ${(props) => (props.$isHidden ? 'none' : 'inherit')};
  }

  ${CallModalHeader} {
    color: #ffffff;
  }
`;

const RemoteVideoMin = styled(RemoteVideo)`
  z-index: 0;
  margin: 0 auto;
  border-radius: 4px;
  background: #000;
`;

const LocalVideoMin = styled(LocalVideo)`
  bottom: 115px;
  aspect-ratio: 9 / 16;
  width: 80px;
  border-radius: 4px;
  background: #222;
`;

const StyledConfigButton = styled(ConfigButton)`
  position: absolute;
  bottom: 120px;
  left: 20px;
  z-index: 10;
`;

export default MiniVideoCallModal;

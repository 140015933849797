import { useState } from 'react';
import { useSelector } from 'react-redux';

import AdminProjects from './project-main/AdminProjects';
import UserProjects from './project-main/UserProjects';
import NewProjectModal from './project-main/NewProject';
import {
  StyledProjectsMain,
  ProjectLoading,
  StyledProjectsList,
  StyledProjectsRow,
} from './project-main/styles';

function ProjectsMain() {
  const { items: projects, isLoading } = useSelector((state) => state.projects);
  const { isGlobalAdmin } = useSelector((state) => state.auth);

  const [isAddingProject, setIsAddingProject] = useState(false);

  const ProjectsComponent = () =>
    isGlobalAdmin ? (
      <AdminProjects projects={projects} onRequestAddProject={() => setIsAddingProject(true)} />
    ) : (
      <UserProjects projects={projects} />
    );

  return (
    <StyledProjectsMain>
      {isLoading ? (
        <ProjectLoading>Loading Projects...</ProjectLoading>
      ) : (
        <StyledProjectsList>
          <StyledProjectsRow>
            <ProjectsComponent />
          </StyledProjectsRow>
        </StyledProjectsList>
      )}
      <NewProjectModal isOpen={isAddingProject} close={() => setIsAddingProject(false)} />
    </StyledProjectsMain>
  );
}

export default ProjectsMain;

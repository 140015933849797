import { useState } from 'react';
import ChannelTableRow from './ChannelTableRow';
import AddEditChannel from './AddEditChannel';

import {
  StyledProjectChannels,
  ProjectDetailsStage,
  ProjectUsersHeader,
  ProjectUsersHeaderText,
  ProjectChannelsTable,
  TableHead,
  TableRow,
  AddButton,
  AddIcon,
  AddText,
} from './styles';

function ProjectChannels({ projectId, channels, isGlobalAdmin, isProjectAdmin, isProjectOwner }) {
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [channelAction, setChannelAction] = useState('add');
  const [channelToEdit, setChannelToEdit] = useState(null);

  return (
    <StyledProjectChannels>
      <ProjectDetailsStage isSidePanelOpen={sidePanelOpen}>
        <ProjectUsersHeader>
          <ProjectUsersHeaderText>Channels</ProjectUsersHeaderText>
          {!sidePanelOpen &&
            (isGlobalAdmin || isProjectAdmin || isProjectOwner ? (
              <AddButton
                type="submit"
                onClick={() => {
                  setChannelAction('add');
                  setSidePanelOpen(true);
                }}
              >
                <AddIcon className="far fa-plus" />
                <AddText>Channel</AddText>
              </AddButton>
            ) : null)}
        </ProjectUsersHeader>

        <ProjectChannelsTable>
          <TableHead>
            <TableRow>
              <td>Name</td>
              <td>Created By</td>
              <td>Label</td>
              <td>Action</td>
            </TableRow>
          </TableHead>
          <tbody>
            {channels.map((channel) => (
              <ChannelTableRow
                channel={channel}
                key={channel.id}
                onEditClick={() => {
                  setChannelAction('edit');
                  setSidePanelOpen(true);
                  setChannelToEdit(channel);
                }}
                editable={!sidePanelOpen}
              />
            ))}
          </tbody>
        </ProjectChannelsTable>
      </ProjectDetailsStage>
      {sidePanelOpen && (
        <AddEditChannel
          projectId={projectId}
          isOpen={sidePanelOpen}
          onClose={() => setSidePanelOpen(false)}
          channelAction={channelAction}
          channelToEdit={channelToEdit}
        ></AddEditChannel>
      )}
    </StyledProjectChannels>
  );
}

export default ProjectChannels;

import PubNub from 'pubnub';
import { v4 as uuidv4 } from 'uuid';
import { createSlice } from '@reduxjs/toolkit';
import backHttpClient from '../../app/backend-http-client';
import vonageClient from '../../app/vonage-client';
import { AUDIO_CALL_ACTIONS } from '../calls/calls-constants';
import { toast } from 'react-toastify';

export const audioSlice = createSlice({
  name: 'audio',
  initialState: {
    microphone: null,
    isInAudioRoom: false,
    isMicMuted: true,
    isAudioMuted: true,
    session: null,
    sessionId: '',
    subscriberToken: '',
    subscriberStream: {},
    publisherToken: '',
    publisher: null,
    isJoiningRoom: false,
    callStreamIds: [],
    callParticipantCount: 1,
    audioCallChannels: {}, // channels with ongoing audio/video calls. Use this to show the appropriate indicator on the sidebar. Some properties will be scoped to this object
    listener: null,
    activeChannelId: null,
    pubSub: null,
    isReady: false,
  },
  reducers: {
    setAudioSessionCredentials: (state, action) => {
      state.apiKey = action.payload.apiKey;
      state.sessionId = action.payload.sessionId;

      if (action.payload.session) {
        state.session = action.payload.session;
      }
    },
    setAudioSession: (state, action) => {
      state.session = action.payload;
    },
    setAudioSubscriberToken: (state, action) => {
      state.subscriberToken = action.payload;
    },
    setAudioPublisherToken: (state, action) => {
      state.publisherToken = action.payload;
    },
    setIsInAudioRoom: (state, action) => {
      state.isInAudioRoom = action.payload;
    },
    setIsMicMuted: (state, action) => {
      state.isMicMuted = action.payload;
    },
    setIsJoiningRoom: (state, action) => {
      state.isJoiningRoom = action.payload;
    },
    setIsAudioMuted: (state, action) => {
      state.isAudioMuted = action.payload;
    },
    setAudioPublisher: (state, action) => {
      state.publisher = action.payload;
    },
    setUserMedia: (state, action) => {
      state.stream = action.payload.stream;
      state.microphone = action.payload.microphone;
    },
    setCallParticipantCount: (state, action) => {
      state.callParticipantCount = action.payload;
    },
    setCallStreamIds: (state, action) => {
      state.callStreamIds = action.payload;
    },
    setReady: (state, action) => {
      state.isReady = action.payload;
    },
    setAudioEventListener: (state, action) => {
      state.listener = action.payload;
    },
    setActiveAudioCallChannel: (state, action) => {
      state.activeChannelId = action.payload;
    },
    setPubSub: (state, action) => {
      state.pubSub = action.payload;
    },
    setAudioCallChannels: (state, action) => {
      state.audioCallChannels = {
        ...state.audioCallChannels,
        ...action.payload,
      };
    },
    setSubscriberStream: (state, action) => {
      state.subscriberStream = { ...state.subscriberStream, ...action.payload };
    },
    removeSubscriberStream: (state, action) => {
      const { [action.payload]: _valueToRemove, ...remainingSubcribers } = state.subscriberStream;
      state.subscriberStream = remainingSubcribers;
    },
  },
});

// Create listeners when the user starts the application to be able to get group level
// incoming call notifications
export const registerGroupAudioPeers = (groupChannelTopics) => async (dispatch, getState) => {
  const { uuid } = getState().auth;

  // Set the initial indicator for when there is an ongoing audio call for a channel
  const initialActiveState = groupChannelTopics.reduce((activeStateByChannel, channelName) => {
    activeStateByChannel[channelName.substr(channelName.lastIndexOf('-') + 1)] = {
      callParticipants: 0,
    };
    return activeStateByChannel;
  }, {});
  dispatch(audioSlice.actions.setAudioCallChannels(initialActiveState));

  dispatch(audioSlice.actions.setIsInAudioRoom(false));
  sessionStorage.setItem('isInAudioRoom', false);

  let { pubSub } = getState().audio;

  const response = await backHttpClient.get('pubnub');
  const { publishKey, subscribeKey } = response.data;
  if (!pubSub) {
    pubSub = new PubNub({ publishKey, subscribeKey, uuid });
    dispatch(audioSlice.actions.setPubSub(pubSub));
  }

  // Subscribe to group call channels. From the Channels and Group List
  const channels = groupChannelTopics.map((topic) => `audio-${topic}`);

  const listener = {
    status: async (event) => {
      if (event.category === 'PNConnectedCategory') {
        dispatch(audioSlice.actions.setReady(true));
        const { pubSub: newPubNub } = getState().audio;

        // Get the membership object. This is where we had set the latest group call status so that those who join later will get notified.
        const metaDataBatchRequest = channels.map((channel) =>
          newPubNub.objects.getChannelMetadata({ channel })
        );

        try {
          const results = await Promise.allSettled(metaDataBatchRequest);
          // get the resolved promises and set the updated audiocall channel properties.
          const resolvedResults = results.filter((result) => result.status === 'fulfilled');
          if (resolvedResults.length) {
            resolvedResults.forEach((channelMetaData) => {
              dispatch(
                audioSlice.actions.setAudioCallChannels({
                  [channelMetaData.value.data.custom?.channelId]: JSON.parse(
                    channelMetaData.value.data.custom.audioCallStatus
                  ),
                })
              );
            });
          }
        } catch (error) {
          console.log({ error });
        }
      }
    },
    message: (event) => {
      if (!event.message.uid) {
        throw new Error('Missing message identifier.');
      }

      if (event.message.type === AUDIO_CALL_ACTIONS.JOIN_CALL) {
        const { audioCallChannels } = getState().audio;
        dispatch(
          audioSlice.actions.setAudioCallChannels({
            [event.message.channelId]: {
              callParticipants: audioCallChannels[event.message.channelId].callParticipants + 1,
            },
          })
        );
      }

      if (event.message.type === AUDIO_CALL_ACTIONS.LEAVE_CALL) {
        const { audioCallChannels } = getState().audio;
        dispatch(
          audioSlice.actions.setAudioCallChannels({
            [event.message.channelId]: {
              callParticipants: audioCallChannels[event.message.channelId].callParticipants - 1,
            },
          })
        );
      }
    },
  };

  pubSub.addListener(listener);
  dispatch(audioSlice.actions.setAudioEventListener(listener));
  pubSub.subscribe({ channels });
};

export const setActiveAudioCallChannel = (channelId) => async (dispatch, _getState) => {
  dispatch(audioSlice.actions.setActiveAudioCallChannel(channelId));
  sessionStorage.setItem('audioChannelId', channelId);
  window.dispatchEvent(new Event('storage'));
};

export const startAudioPublisher =
  (projectId, channelId, microphone, onSuccess, onError) => async (dispatch, getState) => {
    // Set the actively selected channel used for the audio call
    dispatch(setActiveAudioCallChannel(channelId));

    if (!microphone) {
      console.error(
        'Tried to start publish audio without device, so dispatch is being ignored.',
        microphone
      );
      if (onError) onError();
      return;
    }
    dispatch(audioSlice.actions.setIsJoiningRoom(true));
    const { session, publisher, publisherToken } = getState().audio;

    if (!publisherToken || !publisher) {
      await recreateAudioDataAsPublisher(projectId, channelId)(dispatch, getState);
      dispatch(startAudioPublisher(projectId, channelId, microphone, onSuccess, onError));
      return;
    }

    session.on('streamCreated', function streamCreated(event) {
      const subscriberOptions = {
        subscribeToVideo: false,
        subscribeToAudio: true,
        insertDefaultUI: false,
      };
      const { callParticipantCount, callStreamIds } = getState().audio;
      const { stream } = event;
      if (!callStreamIds.includes(stream.id)) {
        dispatch(audioSlice.actions.setCallStreamIds([...callStreamIds, stream.id]));
        dispatch(audioSlice.actions.setCallParticipantCount(callParticipantCount + 1));

        // store the streams and get use that to control the on and off state of the subscribers.
        dispatch(
          audioSlice.actions.setSubscriberStream({
            [stream.streamId]: stream,
          })
        );

        session.subscribe(event.stream, subscriberOptions);
        dispatch(audioSlice.actions.setIsAudioMuted(false));
      }
    });

    session.on('SessionConnectEvent', function streamCreated(event) {
      const subscriberOptions = {
        subscribeToVideo: false,
        subscribeToAudio: true,
        insertDefaultUI: false,
      };
      const { callParticipantCount } = getState().audio;
      const { streams, callStreamIds } = event;
      dispatch(audioSlice.actions.setCallParticipantCount(callParticipantCount + streams.length));

      streams.array.forEach((stream) => {
        if (!callStreamIds.includes(stream.id)) {
          dispatch(audioSlice.actions.setCallStreamIds([...callStreamIds, stream.id]));
          dispatch(audioSlice.actions.setCallParticipantCount(callParticipantCount + 1));
          session.subscribe(event.stream, subscriberOptions);
        }
      });
    });

    session.on('streamDestroyed', function streamCreated(event) {
      const { stream } = event;
      dispatch(audioSlice.actions.removeSubscriberStream(stream.id));
      const { callParticipantCount, callStreamIds } = getState().audio;
      const streamIndex = callStreamIds.findIndex((streamId) => streamId === stream.id);
      if (streamIndex !== -1) {
        dispatch(
          audioSlice.actions.setCallStreamIds([
            ...callStreamIds.slice(0, streamIndex),
            ...callStreamIds.slice(streamIndex + 1),
          ])
        );
        dispatch(audioSlice.actions.setCallParticipantCount(callParticipantCount - 1));
      }
    });
    await vonageClient.connectSession(session, publisherToken);

    if (microphone) {
      publisher.setAudioSource(microphone.deviceId);
    }

    session.publish(publisher, (error) => {
      if (error) {
        console.error('Error on publishing stream.');
        return onError();
      }

      dispatch(audioSlice.actions.setIsInAudioRoom(true));
      sessionStorage.setItem('isInAudioRoom', true);

      dispatch(audioSlice.actions.setIsJoiningRoom(false));

      // Broadcast a call notification to all members
      const { pubSub } = getState().audio;
      const { userId } = getState().auth;
      pubSub.publish(
        {
          channel: `audio-channel-${projectId}-${channelId}`,
          message: {
            channelId,
            uid: uuidv4(),
            inviterId: userId,
            type: AUDIO_CALL_ACTIONS.JOIN_CALL,
            activity: 'call',
            activityType: 'audio',
            createdAt: new Date().toISOString(),
          },
        },
        (status) => {
          if (status.error) {
            console.error('Error on message publish', status.error);
            toast.error('Unknown error: could not publish message.', {
              theme: 'light',
            });
          }
        }
      );

      onSuccess();
    });
  };

const recreateAudioDataAsPublisher = (projectId, channelId) => async (dispatch, getState) => {
  const { session } = getState().audio;

  const response = await backHttpClient.post(
    `/projects/${projectId}/channel/${channelId}/groupcall`,
    {
      role: 'publisher',
      type: 'audio',
    }
  );
  const { sessionId, token, apiKey } = response.data;

  if (session) session.disconnect();

  const updatedSession = vonageClient.initSession(apiKey, sessionId);
  const publisher = vonageClient.OT.initPublisher(
    '',
    {
      publishAudio: false,
      videoSource: null,
      insertDefaultUI: false,
    },
    (error) => error && console.error('Error on init publisher for audio channel.')
  );

  dispatch(
    audioSlice.actions.setAudioSessionCredentials({ apiKey, sessionId, session: updatedSession })
  );
  publisher.on('streamDestroyed', () => {
    dispatch(audioSlice.actions.setIsInAudioRoom(false));
    sessionStorage.setItem('isInAudioRoom', false);
    dispatch(audioSlice.actions.setCallParticipantCount(1));
    dispatch(audioSlice.actions.setCallStreamIds([]));

    // When the publisher destroys their stream, reduce the callParticipant count by 1
    // if stream is destroyed and user is not in an audioRoom and the remoteStreams === 0, it means they are no longer in the audio call
    const { pubSub } = getState().audio;
    const { userId } = getState().auth;

    // inform them you are leaving the call
    pubSub.publish(
      {
        channel: `audio-channel-${projectId}-${channelId}`,
        message: {
          channelId,
          uid: uuidv4(),
          inviterId: userId,
          type: AUDIO_CALL_ACTIONS.LEAVE_CALL,
          activity: 'call',
          activityType: 'audio',
          createdAt: new Date().toISOString(),
        },
      },
      (status) => {
        if (status.error) {
          console.error('Error on message publish', status.error);
          toast.error('Unknown error: could not publish message.', {
            theme: 'light',
          });
        }
      }
    );
  });

  dispatch(audioSlice.actions.setAudioSession(updatedSession));
  dispatch(audioSlice.actions.setAudioPublisherToken(token));
  dispatch(audioSlice.actions.setAudioPublisher(publisher));

  dispatch(audioSlice.actions.setIsInAudioRoom(true));
  sessionStorage.setItem('isInAudioRoom', true);
  dispatch(audioSlice.actions.setIsMicMuted(true));
  dispatch(audioSlice.actions.setIsAudioMuted(true));
};

export const stopAudioPublisher = () => (dispatch, getState) => {
  const { audio } = getState();
  const { session, publisher } = audio;

  if (!session || !publisher) {
    console.warn('Tried to stop audio publish but with no enough resources, ignoring it.');
  } else {
    session.unpublish(publisher);
    session.disconnect();
  }

  dispatch(audioSlice.actions.setAudioPublisherToken(''));
  dispatch(audioSlice.actions.setAudioPublisher(null));
  dispatch(audioSlice.actions.setIsInAudioRoom(false));
  dispatch(audioSlice.actions.setAudioSession(null));
  dispatch(audioSlice.actions.setAudioSessionCredentials({ apiKey: '', sessionId: '' }));
  dispatch(audioSlice.actions.setActiveAudioCallChannel(null));
};

export const toggleRoomMic = () => (dispatch, getState) => {
  const { isMicMuted, publisher } = getState().audio;
  if (!publisher) {
    console.warn('Unable to toggle mic when not in audio room');
  }
  const newMicState = !isMicMuted;
  publisher.publishAudio(!newMicState);
  dispatch(audioSlice.actions.setIsMicMuted(newMicState));
};

export const toggleRoomAudio = () => async (dispatch, getState) => {
  const { isAudioMuted, session, subscriberStream } = getState().audio;
  if (!session) {
    console.warn('Unable to toggle mic when not in audio room');
  }

  const muteAllStreams = !isAudioMuted;
  dispatch(audioSlice.actions.setIsAudioMuted(muteAllStreams));

  const subscribers = Object.values(subscriberStream).map(
    (userStream) => session.getSubscribersForStream(userStream)[0]
  );

  subscribers.forEach((subscriber) => {
    if (muteAllStreams) {
      subscriber.setAudioVolume(0);
    } else {
      subscriber.setAudioVolume(80);
    }
  });
};

export const stopAudioPubnubListener = () => (dispatch, getState) => {
  // TODO
  // if the audio call occupancy count for any of the channels is 1 when the listener is being
  // unpublished, reset the channel metadata to prevent instances of not having updated state.
  const { pubSub, listener } = getState().audio;
  if (pubSub) {
    pubSub.removeListener(listener);
    pubSub.unsubscribeAll();
    dispatch(audioSlice.actions.setPubSub(null));
    dispatch(audioSlice.actions.setAudioEventListener(null));
  }
};

export default audioSlice.reducer;

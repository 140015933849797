import { useState } from 'react';
import { toast } from 'react-toastify';
import { downloadFile } from '../utils/helpers';

function useDownloadAttachment() {
  const [isDownloading, setIsDownloading] = useState(false);
  const [fileName, setFileName] = useState('');
  const downloadAttachment = async (url, filename) => {
    setIsDownloading(true);
    setFileName(url);
    try {
      await downloadFile(url, filename);
    } catch (_error) {
      toast.error('Download failed. Try again');
    }
    setIsDownloading(false);
    setFileName('');
  };

  return {
    fileName,
    isDownloading,
    downloadAttachment,
  };
}

export default useDownloadAttachment;

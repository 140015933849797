import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { InputLabel, PrimaryButton, TextInput } from '../inputs';
import { ErrorText, Heading } from '../texts';
import { ErrorIcon } from '../iconography';
import { createProfile, updateProfile } from '../../users/profile-slice';

import { toast } from 'react-toastify';
import { getUserAccessLevel } from '../../../utils/helpers';
import { getUpdatedProjectInfo } from '../../main/main-slice';

function UserProfileForm({ project, close }) {
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.profile.data);
  const authUser = project.users.find((user) => user.id === Number(userId));
  const authUserChannels = authUser.Channels.filter(
    (singleChannel) => singleChannel.projectId === project.id
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [profilePic, setProfilePic] = useState(profile?.profilePictureUrl || '');
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');

    const payload = {
      name: event.target.userName.value.trim(),
      phone: event.target.phone.value.trim(),
      company: event.target.company.value.trim(),
      jobTitle: event.target.jobTitle.value.trim(),
      projectId: project.id,
      pictureBase64: profilePic,
    };
    const onSuccess = () => {
      toast.success(`Profile successfully updated`, {
        theme: 'light',
      });
      dispatch(getUpdatedProjectInfo(project.id));
      cleanUpAndClose();
      setLoading(false);
    };
    const onError = (error) => {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Unknown error while updating profile, please contact support team.');
        console.error('Error on updating profile.', error);
      }
      setLoading(false);
    };

    // If there's profile, update
    // If not, create a profile
    if (profile) {
      dispatch(updateProfile({ payload, onSuccess, onError }));
    } else {
      dispatch(createProfile({ payload, onSuccess, onError }));
    }
  };

  function cleanUpAndClose() {
    setProfilePic('');
    close();
  }
  function handleProfilePicSelected(e) {
    const fr = new FileReader();
    fr.onload = (_e) => {
      setProfilePic(_e.target.result);
    };
    fr.readAsDataURL(e.target.files[0]);
  }

  const userAccessLevel = getUserAccessLevel(authUser.ProjectUsers);

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledHeader>
        <Heading>Profile</Heading>
      </StyledHeader>
      {errorMessage && (
        <p>
          <ErrorIcon /> <ErrorText>{errorMessage}</ErrorText>
        </p>
      )}
      <main>
        <NewProjectRow>
          {profilePic ? <ProjectAvatarImage src={profilePic} alt="image" /> : <EmptyAvatarImage />}
          <label to="#" htmlFor="profilePic-upload">
            Edit Profile Picture
          </label>
          <HiddenFileInput
            type="file"
            id="profilePic-upload"
            accept="image/*"
            onChange={handleProfilePicSelected}
          />
        </NewProjectRow>
        <InputLabel>
          <span>Email</span>
          <TextInput
            name="email"
            type="email"
            autoComplete="off"
            disabled
            defaultValue={authUser.email}
          />
        </InputLabel>

        <InputLabel>
          <span>Access Level</span>
          <TextInput
            name="accessLevel"
            autoComplete="off"
            disabled
            defaultValue={userAccessLevel}
          />
        </InputLabel>

        <InputLabel>
          <span>Project Role</span>
          <TextInput
            name="projectRole"
            autoComplete="off"
            disabled
            defaultValue={authUser.ProjectUsers.projectRole}
          />
        </InputLabel>

        {authUserChannels?.length ? (
          <InputLabel>
            <span>Channels</span>
            <SelectedChannelList>
              {authUserChannels.map((channel) => (
                <RowListChannelLabel key={channel.id} color={channel.color}>
                  {channel.label}
                </RowListChannelLabel>
              ))}
            </SelectedChannelList>
          </InputLabel>
        ) : null}

        <InputLabel>
          <span>Name</span>
          <TextInput
            name="userName"
            autoComplete="off"
            defaultValue={profile?.name ?? authUser.name}
            required
          />
        </InputLabel>

        <InputLabel>
          <span>Phone</span>
          <TextInput
            name="phone"
            autoComplete="off"
            defaultValue={profile?.phone || ''}
            placeholder="Eg. 888-888-8888"
            pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
            title="Please enter valid US Phone Number."
          />
        </InputLabel>

        <InputLabel>
          <span>Company</span>
          <TextInput name="company" autoComplete="off" defaultValue={profile?.company || ''} />
        </InputLabel>

        <InputLabel>
          <span>Job Title</span>
          <TextInput name="jobTitle" autoComplete="off" defaultValue={profile?.jobTitle || ''} />
        </InputLabel>
      </main>
      {isLoading && <p>Updating your profile...</p>}
      <ModalButtonsGroup>
        <PrimaryButton type="submit">Save Changes</PrimaryButton>
      </ModalButtonsGroup>
    </StyledForm>
  );
}

const StyledHeader = styled.header`
  flex-shrink: 0;
  border-bottom: 1px solid #f0f0f0;
  margin: 0 -5% 20px;

  ${Heading} {
    padding: 0 5%;
  }
`;
const StyledForm = styled.form`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: 0 5% 40px;

  ${InputLabel} {
    margin-bottom: 30px;
  }

  main {
    flex-grow: 1;
    flex-shrink: 0;
  }
`;

const NewProjectRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  label {
    margin-left: 5px;
    cursor: pointer;
    text-decoration: underline;
  }
`;
const HiddenFileInput = styled.input`
  display: none;
`;
const ProjectAvatarImage = styled.img`
  height: 40px;
  width: 40px;
  background-color: #8c8c8c55;
  border-radius: 50%;
`;
const EmptyAvatarImage = styled.div`
  height: 40px;
  width: 40px;
  background-color: #8c8c8c55;
  border-radius: 50%;
`;

const ModalButtonsGroup = styled.div`
  flex-shrink: 0;
  width: 100%;
  text-align: center;
  margin-top: 30px;

  ${PrimaryButton} {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const ChannelLabel = styled.div.attrs((props) => ({
  style: {
    backgroundColor: props.isDefault ? '#8c8c8c55' : `${props.color}55`,
    borderColor: props.isDefault ? '#8c8c8c' : props.color,
    color: props.color,
  },
}))`
  display: inline;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-width: 2px;
  border-style: solid;
  padding: 2px;
  font-size: 10px;
  font-weight: 800;
`;

const SelectedChannelList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2px;
  border-bottom: 1px solid #c4c4c4;
`;

const RowListChannelLabel = styled(ChannelLabel)`
  margin-right: 5px;
  border-width: 1px;
  cursor: not-allowed;
  position: relative;
`;

UserProfileForm.propTypes = {};

export default UserProfileForm;

import styled from 'styled-components';
import { PrimaryButton } from '../../ui/inputs';

export const ProjectLoading = styled.p`
  display: flex;
`;

export const NewProjectRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  label {
    margin-left: 5px;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const ProjectAvatarImage = styled.img`
  height: 40px;
  width: 40px;
  background-color: #8c8c8c55;
  border-radius: 50%;
`;

export const EmptyAvatarImage = styled.div`
  height: 40px;
  width: 40px;
  background-color: #8c8c8c55;
  border-radius: 50%;
`;

export const StyledProjectsMain = styled.div`
  height: calc(100% - 161px);
  padding: 20px;
  overflow: scroll;
  background-color: #fafafa;
`;

export const StyledProjectsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`;

export const StyledProjectsRow = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 -15px;
`;

export const StyledProjectsCol = styled.div`
  flex: 1 1 25%;
  max-width: 25%;
  padding: 15px;
  box-sizing: border-box;
`;

export const StyledProjectTile = styled.div`
  min-height: 275px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #8c8c8c55;
  color: ${(props) => (props.enabled ? '#000000' : '#8c8c8c')};
  background: ${(props) => (props.enabled ? '#fff' : '#f9f9f9')};
  cursor: ${(props) => (props.enabled || props.isGlobalAdmin ? 'pointer' : 'not-allowed')};
  -webkit-appearance: button;
  border-radius: 6px;

  &:hover {
    background: #f9f9f9;
  }
`;

export const CreateLabel = styled.span`
  font-size: 16px;
  margin-top: 10px;
  font-weight: 700;
`;

export const StyledProjectLogo = styled.div`
  height: 170px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #8c8c8c55;
  background: #d2d2d2;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  img {
    height: 170px;
    width: 100%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
`;

export const StyledProjectDetails = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  margin-top: auto;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
`;

export const StyledProjectDetail = styled.p`
  margin: 0px 0px 5px;
  padding: 0;
  font-size: 14px;

  &:last-child {
    margin: none;
  }
`;

export const StyledProjectDetailName = styled(StyledProjectDetail)`
  font-weight: 700;
  font-size: 16px;
`;

export const AddProjectIcon = styled.i`
  font-size: 70px;
  font-weight: 200;
  margin-bottom: 5px;
`;

export const CameraLogoIcon = styled.i`
  margin: auto;
  font-size: 120px;
  color: rgba(0, 0, 0, 0.15);
`;

export const ModalButtonsGroup = styled.div`
  width: 100%;
  text-align: center;

  ${PrimaryButton} {
    width: 100%;
    margin-bottom: 5px;
  }
`;

import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CallModalHeader,
  CallModalHeaderControls,
  VideoCallActionButtonGroup,
} from '../main/styles';
import styled from 'styled-components';
import { Modal } from '../ui/modal';
import { ConfigButton, NoiseBgWrapHelper } from '../main/project-main-sidebar/styles';
import { toggleVideoCallMic, endVideoCall, setScreenToDisplay } from '../video/group-video-slice';
import SidebarBottomCallActions from '../../components/SidebarBottomCallActions';
import { AUDIO_LEVEL_THRESHOLD } from '../calls/calls-constants';
import NoiseBgHelper from '../../images/noise_bg_helper.png';
import { createChunk } from '../../utils/helpers';
import debounce from 'lodash.debounce';
import AVControlsModal from '../broadcast/AVControlsModal';

function GroupCallModal({
  groupName,
  groupPubnubChannel,
  startMinimized,
  handleAddVideoToAudioCall,
  children,
}) {
  const dispatch = useDispatch();

  const [isVideoMinimized, setIsVideoMinimized] = useState(startMinimized);
  const {
    session,
    publisher,
    isMicMuted,
    subscriberStream,
    subscribers: subscriberInstancesPerStream,
    isInGroupVideoCall,
  } = useSelector((state) => state.groupVideoCall);

  const [isOpenCameraSwitchModal, setIsOpenCameraSwitchModal] = useState(false);
  const audioLevelByStreamIds = useRef({});
  const videoDisabledSubscribers = useRef([]);
  const publisherAudioLevel = useRef(0);
  const [activePage, setActivePage] = useState(0);

  const numberOfSubscriberStreams = Object.keys(subscriberStream).length;
  const numberInCall = publisher ? numberOfSubscriberStreams + 1 : numberOfSubscriberStreams;

  const handleCameraChange = async (camearDevice) => {
    await publisher.setVideoSource(camearDevice.deviceId);
  };

  const handleMicToggle = () => {
    dispatch(toggleVideoCallMic());
  };

  const endCall = () => {
    dispatch(endVideoCall(groupPubnubChannel));
  };

  const handleToggleUI = () => {
    let subscribers = [];
    if (session && numberOfSubscriberStreams) {
      subscribers = Object.values(subscriberStream).map(
        (userStream) => session.getSubscribersForStream(userStream)[0]
      );
    }

    if (isVideoMinimized) {
      setIsVideoMinimized(false);
      dispatch(setScreenToDisplay('large'));
      if (publisher) {
        const maxLocal = document.querySelector('#group-local-video');
        maxLocal.appendChild(publisher.element);
      }

      if (subscribers.length) {
        subscribers.forEach((subscriber) => {
          document
            .getElementById(`remote-video-${subscriber.streamId}`)
            .appendChild(subscriber.element);
        });
      }
    } else {
      setIsVideoMinimized(true);
      dispatch(setScreenToDisplay('minimized'));

      if (publisher) {
        const minLocal = document.querySelector('#group-min-local-video');
        minLocal.appendChild(publisher.element);
      }

      if (subscribers.length) {
        subscribers.forEach((subscriber) => {
          document
            .getElementById(`remote-min-video-${subscriber.streamId}`)
            .appendChild(subscriber.element);
        });
      }
    }
  };

  const subsAudioLevelUpdateRef = useRef(null);
  const subsAudioLevelUpdateFn = (audioEvent, streamId) => {
    audioLevelByStreamIds.current = {
      ...audioLevelByStreamIds.current,
      [streamId]: subscriberInstancesPerStream?.[streamId]?.stream?.hasAudio
        ? audioEvent.audioLevel
        : 0,
    };
  };
  subsAudioLevelUpdateRef.current = debounce(subsAudioLevelUpdateFn, 500, {
    leading: true,
    trailing: true,
  });

  const pubAudioLevelUpdateRef = useRef(null);
  const pubAudioLevelUpdateFn = (audioEvent) => {
    publisherAudioLevel.current = audioEvent.audioLevel;
  };
  pubAudioLevelUpdateRef.current = debounce(pubAudioLevelUpdateFn, 500, {
    leading: true,
    trailing: true,
  });

  useEffect(() => {
    Object.keys(subscriberInstancesPerStream).forEach((streamId) => {
      subscriberInstancesPerStream?.[streamId]?.on('audioLevelUpdated', (audioEvent) => {
        subsAudioLevelUpdateRef.current(audioEvent, streamId);
      });

      subscriberInstancesPerStream?.[streamId]?.on('videoDisabled', (videoDisabledEvent) => {
        if (numberInCall > 4) {
          videoDisabledSubscribers.current = [
            ...videoDisabledSubscribers.current,
            videoDisabledEvent.target.streamId,
          ];
        }
      });

      subscriberInstancesPerStream?.[streamId]?.on('videoEnabled', (videoEnabledEvent) => {
        videoDisabledSubscribers.current = videoDisabledSubscribers.current.filter(
          (_streamId) => _streamId !== videoEnabledEvent.target.streamId
        );
      });
    });

    publisher?.on('audioLevelUpdated', (audioEvent) => {
      pubAudioLevelUpdateRef.current(audioEvent);
    });

    return () => {
      Object.keys(subscriberInstancesPerStream).forEach((streamId) => {
        subscriberInstancesPerStream?.[streamId]?.off('audioLevelUpdated');
        audioLevelByStreamIds.current = {
          ...audioLevelByStreamIds.current,
          [streamId]: 0,
        };
        subscriberInstancesPerStream?.[streamId]?.off('videoDisabled');
        subscriberInstancesPerStream?.[streamId]?.off('videoEnabled');
      });

      publisher?.off('audioLevelUpdated');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberInstancesPerStream, publisher]);

  const highAudioSubscriber = Object.keys(audioLevelByStreamIds.current).reduce(
    (a, b) => (audioLevelByStreamIds.current[a] > audioLevelByStreamIds.current[b] ? a : b),
    ''
  );
  const isSubscriberSpeaking =
    audioLevelByStreamIds.current?.[highAudioSubscriber] > publisherAudioLevel.current;
  const allSubscribersToDisplay = Object.keys(subscriberStream).filter(
    (_streamId) => !videoDisabledSubscribers.current?.includes(_streamId)
  );

  const firstPageSubs = allSubscribersToDisplay.slice(0, numberInCall > 4 ? 3 : 4);
  const remainingPageSubs = allSubscribersToDisplay.slice(firstPageSubs.length);
  const chunkedRemainingSubs = createChunk(remainingPageSubs, 4);
  const activeSubsToDisplay =
    activePage === 0 ? firstPageSubs : chunkedRemainingSubs[activePage - 1];

  // Pagination Methods
  const goToPrevPage = () => {
    if (activePage > 0) {
      setActivePage((currentPage) => currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (chunkedRemainingSubs[activePage]) {
      setActivePage((currentPage) => currentPage + 1);
    }
  };

  // End of pagination methods

  const isVisibleSubscriber = (_streamId) => activeSubsToDisplay?.includes(_streamId);
  const displayedNumber =
    publisher && activePage === 0 ? activeSubsToDisplay?.length + 1 : activeSubsToDisplay?.length;

  const SubsNavButtons = () => (
    <>
      {activePage > 0 && (
        <StyledPreviousButton onClick={(e) => goToPrevPage(e)} $isVideoMinimized={isVideoMinimized}>
          <i className="fas fa-long-arrow-alt-left"></i>
        </StyledPreviousButton>
      )}
      {chunkedRemainingSubs[activePage] && (
        <StyledNextButton onClick={(e) => goToNextPage(e)} $isVideoMinimized={isVideoMinimized}>
          <i className="fas fa-long-arrow-alt-right"></i>
        </StyledNextButton>
      )}
    </>
  );

  return (
    <>
      <Modal
        onRequestClose={null}
        isOpen={isInGroupVideoCall}
        content={{
          backgroundColor: '#000000',
          color: '#ffffff',
          aspectRatio: '4/3',
          width: 'auto',
          height: '70%',
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: 0,
        }}
        overlay={isVideoMinimized ? { height: 0, zIndex: -1 } : null}
        closeIcon={true}
      >
        <CallModalHeaderMax>
          {groupName && <p className="groupName">{groupName}</p>}
          <CallModalHeaderControls>
            {isVideoMinimized ? (
              <button onClick={handleToggleUI}>
                <i style={{ color: '#ffffff' }} className="far fa-plus-circle" />
              </button>
            ) : (
              <button onClick={handleToggleUI}>
                <i className="far fa-minus-circle" />
              </button>
            )}
            <button onClick={endCall}>
              <i className="far fa-times-circle" />
            </button>
          </CallModalHeaderControls>
        </CallModalHeaderMax>

        <StyledVideoContainer
          $numberInCall={numberInCall}
          $isMinimized={isVideoMinimized}
          $displayedNumber={displayedNumber}
        >
          <StyledGroupLocalVideo
            id="group-local-video"
            $isHidden={!publisher || activePage !== 0}
            $isSpeaking={
              !isSubscriberSpeaking &&
              !isMicMuted &&
              numberInCall > 1 &&
              publisherAudioLevel.current >= AUDIO_LEVEL_THRESHOLD
            }
          ></StyledGroupLocalVideo>

          {allSubscribersToDisplay.map((streamId) => (
            <StyledRemoteVideo
              id={`remote-video-${streamId}`}
              key={streamId}
              $isVisible={isVisibleSubscriber(streamId)}
              $isSpeaking={
                isSubscriberSpeaking &&
                highAudioSubscriber === streamId &&
                numberInCall > 1 &&
                audioLevelByStreamIds.current[streamId] >= AUDIO_LEVEL_THRESHOLD
              }
            ></StyledRemoteVideo>
          ))}
          <SubsNavButtons />

          {publisher && (
            <StyledConfigButton className="yellow" onClick={() => setIsOpenCameraSwitchModal(true)}>
              <i className="fa fa-wrench"></i>
            </StyledConfigButton>
          )}
        </StyledVideoContainer>

        <ModalBottomCallActions>
          <SidebarBottomCallActions
            id="modal-group-call-controls"
            isVideoCall={true}
            groupName={groupName}
            handleMicToggle={handleMicToggle}
            groupPubnubChannel={groupPubnubChannel}
            handleAddVideoToAudioCall={handleAddVideoToAudioCall}
          >
            {!isVideoMinimized && children}
          </SidebarBottomCallActions>
        </ModalBottomCallActions>
      </Modal>

      {/* Minimized Video */}
      <StyledMinimizedGroupCall $isHidden={!isVideoMinimized} id="group-mini-player">
        <CallModalHeader className="call-modal-header">
          {groupName && <p className="groupName">{groupName}</p>}
          <CallModalHeaderControls>
            {isVideoMinimized ? (
              <button onClick={handleToggleUI}>
                <i style={{ color: '#ffffff' }} className="far fa-plus-circle" />
              </button>
            ) : (
              <button onClick={handleToggleUI}>
                <i className="far fa-minus-circle" />
              </button>
            )}
            <button onClick={endCall}>
              <i className="far fa-times-circle" />
            </button>
          </CallModalHeaderControls>
        </CallModalHeader>

        <StyledVideoContainer
          $numberInCall={numberInCall}
          $isMinimized={isVideoMinimized}
          $displayedNumber={displayedNumber}
        >
          <StyledGroupLocalMinVideo
            $isHidden={!publisher || activePage !== 0}
            id="group-min-local-video"
            $isSpeaking={
              !isSubscriberSpeaking &&
              !isMicMuted &&
              numberInCall > 1 &&
              publisherAudioLevel.current >= AUDIO_LEVEL_THRESHOLD
            }
          ></StyledGroupLocalMinVideo>

          {allSubscribersToDisplay.map((streamId) => (
            <StyledRemoteVideo
              id={`remote-min-video-${streamId}`}
              key={streamId}
              $isVisible={isVisibleSubscriber(streamId)}
              $isSpeaking={
                isSubscriberSpeaking &&
                highAudioSubscriber === streamId &&
                numberInCall > 1 &&
                audioLevelByStreamIds.current[streamId] >= AUDIO_LEVEL_THRESHOLD
              }
            ></StyledRemoteVideo>
          ))}
        </StyledVideoContainer>

        <MinimizedCallActions>
          <SidebarBottomCallActions
            groupName={groupName}
            handleAddVideoToAudioCall={handleAddVideoToAudioCall}
            isVideoCall={true}
            groupPubnubChannel={groupPubnubChannel}
          >
            {isVideoMinimized && children}
          </SidebarBottomCallActions>
        </MinimizedCallActions>
        <SubsNavButtons />

        {publisher && (
          <StyledConfigButton className="yellow" onClick={() => setIsOpenCameraSwitchModal(true)}>
            <i className="fa fa-wrench"></i>
          </StyledConfigButton>
        )}
      </StyledMinimizedGroupCall>

      {/* Camera Change Modal */}
      {isOpenCameraSwitchModal && (
        <AVControlsModal
          close={() => setIsOpenCameraSwitchModal(false)}
          onDoneBroadcast={handleCameraChange}
          actionText="Change Camera"
        />
      )}
    </>
  );
}

const setGridSizes = (numberInCall) => {
  if (numberInCall === 1) {
    return '1fr 1fr';
  }
  if (numberInCall >= 2) {
    return 'repeat(2, 1fr) / repeat(2, 1fr)';
  }
};

const StyledVideoContainer = styled.div`
  position: initial;
  display: grid;
  grid-template: ${(props) => setGridSizes(props.$displayedNumber)};
  width: 100%;
  height: ${(props) => (props.$isMinimized ? 'auto' : '100%')};
  gap: ${(props) => (props.$isMinimized ? '0px' : '2px')};
  flex: 1;
  border-radius: 2px;
  overflow: hidden;
  background: #000;
  z-index: 9;

  .OT_video-element {
    object-fit: cover;
  }
`;

const StyledGroupLocalVideo = styled.div`
  position: relative;
  display: ${(props) => (props.$isHidden ? 'none' : 'inherit')};
  border: ${(props) => (props.$isSpeaking ? '1px solid red' : 0)};
  border-radius: 4px;
  width: 100%;
`;

const StyledGroupLocalMinVideo = styled.div`
  display: ${(props) => (props.$isHidden ? 'none' : 'inherit')};
  border: ${(props) => (props.$isSpeaking ? '1px solid red' : 0)};
  border-radius: 4px;
  width: 100%;
`;

const StyledRemoteVideo = styled.div`
  border: ${(props) => (props.$isSpeaking ? '1px solid red' : 0)};
  display: ${(props) => (props.$isVisible ? 'inherit' : 'none')};
  border-radius: 4px;
  width: 100%;
`;

const StyledMinimizedGroupCall = styled(NoiseBgWrapHelper)`
  position: ${(props) => (props.$isMinimized ? 'intiial' : 'absolute')};
  top: 0;
  left: 0;
  box-sizing: border-box;
  display: ${(props) => (props.$isHidden ? 'none' : 'flex')};
  height: ${(props) => (props.$isHidden ? '0px' : '100%')};
  width: ${(props) => (props.$isHidden ? '0px' : '100%')};
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  padding: 8px;

  .OT_video-element {
    object-fit: cover;
  }

  ${VideoCallActionButtonGroup} {
    display: ${(props) => (props.$isHidden ? 'none' : 'inherit')};
  }

  ${CallModalHeader} {
    color: #ffffff;
  }

  .groupName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ModalBottomCallActions = styled(VideoCallActionButtonGroup)`
  position: ${(props) => (props.$isMinimized ? 'absolute' : 'initial')};
  width: 100%;
  box-sizing: border-box;
  background-image: url(${NoiseBgHelper});
  background-color: #333333;
  background-blend-mode: overlay;
  box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.35),
    inset 0px 1.5px 0.5px rgba(0, 0, 0, 0.5);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: rgba(255, 255, 255, 75%);
  bottom: 0;
  padding: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .group-name {
    display: none;
  }

  .actions-wrap {
    width: 220px;
  }

  .controls-container {
    margin: 0 auto;
  }
  .end-call-btn {
    margin-left: 20px;
  }
`;

const MinimizedCallActions = styled.div`
  .controls-container {
    display: flex;
  }

  .group-name {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mute-controls {
    display: flex;
    border-radius: 40px;
    background: #333333;
    margin-right: 5px;

    button {
      padding: 0 5px;

      &:first-child {
        padding-left: 12px;
      }

      &:last-child {
        padding-right: 12px;
      }
    }
  }
`;

const CallModalHeaderMax = styled(CallModalHeader)`
  min-height: 52px;
  position: ${(props) => (props.$isMinimized ? 'absolute' : 'initial')};
  background-image: url(${NoiseBgHelper});
  background-color: #333333;
  background-blend-mode: overlay;
  box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.35),
    inset 0px 1.5px 0.5px rgba(0, 0, 0, 0.5);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: rgba(255, 255, 255, 75%);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .groupName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledNavButton = styled.button`
  cursor: pointer;
  position: absolute;
  z-index: 100;
  display: block;
  padding: 5px 20px;
  i {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const StyledPreviousButton = styled(StyledNavButton)`
  left: 0;
  top: ${(props) => (props.$isVideoMinimized ? '35%' : '43%')};
`;

const StyledNextButton = styled(StyledNavButton)`
  right: 0;
  top: ${(props) => (props.$isVideoMinimized ? '35%' : '43%')};
`;

const StyledConfigButton = styled(ConfigButton)`
  position: absolute;
  bottom: 120px;
  left: 20px;
  z-index: 10;
`;

export default GroupCallModal;

import React, { useState } from 'react';
import styled from 'styled-components';
import LutsControls from '../broadcast/LutsControls';
import LutsControlDarkIcon from '../../images/lut_control_dark_icon.svg';
import AspectRatioControlDarkIcon from '../../images/aspect_ratio_control_dark_icon.svg';
import ExposureDarkIcon from '../../images/exposure_dark_icon.svg';
import SaturationDarkIcon from '../../images/saturation_dark_icon.svg';
import TemperatureDarkIcon from '../../images/temperature_dark_icon.svg';
import { ConfigButton, ConfigButtonShell } from '../../features/main/project-main-sidebar/styles';

function VideoSettings({
  lutsValues,
  closeSettings,
  setLutsValues,
  isIngoingAspectRatioChecked,
  handleIngoingAspectRatioClickFn,
}) {
  const [videoControlType, setVideoControlType] = useState('');
  const [lutsType, setLutsType] = useState(LUTS.BRIGHTNESS);

  const toggleAspectRatioControl = () => {
    if (videoControlType !== 'ASPECT_RATIO') {
      setVideoControlType('ASPECT_RATIO');
    } else {
      setVideoControlType('');
    }
  };

  const toggleLutsControl = () => {
    if (videoControlType !== 'LUTS') {
      setVideoControlType('LUTS');
    } else {
      setVideoControlType('');
    }
  };

  const isLutsSettings = videoControlType === 'LUTS';
  const isAspectRatioSettings = videoControlType === 'ASPECT_RATIO';
  const isNotDefaultBrightnessValue = lutsValues[LUTS.BRIGHTNESS].value !== '';
  const isNotDefaultContrastValue = lutsValues[LUTS.CONTRAST].value !== '';
  const isNotDefaultTemperatureValue = lutsValues[LUTS.TEMPERATURE].value !== '';

  return (
    <VideoSettingsWrapper>
      <CloseSettingsPanel onClick={closeSettings}>Close</CloseSettingsPanel>
      <Controls>
        <ConfigButtonShell>
          <ControlButton
            className={`yellow ${isLutsSettings ? 'on' : 'off'} active`}
            onClick={toggleLutsControl}
          >
            <img src={LutsControlDarkIcon} height="15px" width="15px" />
          </ControlButton>
        </ConfigButtonShell>
        <ConfigButtonShell>
          <ControlButton
            className={`yellow ${isAspectRatioSettings ? 'on' : 'off'} active`}
            isSelected={isAspectRatioSettings}
            onClick={toggleAspectRatioControl}
          >
            <img src={AspectRatioControlDarkIcon} height="10px" width="10px" />
          </ControlButton>
        </ConfigButtonShell>
      </Controls>

      {isLutsSettings && (
        <LutsWrapper>
          <ControlButton
            className={`yellow ${isNotDefaultBrightnessValue ? 'on' : 'off'} active`}
            isSelected={lutsType === LUTS.BRIGHTNESS}
            onClick={() => setLutsType(LUTS.BRIGHTNESS)}
          >
            <img src={ExposureDarkIcon} height="15px" width="15px" />
          </ControlButton>
          <ControlButton
            className={`yellow ${isNotDefaultContrastValue ? 'on' : 'off'} active`}
            isSelected={lutsType === LUTS.CONTRAST}
            onClick={() => setLutsType(LUTS.CONTRAST)}
          >
            <img src={SaturationDarkIcon} height="15px" width="15px" />
          </ControlButton>
          <ControlButton
            className={`yellow ${isNotDefaultTemperatureValue ? 'on' : 'off'} active`}
            isSelected={lutsType === LUTS.TEMPERATURE}
            onClick={() => setLutsType(LUTS.TEMPERATURE)}
          >
            <img src={TemperatureDarkIcon} height="15px" width="15px" />
          </ControlButton>
        </LutsWrapper>
      )}

      {isAspectRatioSettings && (
        <RatioButtonGroup>
          <RatioButton
            $active={isIngoingAspectRatioChecked(RATIO_UNSET)}
            onClick={() => handleIngoingAspectRatioClickFn(RATIO_UNSET)}
            className={`yellow ${isIngoingAspectRatioChecked(RATIO_UNSET) ? 'on' : 'off'} active`}
          >
            None
          </RatioButton>
          <RatioButton
            $active={isIngoingAspectRatioChecked(RATIO_16_PER_9)}
            onClick={() => handleIngoingAspectRatioClickFn(RATIO_16_PER_9)}
            className={`yellow ${
              isIngoingAspectRatioChecked(RATIO_16_PER_9) ? 'on' : 'off'
            } active`}
          >
            16:9
          </RatioButton>
          <RatioButton
            $active={isIngoingAspectRatioChecked(RATIO_4_PER_3)}
            onClick={() => handleIngoingAspectRatioClickFn(RATIO_4_PER_3)}
            className={`yellow ${isIngoingAspectRatioChecked(RATIO_4_PER_3) ? 'on' : 'off'} active`}
          >
            4:3
          </RatioButton>
          <RatioButton
            $active={isIngoingAspectRatioChecked(RATIO_1_PER_1)}
            onClick={() => handleIngoingAspectRatioClickFn(RATIO_1_PER_1)}
            className={`yellow ${isIngoingAspectRatioChecked(RATIO_1_PER_1) ? 'on' : 'off'} active`}
          >
            1:1
          </RatioButton>
          <RatioButton
            className={`yellow ${
              isIngoingAspectRatioChecked(RATIO_9_PER_16) ? 'on' : 'off'
            } active`}
            $active={isIngoingAspectRatioChecked(RATIO_9_PER_16)}
            onClick={() => handleIngoingAspectRatioClickFn(RATIO_9_PER_16)}
          >
            9:16
          </RatioButton>
        </RatioButtonGroup>
      )}

      {videoControlType === 'LUTS' && (
        <LutsControls selectedLuts={lutsType} updateLuts={setLutsValues} lutsValues={lutsValues} />
      )}
    </VideoSettingsWrapper>
  );
}

// References for sizes:
// https://www.comtech-networking.com/blog/item/4-what-is-the-screen-resolution-or-the-aspect-ratio-what-do-720p-1080i-1080p-mean/
// https://openxcom.org/forum/index.php?topic=842.0
// https://pacoup.com/2011/06/12/list-of-true-169-resolutions/

// Set INITIAL_RATIO to define input camera origina Aspect Ratio.
export const INITIAL_RATIO = '16 / 9';
export const RATIO_UNSET = 'ratio-unset';
export const RATIO_16_PER_9 = 'ratio-16-9';
const RATIO_4_PER_3 = 'ratio-4-3';
const RATIO_1_PER_1 = 'ratio-1-1';
const RATIO_9_PER_16 = 'ratio-9-16';

export const LUTS = {
  BRIGHTNESS: 'brightness',
  CONTRAST: 'contrast',
  TEMPERATURE: 'temperature',
};

const RatioButton = styled(ConfigButton)`
  height: 30px;
  font-size: 13px;
  width: 50px;
  max-width: 50px;
  flex: 1;
`;

const RatioButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;

  ${RatioButton} {
    margin-left: 8px;
    margin-right: 8px;
  }
`;

const VideoSettingsWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 20px auto;

  .rc-slider-track {
    background-color: #f3dd9b;
  }

  .rc-slider-handle:hover,
  .rc-slider-handle {
    border: #f3dd9b;
  }

  .rc-slider-handle-dragging {
    border-color: #f3dd9b !important;
    box-shadow: 0 0 0 5px #f3dd9b !important;
  }
`;

const LutsWrapper = styled(ConfigButtonShell)`
  display: flex;
  margin: 0 auto 25px;
  width: fit-content;

  button {
    margin: 0 2px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;

const ControlButton = styled(ConfigButton)``;

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0 35px;
`;

export default VideoSettings;

const CloseSettingsPanel = styled(ConfigButtonShell)`
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 13px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`;

import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { projectsActions } from './projects-duck';
import ProjectsTopBar from './ProjectsTopBar';
import ProjectDetailNav from './project-detail/ProjectDetailNav';
import ProjectChannels from './project-detail/ProjectChannels';
import ProjectUsers from './project-detail/ProjectUsers';
import EditProject from './project-main/EditProject';
import { StylingProjectDetail, ProjectDetailBody, ProjectLoading } from './project-detail/styles';

function ProjectDetail() {
  const { isGlobalAdmin, userId } = useSelector((state) => state.auth);
  const [pageIndex, setPageIndex] = useState(0);
  const [isOpenProjectEditModal, setIsOpenProjectEditModal] = useState(false);
  const dispatch = useDispatch();

  const { id } = useParams();
  const projectState = useSelector((state) => state.projects);
  const { items: projects, isLoading } = projectState;

  const project = projects.find((_project) => _project.id === Number(id));
  const projectNotFound = !isLoading && !project;
  const projectLoaded = (!isLoading && project) || (isLoading && project);

  const isProjectAdmin = project?.isProjectAdmin;
  const isProjectOwner = project?.isProjectOwner;
  const isProjectMember = project?.isProjectMember;

  const fetchProjects = useCallback(() => {
    if (isGlobalAdmin) {
      dispatch(projectsActions.readAll(true, null));
    } else {
      dispatch(projectsActions.readAll(false, userId));
    }
  }, [dispatch, userId, isGlobalAdmin]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  // If user is a Project Member, take them to the room
  if (project && !isGlobalAdmin && !isProjectAdmin && !isProjectOwner) {
    return <Redirect to={`/projects/${project.id}/room`} />;
  }

  return (
    <>
      <StylingProjectDetail>
        {projectLoaded && (
          <>
            <ProjectsTopBar toggleEditProjectModal={setIsOpenProjectEditModal} />
            <ProjectDetailNav
              onIndexChanged={(index) => setPageIndex(index)}
              projectTitle={project && project.name}
              projectId={project && project.id}
              projectLogo={project && project.logoUrl}
              enabled={project && project.enabled}
              isGlobalAdmin={isGlobalAdmin}
              isProjectAdmin={isProjectAdmin}
              isProjectOwner={isProjectOwner}
              isProjectMember={isProjectMember}
              toggleEditProjectModal={setIsOpenProjectEditModal}
            />
          </>
        )}
        <ProjectDetailBody>
          {!projectLoaded && isLoading && <ProjectLoading>Loading Project...</ProjectLoading>}
          {projectNotFound && <div>Project Not found</div>}

          {projectLoaded && pageIndex === 0 && (
            <ProjectUsers
              owner={project.owner}
              users={project.users}
              channels={project.channels}
              projectId={project.id}
              isProjectAdmin={isProjectAdmin}
              isProjectOwner={isProjectOwner}
            />
          )}
          {projectLoaded && pageIndex === 1 && (
            <ProjectChannels
              channels={project.channels}
              projectId={project.id}
              isProjectAdmin={isProjectAdmin}
              isProjectOwner={isProjectOwner}
              isGlobalAdmin={isGlobalAdmin}
            />
          )}
        </ProjectDetailBody>
      </StylingProjectDetail>
      {project && (
        <EditProject
          isOpen={isOpenProjectEditModal}
          close={() => setIsOpenProjectEditModal(false)}
          onSuccess={fetchProjects}
          project={project}
        />
      )}
    </>
  );
}

export default ProjectDetail;

import { useSelector, useDispatch } from 'react-redux';
import { TOPIC_CHANNEL, TOPIC_GROUP, TOPIC_USER } from '../calls/calls-constants';
import { toggleChatViewTypePerChannel } from '../messages/messages-slice';
import { useSelectedChatName } from '../messages/selected-chat-utils';
import { ProjectAvatarImage } from '../projects/project-main/styles';
import {
  CallButton,
  HeadingText,
  StyledViewFiles,
  ConferenceCollaper,
  StyledBroadcastHeader,
  GroupedAvatar,
} from './styles';

export default function BroadcastHeader({
  id,
  userId,
  closeChat,
  openChat,
  isChatOpen,
  isBroadcasting,
  setAVModalOpen,
  setRequestPeerId,
  viewChannelMembers,
  activeGroupMembers,
  activeChannelMembers,
  setIsJoiningOngoingCall,
  setIsOpenGroupCallModal,
  viewAdhocGroupMembers,
}) {
  const dispatch = useDispatch();
  const { currentTopic, channel, items: rawMessageItems } = useSelector((state) => state.messages);
  const { isInAudioRoom } = useSelector((state) => state.audio);
  const { isInGroupVideoCall, isReady, groupCallState } = useSelector(
    (state) => state.groupVideoCall
  );
  const chatOpenIcon = isChatOpen ? 'fal fa-angle-down' : 'fal fa-angle-up';
  const chatOpenClass = isChatOpen ? 'chat-open' : 'chat-collapsed';
  const toggleChatOpen = isChatOpen ? closeChat : openChat;
  const selectedChatName = useSelectedChatName();

  const shouldJoinOngoingCall =
    groupCallState[`video-call-${channel}`]?.callParticipants > 0 &&
    !isInGroupVideoCall &&
    !isInAudioRoom;

  const handleViewFiles = () => {
    dispatch(toggleChatViewTypePerChannel(channel));
  };

  return (
    <StyledBroadcastHeader className={chatOpenClass}>
      {currentTopic === TOPIC_USER && selectedChatName && userId && id !== userId && (
        <CallButton
          onClick={() => {
            setRequestPeerId(userId);
            setAVModalOpen(true);
          }}
        >
          <i className="fa fa-phone-alt" />
        </CallButton>
      )}

      {(currentTopic === TOPIC_GROUP || currentTopic === TOPIC_CHANNEL) &&
        selectedChatName &&
        id &&
        isReady &&
        !isInGroupVideoCall &&
        !isInAudioRoom &&
        !shouldJoinOngoingCall && (
          <CallButton
            onClick={() => {
              setRequestPeerId(id);
              setIsOpenGroupCallModal(true);
            }}
          >
            <i className="fa fa-phone-alt" />
          </CallButton>
        )}

      {/* If there is an ongoing group/channel video call and the user is not in the video call we show a button for them to join the call */}
      {shouldJoinOngoingCall && (
        <CallButton
          onClick={() => {
            setRequestPeerId(id);
            setIsJoiningOngoingCall(true);
            setIsOpenGroupCallModal(true);
          }}
        >
          <i className="fa fa-phone-alt" /> Join Call
        </CallButton>
      )}

      <HeadingText>{selectedChatName || 'No chat'}</HeadingText>
      {!!selectedChatName && Boolean(rawMessageItems?.[channel]?.length) && (
        <StyledViewFiles onClick={handleViewFiles}>View Files</StyledViewFiles>
      )}

      {currentTopic === TOPIC_CHANNEL && selectedChatName && id && (
        <GroupedAvatar onClick={() => viewChannelMembers(true)}>
          {activeChannelMembers?.slice(0, 3).map((member) => (
            <ProjectAvatarImage
              className="user-avatar"
              src={
                member?.profile[0]?.profilePictureUrl ||
                `https://ui-avatars.com/api/?name=${
                  member?.profile[0]?.name ?? member.name
                }&bold=true&background=000000&color=9d8c72&format=PNG`
              }
              key={member.id}
            />
          ))}
          <ProjectAvatarImage
            className="user-avatar"
            src={`https://ui-avatars.com/api/?name=${activeChannelMembers?.length}&bold=true&background=FFFFFF&color=000000&format=PNG`}
            key={activeChannelMembers?.length}
          />
        </GroupedAvatar>
      )}

      {currentTopic === TOPIC_GROUP && selectedChatName && id && (
        <GroupedAvatar onClick={() => viewAdhocGroupMembers(true)}>
          {activeGroupMembers?.slice(0, 3).map((member) => (
            <ProjectAvatarImage
              className="user-avatar"
              src={
                member?.profile[0]?.profilePictureUrl ||
                `https://ui-avatars.com/api/?name=${
                  member?.profile[0]?.name ?? member.name
                }&bold=true&background=000000&color=9d8c72&format=PNG`
              }
              key={member.id}
            />
          ))}
          <ProjectAvatarImage
            className="user-avatar"
            src={`https://ui-avatars.com/api/?name=${activeGroupMembers?.length}&bold=true&background=FFFFFF&color=000000&format=PNG`}
            key={activeGroupMembers?.length}
          />
        </GroupedAvatar>
      )}

      {isBroadcasting ? (
        <ConferenceCollaper role="button" onClick={toggleChatOpen}>
          <i className={chatOpenIcon} />
          <i className={chatOpenIcon} />
        </ConferenceCollaper>
      ) : (
        <></>
      )}
    </StyledBroadcastHeader>
  );
}

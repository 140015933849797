import OT from '@opentok/client';

const vonageClient = {
  OT,
  getDevices: () => {
    return new Promise((resolve, reject) => {
      OT.getDevices((error, devices) => (error ? reject(error) : resolve(devices)));
    });
  },
  initSession: (apiKey, sessionId) => {
    return OT.initSession(apiKey, sessionId);
  },
  initCallPubSub: (session, publisherCalback = () => {}, subscriberCallback = () => {}) => {
    session.on('streamCreated', (event) => {
      session.subscribe(
        event.stream,
        'ot-call',
        {
          insertMode: 'append',
        },
        subscriberCallback
      );
    });

    return OT.initPublisher(
      'ot-call',
      {
        insertMode: 'append',
        videoSource: null,
      },
      publisherCalback
    );
  },
  initSubscriber: (session, callback) => {
    session.on('streamCreated', (event) => {
      session.subscribe(
        event.stream,
        'ot-broadcast',
        {
          insertMode: 'replace',
          width: '100%',
          height: '100%',
          fitMode: 'contain',
          style: { buttonDisplayMode: 'off' },
        },
        (error) => callback(error, event)
      );
    });
  },
  initPublisher: (options, callback) => {
    return OT.initPublisher(
      'ot-broadcast',
      {
        insertMode: 'after',
        width: '100%',
        height: '100%',
        fitMode: 'contain',
        style: { buttonDisplayMode: 'off' },
        ...(options || {}),
      },
      callback
    );
  },
  initVideoCallPublisher: (targetElement, options, callback) => {
    return OT.initPublisher(
      targetElement,
      {
        insertMode: 'append',
        width: '100%',
        height: '100%',
        fitMode: 'contain',
        style: { buttonDisplayMode: 'off', audioLevelDisplayMode: 'off' },
        ...(options || {}),
      },
      callback
    );
  },
  connectSession: (session, token) => {
    return new Promise((resolve, reject) =>
      session.connect(token, (error) => (error ? reject(error) : resolve()))
    );
  },
};

if (process.env.NODE_ENV === 'development') {
  window.vonageClient = vonageClient;
}

export default vonageClient;

export default function getPasswordValidationErrors(password) {
  const passwordChars = password.split('');

  const errors = [];

  if (passwordChars.length < 8) {
    errors.push('Must have at least 8 characters.');
  }

  const hasNumber = passwordChars.some(isNumber);
  if (!hasNumber) {
    errors.push('Must have a number.');
  }

  const hasSomeSpecialCharacter = passwordChars.some(isSpecial);
  if (!hasSomeSpecialCharacter) {
    errors.push('Must have a special character (like !, #, &).');
  }

  return errors;
}

const isNumber = (char) => char >= '1' && char <= '9';
const isLowerLetter = (char) => char >= 'a' && char <= 'z';
const isUpperLetter = (char) => char >= 'A' && char <= 'Z';
const isSpecial = (char) => !isNumber(char) && !isLowerLetter(char) && !isUpperLetter(char);

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { projectsActions } from './projects-duck';
import ProjectsMain from './ProjectsMain';
import ProjectsNav from './ProjectsNav';
import ProjectsTopBar from './ProjectsTopBar';

export default function ProjectsPage() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { items: projects, isLoading: isProjectsLoading } = useSelector(
    (reduxState) => reduxState.projects
  );
  const { isGlobalAdmin, userId } = useSelector((reduxState) => reduxState.auth);

  useEffect(() => {
    if (isGlobalAdmin) {
      dispatch(projectsActions.readAll(true, null));
    } else {
      dispatch(projectsActions.readAll(false, userId));
    }
  }, [dispatch, userId, isGlobalAdmin]);

  // TODO: Investigate errors related to last logged in
  if (!isProjectsLoading && !isGlobalAdmin && state && state.from === 'login') {
    if (projects) {
      projects.sort((a, b) => Number(b.lastLoggedIn) - Number(a.lastLoggedIn));
      const lastProject = projects?.[0];
      if (lastProject) {
        const { id } = lastProject;
        return <Redirect to={{ pathname: `/projects/${id}/room` }} />;
      }
    }
  }
  return (
    (isGlobalAdmin || !isProjectsLoading) && (
      <StylingProjectsPage>
        <ProjectsTopBar />
        <ProjectsNav />
        <ProjectsMain />
      </StylingProjectsPage>
    )
  );
}

const StylingProjectsPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

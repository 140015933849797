import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { projectsActions } from '../projects-duck';
import { Modal } from '../../ui/modal';
import { PrimaryButton } from '../../ui/inputs';
import { ErrorIcon } from '../../ui/iconography';
import { ErrorText, AnchorLink } from '../../ui/texts';

import {
  TableRow,
  ActionCell,
  ChannelLabel,
  EditCell,
  DeleteCell,
  DeleteConfirmText,
  ModalButtonsGroup,
  ModalMessage,
} from './styles';
import { sendProjectUpdateNotice } from '../../broadcast/broadcast-slice';

function ChannelTableRow({ channel, onEditClick, editable }) {
  const dispatch = useDispatch();
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleChannelDelete = (e) => {
    e.preventDefault();
    const payload = {
      channelId: channel.id,
      type: 'remove-channel',
    };
    const onSuccess = () => {
      setIsConfirmingDelete(false);

      dispatch(sendProjectUpdateNotice(channel.projectId));
    };
    const onError = (error) => {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Unknown error carrying out operation, please contact support team.');
        console.error('Error saving changes.', error);
      }
    };
    dispatch(projectsActions.update(channel.projectId, payload, onSuccess, onError));
  };
  return (
    <TableRow>
      <td>{channel.name}</td>
      <td>{channel.creator}</td>
      <td>
        <ChannelLabel color={channel ? channel.color : ''} isDefault={channel.isDefault}>
          {channel.isDefault ? `Default` : channel.label}
        </ChannelLabel>
      </td>
      <ActionCell>
        <EditCell
          editable={editable}
          isDefault={channel.isDefault}
          onClick={editable ? () => onEditClick() : null}
        >
          Edit
        </EditCell>
        <DeleteCell editable onClick={() => setIsConfirmingDelete(true)}>
          Remove
        </DeleteCell>
      </ActionCell>
      <Modal isOpen={isConfirmingDelete} onRequestClose={() => setIsConfirmingDelete(false)}>
        <form onSubmit={handleChannelDelete}>
          {errorMessage && (
            <p>
              <ErrorIcon /> <ErrorText>{errorMessage}</ErrorText>
            </p>
          )}
          <DeleteConfirmText>
            <ModalMessage>
              {`You have chosen to remove ${channel.label} from this project`}.
            </ModalMessage>
            <ModalMessage>Do you want to continue?</ModalMessage>
          </DeleteConfirmText>
          <ModalButtonsGroup>
            <PrimaryButton type="submit">Remove</PrimaryButton>
            <AnchorLink to="#" onClick={() => setIsConfirmingDelete(false)}>
              Cancel
            </AnchorLink>
          </ModalButtonsGroup>
        </form>
      </Modal>
    </TableRow>
  );
}

export default ChannelTableRow;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Heading = styled.h1`
  font-size: 32px;
`;

export const CardHeading = styled.h4`
  margin: 0px;
  display: flex;
  justify-content: space-between;
`;

export const ModalHeading = styled.h1`
  font-size: 22px;
  margin: 0 0 30px;
`;

export const ErrorText = styled.small`
  color: #d35050;
  font-weight: bold;
`;

export const SuccessText = styled.small`
  color: rgb(1, 152, 117);
  font-weight: bold;
`;

export const AnchorLink = styled(Link)`
  color: black;
`;

export const ChannelsHeading = styled.h2`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  padding: 15px;
`;

export const ChannelsNav = styled.nav`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const GroupsHeading = styled.h4`
  margin: 10px 0 30px;
  text-align: left;
  font-size: 20px;
  font-weight: normal;
`;
export const UsersListScroll = styled.div`
  min-height: 75px;
  max-height: 120px;
  margin-bottom: 30px;
  padding: 10px 0;
  border: 1px solid #c4c4c4;
  border-left: none;
  border-right: none;
  overflow: hidden;
  overflow-y: auto;

  label {
    display: flex;
    padding: 5px 0;
    align-items: center;

    input {
      margin-right: 5px;
    }
  }
  span {
    display: inline-block;
    margin-right: 5px;
  }

  small {
    display: inline-block;
    margin-left: 5px;
  }
`;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const InputLabel = styled.label`
  display: block;
  padding-bottom: 5px;
  padding-top: 10px;
`;

export const CheckLabel = styled.label`
  display: block;
`;

export const TextInput = styled.input`
  width: 100%;
  height: 25px;
  border: none;
  background: none;
  outline: none;
  border-bottom: 1px solid #c4c4c4;

  &:focus {
    border-bottom: 1px solid black;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const PrimaryButton = styled.button`
  background: #000000;
  border-radius: 10px;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  color: #f5f5f5;
  cursor: pointer;
  border: none;
  font-size: 15px;

  &:disabled {
    opacity: 65%;
    cursor: not-allowed;
  }
`;

export const Select = styled.select`
  display: block;
  border: none;
  width: 100%;
  height: 25px;
  background: none;
  outline: none;
  border-bottom: 1px solid #c4c4c4;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0 1rem;
  width: 100%;
  ${CheckLabel}:nth-child(2) {
    margin: 0 2rem;
  }
`;

export const ActionButton = styled.button`
  appearance: none;
  font-size: inherit;
  font-family: inherit;
  display: flex;
  width: 100%;
  align-items: center;
  color: rgba(255, 255, 255, 75%);
  background-color: ${(props) => (props.$isActive ? 'rgba(0, 0, 0, 0.25)' : 'transparent')};
  border: none;
  outline: none;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1);
  text-decoration: none;
  height: 68px;
  padding: 0 0 0 25px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.25), inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  }

  i {
    width: 36px;
    margin-right: 10px;
    font-size: 12px;
  }
`;

export const ModalButtonsGroup = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 30px;

  ${PrimaryButton} {
    width: 100%;
    margin-bottom: 5px;
  }
`;

export const BroadcastButtonWrap = styled.div`
  position: relative;
  display: flex;
  padding: 2px;
  height: 28px;
  background: #1a1a1a;
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  box-sizing: border-box;
  margin-left: auto;
`;

export const UnreadCountIndicator = styled.span`
  position: absolute;
  top: -3px;
  right: 0;
  display: block;
  height: 6px;
  width: 6px;
  background: red;
  border-radius: 50%;
`;

export const ChannelNameSpan = styled.span`
  flex: 1;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${UnreadCountIndicator} {
    left: 0;
  }
`;

export const ChannelIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
`;

export const ChannelIndicator = styled.span`
  display: block;
  width: 8px;
  height: 8px;
  background: radial-gradient(50% 50% at 50% 50%, #e6a1cf 37.61%, #cc3d9c 86.52%);
  border: 1px solid #660044;
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.2), 0px 0px 12px rgba(255, 51, 187, 0.75);
  border-radius: 5px;

  &.white {
    border: 1px solid #333333;
    background: radial-gradient(50% 50% at 50% 50%, #e6e6e6 37.61%, #999999 86.52%);
    box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.2), 0px 0px 12px rgba(255, 255, 255, 0.5);
  }

  &.ringing {
    opacity: 1;
    animation: flickerAnimation 1s infinite;
  }

  @keyframes flickerAnimation {
    /* flame pulses */
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const ChannelIcon = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  padding: 2px;
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  position: relative;
  background: #1a1a1a;
  box-sizing: border-box;

  span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    color: #333333;
    font-weight: bold;
    font-size: 14px;
    margin: 0;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(128, 128, 128, 0.2) 100%),
      #badcd4;
    background-blend-mode: overlay, overlay, normal;
    border-radius: 8px;
  }
`;

export const BroadcastButton = styled.div`
  display: flex;
  flex: 1;
  min-width: 0;
  align-items: center;
  justify-content: center;
  height: 24px;
  font-size: 16px;
  margin: 0 1px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(128, 128, 128, 0.2) 100%),
    #1a1a1a;
  background-blend-mode: overlay, overlay, normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5), inset 0px 2px 2px rgba(255, 255, 255, 0.25),
    inset 0px -2px 2px rgba(0, 0, 0, 0.25);
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: 3px;

  &.active,
  &:hover {
    box-shadow: inset 0px 0px 2px 1px rgb(0 0 0 / 60%), inset 0px 2px 2px rgb(255 255 255 / 20%),
      inset 0px -2px 2px rgb(0 0 0 / 20%);
  }

  > span {
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 5px;
    font-size: 12px;
  }

  ${ChannelIndicator} {
    margin-left: 5px;
  }
`;
export const BroacastMuteButtonTrigger = styled.button`
  display: block;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
`;

export const BroacastMuteButton = styled(BroadcastButton)`
  width: 28px;
  flex: 0 0 24px;
  padding: 0;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.75);
`;

export const ChannelsNavLink = styled(Link)`
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 75%);
  background-color: ${(props) => (props.$isActive ? 'rgba(0, 0, 0, 0.25)' : 'transparent')};

  box-shadow: ${(props) =>
    props.$isActive
      ? 'inset 0px 1px 0px rgba(0, 0, 0, 0.25), inset 0px -1px 0px rgba(0, 0, 0, 0.1);'
      : 'inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1);'};

  text-decoration: none;
  height: 68px;
  padding: 0 0 0 25px;

  &:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.25), inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  }

  ${ChannelIcon} {
    span {
      box-shadow: ${(props) =>
        props.$isActive
          ? 'inset 0px 0px 2px 1px rgba(0, 0, 0, 0.6), inset 0px 2px 2px rgba(255, 255, 255, 0.4), inset 0px -2px 2px rgba(0, 0, 0, 0.2);'
          : '0px 4px 4px rgba(0, 0, 0, 0.5), inset 0px 2px 2px rgba(255, 255, 255, 0.5), inset 0px -2px 2px rgba(0, 0, 0, 0.25);'};
    }
  }
`;

export const ChannelItemRight = styled.span`
  display: flex;
`;

export const ChannelItemLeft = styled.span`
  position: relative;
  flex: 1;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  font-weight: ${(props) => (props.unread ? 'bold' : '400')};
  color: ${(props) => (props.unread ? '#fff' : 'inherit')};

  .unread {
    font-weight: bold;
  }
`;

export const ChannelItemRightAction = styled.span`
  margin-left: auto;
`;
export const JoinButton = styled.button`
  font-size: 8px;
  border-radius: 8px;
  font-weight: 700;
  z-index: 5;
  border: none;
  outline: none;
`;

export const InCallBadge = styled.span`
  color: #fff;
  border-radius: 8px;
  background-color: #3a9224;
  font-size: 8px;
  font-weight: 700;
  padding: 4px 6px;
`;

export const ToggleWrapper = styled.div`
  position: relative;
`;
export const ToggleLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 24px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const Toggle = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 24px;
  &:checked + ${ToggleLabel} {
    background: #000000;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

const ToolTipText = styled('span')({
  visibility: 'hidden',
  width: '120px',
  backgroundColor: '#000',
  color: '#fff',
  textAlign: 'center',
  borderRadius: '6px',
  padding: '5px 0',
  position: 'absolute',
  zIndex: 1,
  bottom: '150%',
  left: '50%',
  marginLeft: '-60px',
  ':after': {
    content: '""',
    position: 'absolute',
    top: '100%',
    left: '50%',
    marginLeft: '-5px',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderColor: 'black transparent transparent transparent',
  },
});

const ToolTipContainer = styled('div')({
  position: 'relative',
  display: 'inline-block',
  ':hover span': {
    visibility: 'visible',
  },
});

export const Tooltip = ({ children, toolTipText }) => (
  <ToolTipContainer>
    {children}
    <ToolTipText>{toolTipText}</ToolTipText>
  </ToolTipContainer>
);

export const ChannelIndicatorIcon = styled.div``;

export const channelSelectStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled }) => {
    return {
      ...styles,
      color: data.color,
      backgroundColor: '#FFF',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    };
  },
  multiValue: (styles, { data }) => {
    const color = data.color;
    return {
      ...styles,
      backgroundColor: color,
    };
  },
  multiValueLabel: (styles, { _data }) => ({
    ...styles,
    color: '#FFF',
    cursor: 'pointer',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      color: 'white',
      cursor: 'pointer',
    },
  }),
};

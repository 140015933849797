import PubNub from 'pubnub';
import { createSlice } from '@reduxjs/toolkit';
import { BROADCAST_ACTIONS, VONAGE_INSERT_MODES } from '../calls/calls-constants';
import { v4 as uuidv4 } from 'uuid';
import backHttpClient from '../../app/backend-http-client';
import vonageClient from '../../app/vonage-client';
import { getUpdatedProjectInfo } from '../main/main-slice';
import { toast } from 'react-toastify';
import NoConnection from '../../components/NoConnection';

export const broadcastSlice = createSlice({
  name: 'broadcast',
  initialState: {
    camera: null,
    microphone: null,
    isBroadcasting: false,
    session: null,
    sessionId: '',
    subscriberToken: '',
    publisherToken: '',
    publisher: null,
    stream: null,
    subscriberStream: null,
    pubnub: null,
    listener: null,
    broadcastPerProject: {}, // stores the broadcast state project
    activeProjectId: null,
    broadcastVolume: 0,
    isMutedBroadcast: false,
    isNoConnection: false,
    isLoading: false,
  },
  reducers: {
    setBroadcastSessionCredentials: (state, action) => {
      state.apiKey = action.payload.apiKey;
      state.sessionId = action.payload.sessionId;
      state.session = action.payload.session;
    },
    setBroadcastSession: (state, action) => {
      state.session = action.payload;
    },
    setBroadcastSubscriberToken: (state, action) => {
      state.subscriberToken = action.payload;
    },
    setBroadcastPublisherToken: (state, action) => {
      state.publisherToken = action.payload;
    },
    setIsBroadcasting: (state, action) => {
      state.isBroadcasting = action.payload;
    },
    setBroadcastPublisher: (state, action) => {
      state.publisher = action.payload;
    },
    setUserMedia: (state, action) => {
      state.stream = action.payload.stream;
      state.camera = action.payload.camera;
      state.microphone = action.payload.microphone;
    },
    setSubscriberStream: (state, action) => {
      state.subscriberStream = action.payload;
    },
    setPubNub: (state, action) => {
      state.pubnub = action.payload;
    },
    setPubNubEventListener: (state, action) => {
      state.listener = action.payload;
    },

    setBroadcastPerProject: (state, action) => {
      state.broadcastPerProject = {
        ...state.broadcastPerProject,
        ...action.payload,
      };
    },

    setActiveProjectId: (state, action) => {
      state.activeProjectId = action.payload;
    },

    setBroadcastVolume: (state, action) => {
      state.broadcastVolume = action.payload;
    },

    setIsBroadcastMuted: (state, action) => {
      state.isMutedBroadcast = action.payload;
    },

    setIsNoConnection: (state, action) => {
      state.isNoConnection = action.payload;
    },

    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const registerBroadcastListener = (projectId) => async (dispatch, getState) => {
  const { uuid, userId } = getState().auth;
  let { pubnub } = getState().broadcast;

  const response = await backHttpClient.get('pubnub');
  const { publishKey, subscribeKey } = response.data;
  if (!pubnub) {
    pubnub = new PubNub({ publishKey, subscribeKey, uuid });
    dispatch(broadcastSlice.actions.setPubNub(pubnub));
  }

  const listener = {
    status: async (event) => {
      if (event.category === 'PNConnectedCategory') {
        try {
          // fetch data stored in the objects related to this project to
          // confirm the broadcast status
          const { data } = await pubnub.objects.getChannelMetadata({
            channel: `broadcast-${projectId}`,
          });

          // if we find that the channel is broadcasting, subscribe
          if (data.custom.isBroadcasting && data.custom.projectId === projectId) {
            dispatch(startBroadcastSubscriber(data.custom.projectId));
          }
        } catch (_status) {}
      }
    },
    message: async (event) => {
      if (!event.message.uid) {
        throw new Error('Missing message identifier.');
      }

      // check whether the one who sent the message is not the broadcast publisher.
      // whether the channel is not already broadcasting
      // and if the projectId inside the event is same as the currently active projectId
      const { isBroadcasting } = getState().broadcast;
      if (
        event.message.type === BROADCAST_ACTIONS.STARTED &&
        event.message.inviterId !== userId &&
        !isBroadcasting
        //  && event.message.projectId === projectId
      ) {
        // subscribe if user is not already subscribed or is not a publisher
        dispatch(startBroadcastSubscriber(projectId));
      }

      // Add a high level listener on the project level where group updates
      // among others will be communicated to users who are already logged in
      // for them to fetch the updated project data
      if (event.message.type === BROADCAST_ACTIONS.PROJECT_UPDATED) {
        dispatch(getUpdatedProjectInfo(event.message.projectId));
      }
    },
    presence: async (event) => {
      // If the user who just joined the project is not in the loaded list
      // it means they were just invited so refetch that project data again.
      if (event.action === 'join') {
        const { items: projects } = getState().projects;
        const project = projects.find((_project) => _project.id === Number(projectId));
        const connectedUsers = project.users.map((userDetails) => userDetails.uuid);

        if (!connectedUsers.includes(event.uuid)) {
          dispatch(getUpdatedProjectInfo(projectId));
        }
      }
    },
  };

  pubnub.addListener(listener);
  dispatch(broadcastSlice.actions.setPubNubEventListener(listener));
  pubnub.subscribe({ channels: [`broadcast-${projectId}`], withPresence: true });

  dispatch(broadcastSlice.actions.setActiveProjectId(projectId));
};

// start broadcast subscriber
export const startBroadcastSubscriber = (projectId) => async (dispatch, getState) => {
  // create a subscriber instance and connect to it.
  const { session } = getState().broadcast;
  if (session) {
    session.disconnect();
  }
  const response = await backHttpClient.post(`broadcast/${projectId}/subscriber`);
  const { apiKey, sessionId, token } = response.data;

  // initialize a vonage session
  const subscriberSession = vonageClient.initSession(apiKey, sessionId);
  // store the session credentials.
  dispatch(
    broadcastSlice.actions.setBroadcastSessionCredentials({
      apiKey,
      sessionId,
      session: subscriberSession,
    })
  );
  dispatch(broadcastSlice.actions.setBroadcastSubscriberToken(token));

  // add event listeners to the session
  subscriberSession.on('streamDestroyed', (event) => {
    const { session: currentSession } = getState().broadcast;
    const subscribers = subscriberSession.getSubscribersForStream(event.stream);
    subscribers.forEach((subscriber) => {
      subscriberSession.unsubscribe(subscriber);
    });
    dispatch(broadcastSlice.actions.setIsBroadcasting(false));

    currentSession.disconnect();
  });

  subscriberSession.on('sessionDisconnected', (event) => {
    if (event.reason === 'networkDisconnected') {
      dispatch(broadcastSlice.actions.setIsNoConnection(true));
      toast(<NoConnection mode="dark" isNotification={true} />);
    }
  });

  subscriberSession.on('streamCreated', (event) => {
    let alreadySubscribed = false;
    // before reconnecting check whether there's already a subscriber with same connection Id
    const subscribers = subscriberSession.getSubscribersForStream(event.stream);
    for (const subscriber of subscribers) {
      if (subscriber.stream.connection.connectionId === event.stream.connection.connectionId) {
        alreadySubscribed = true;
      }
    }
    if (!alreadySubscribed) {
      subscriberSession.subscribe(
        event.stream,
        'ot-broadcast',
        {
          insertMode: VONAGE_INSERT_MODES.APPEND,
          width: '100%',
          height: '100%',
          fitMode: 'contain',
          style: { buttonDisplayMode: 'off' },
          subscribeToVideo: true,
          subscribeToAudio: true,
          preferredResolution: { width: 1920, height: 1080 },
        },
        (error) => {
          if (error) {
            console.log(
              'sorry you could not subscribe to the stream. too bad for you. This error must be handled'
            );
          } else {
            dispatch(broadcastSlice.actions.setSubscriberStream(event.stream));
            dispatch(broadcastSlice.actions.setIsBroadcasting(true));

            const activeSubscriber = subscriberSession.getSubscribersForStream(event.stream)[0];
            const initialVolume = activeSubscriber.isAudioBlocked()
              ? 0
              : activeSubscriber.getAudioVolume();
            dispatch(broadcastSlice.actions.setBroadcastVolume(initialVolume));
          }
        }
      );
    }
  });

  // connect the subscriber session.
  try {
    await vonageClient.connectSession(subscriberSession, token);
  } catch (error) {
    console.log('error connecting to session: ', error);
  }
};

export const startBroadcastPublisher =
  (projectId, camera, microphone) => async (dispatch, getState) => {
    // check whether camera and microphone access has been granted
    if (!camera && !microphone) {
      console.error(
        'Tried to start broadcast without device, so dispatch is being ignored.',
        camera,
        microphone
      );
      return;
    }

    dispatch(broadcastSlice.actions.setIsLoading(true));

    // create publisher token
    const { session, pubnub } = getState().broadcast;
    const { userId } = getState().auth;
    // if a session exists for some reason, disconnect it.
    if (session) {
      session.disconnect();
    }

    const response = await backHttpClient.post(`broadcast/${projectId}/publisher`);
    const { apiKey, sessionId, token } = response.data;
    dispatch(broadcastSlice.actions.setBroadcastPublisherToken(token));

    // create new vonage session
    const newSession = vonageClient.initSession(apiKey, sessionId);
    dispatch(broadcastSlice.actions.setBroadcastSession(newSession));

    newSession.on('sessionDisconnected', (event) => {
      if (event.reason === 'networkDisconnected') {
        dispatch(broadcastSlice.actions.setIsNoConnection(true));
        dispatch(broadcastSlice.actions.setIsLoading(false));
        toast(<NoConnection mode="dark" isNotification={true} />);
      }
    });

    newSession.on('streamDestroyed', () => {
      dispatch(broadcastSlice.actions.setIsLoading(false));
      dispatch(broadcastSlice.actions.setIsBroadcasting(false));
    });

    // create a publisher. we publish video and audio by default
    const publisher = vonageClient.OT.initPublisher(
      'ot-broadcast',
      {
        insertMode: VONAGE_INSERT_MODES.APPEND,
        width: '100%',
        height: '100%',
        fitMode: 'contain',
        style: { buttonDisplayMode: 'off' },
        publishAudio: true,
        publishVideo: true,
        resolution: '1920x1080',
        audioFallbackEnabled: false,
      },
      (error) => {
        if (error) {
          toast.error('Error starting broadcast', {
            theme: 'light',
          });
          dispatch(broadcastSlice.actions.setIsLoading(false));
        } else {
          console.log('Publisher successfully initialized');
        }
      }
    );

    if (!publisher) {
      toast.error('Error starting broadcast', {
        theme: 'light',
      });
      return;
    }
    dispatch(broadcastSlice.actions.setBroadcastPublisher(publisher));

    publisher.on('streamDestroyed', () => {
      console.log('publisher stream destroyed');
      dispatch(broadcastSlice.actions.setIsLoading(false));
    });

    // Connect the vonage session
    let connectionSuccessful = false;
    try {
      await vonageClient.connectSession(newSession, token);
      connectionSuccessful = true;
    } catch (error) {
      console.log('error connecting to the session. please act on this error message: ', error);
    }

    if (!connectionSuccessful) {
      console.log('vonage connection failed. Simply return or handle the error properly');
      return;
    }

    // Set media source.
    // This could be handled with Promise.all or maybe not incase one fails.
    // well if any of them fails we don't proceed but throw an error for the user to start all over
    if (camera) {
      try {
        await publisher.setVideoSource(camera.deviceId);
      } catch (error) {
        dispatch(startBroadcastPublisher(projectId, camera, microphone));
        return;
      }
    }
    if (microphone) {
      await publisher.setAudioSource(microphone.deviceId);
    }

    // Add the publisher to the session.
    newSession.publish(publisher, async (error) => {
      if (error) {
        toast.error('Error starting broadcast', {
          theme: 'light',
        });
      } else {
        console.log('successfully published to the screen');
        // update the broadcasting status
        dispatch(broadcastSlice.actions.setIsLoading(false));
        dispatch(broadcastSlice.actions.setIsBroadcasting(true));

        // send a message that the broadcast has started.
        // add the broadcast status to the channel meta data

        if (pubnub) {
          pubnub.publish(
            {
              channel: `broadcast-${projectId}`,
              message: {
                uid: uuidv4(),
                inviterId: userId,
                type: BROADCAST_ACTIONS.STARTED,
                createdAt: new Date().toISOString(),
                projectId,
              },
            },
            (status) => {
              if (status.error) {
                console.error('Error on message publish', status.error);
              }
            }
          );

          try {
            await pubnub.objects.setChannelMetadata({
              channel: `broadcast-${projectId}`,
              data: {
                custom: {
                  isBroadcasting: true,
                  projectId,
                },
              },
              include: {
                customFields: true,
              },
            });
          } catch (_status) {}
        }
      }
    });
  };

export const stopBroadcastPublisher = (projectId) => async (dispatch, getState) => {
  const { broadcast } = getState();
  const { session, publisher, pubnub, subscriberStream } = broadcast;

  if (!session) {
    console.warn('Tried to stop broadcast publish but with no enough resources, ignoring it.');
    return;
  }

  if (subscriberStream) {
    dispatch(resetBroadcastSubscriber());
  }

  if (publisher) {
    publisher.publishAudio(false);
    publisher.publishVideo(false);
    session.unpublish(publisher);
  }

  session.disconnect();

  // send a pubnub event that the broadcast has ended
  if (pubnub && publisher) {
    try {
      await pubnub.objects.setChannelMetadata({
        channel: `broadcast-${projectId}`,
        data: {
          custom: {
            isBroadcasting: false,
            projectId: projectId,
          },
        },
        include: {
          customFields: true,
        },
      });
    } catch (status) {
      console.log('operation failed w/ error:', status);
    }
  }

  dispatch(broadcastSlice.actions.setIsBroadcasting(false));
  dispatch(
    broadcastSlice.actions.setBroadcastSessionCredentials({
      apiKey: null,
      sessionId: '',
      session: null,
    })
  );
  dispatch(broadcastSlice.actions.setBroadcastSubscriberToken(''));
  dispatch(broadcastSlice.actions.setBroadcastPublisher(null));
  dispatch(broadcastSlice.actions.setIsNoConnection(false));
};

export const stopBroadcastPubnubListener = () => async (_dispatch, getState) => {
  const { pubnub, listener } = getState().broadcast;
  if (pubnub) {
    pubnub.removeListener(listener);
    pubnub.unsubscribeAll();
  }
};

export const resetBroadcastSubscriber = () => (dispatch, getState) => {
  const { subscriberStream, session } = getState().broadcast;
  const subscriber = session.getSubscribersForStream(subscriberStream)[0];

  if (subscriber) {
    try {
      subscriber.subscribeToAudio(false);
      subscriber.subscribeToVideo(false);
      session.unsubscribe(subscriber);
    } catch (error) {
      console.log('error unsubscribing: ', error);
    }
  }

  dispatch(broadcastSlice.actions.setSubscriberStream(null));
  dispatch(
    broadcastSlice.actions.setBroadcastSessionCredentials({
      sessionId: '',
      session: null,
    })
  );
  dispatch(broadcastSlice.actions.setIsBroadcasting(false));
  dispatch(broadcastSlice.actions.setBroadcastSubscriberToken(''));
  dispatch(broadcastSlice.actions.setIsNoConnection(false));
};

export const setBroadcastVolume =
  (currentVolume, shouldSubscribeToAudio = true) =>
  (dispatch, getState) => {
    const { session, subscriberStream } = getState().broadcast;
    const subscriber = session.getSubscribersForStream(subscriberStream)[0];
    subscriber.subscribeToAudio(shouldSubscribeToAudio);
    subscriber.setAudioVolume(currentVolume);
    dispatch(broadcastSlice.actions.setBroadcastVolume(currentVolume));
  };

export const unblockBroadcastAudio = () => async (_dispatch, getState) => {
  const { session, subscriberStream } = getState().broadcast;
  const subscriber = session.getSubscribersForStream(subscriberStream)[0];

  if (subscriber.isAudioBlocked()) {
    await vonageClient.OT.unblockAudio();
  }
};

export const decreaseBroadcastVolume = () => async (dispatch, getState) => {
  dispatch(unblockBroadcastAudio());
  const { broadcastVolume } = getState().broadcast;
  if (broadcastVolume < 0) {
    dispatch(setBroadcastVolume(broadcastVolume - 1));
  }
};

export const increaseBroadcastVolume = () => async (dispatch, getState) => {
  dispatch(unblockBroadcastAudio());
  const { broadcastVolume } = getState().broadcast;
  if (broadcastVolume < 100) {
    dispatch(setBroadcastVolume(broadcastVolume + 1));
  }
};

export const toggleBroadcastMute = () => async (dispatch, getState) => {
  const { broadcastVolume } = getState().broadcast;
  if (broadcastVolume === 0) {
    dispatch(setBroadcastVolume(80));
  } else {
    dispatch(setBroadcastVolume(0, false));
  }
};

export const setIsBroadcastMuted = () => async (dispatch, getState) => {
  dispatch(broadcastSlice.actions.setIsBroadcastMuted(true));
};

export const setIsNoBroadcastConnection = (isNoConnection) => async (dispatch, _getState) => {
  dispatch(broadcastSlice.actions.setIsNoConnection(isNoConnection));
};

export const sendProjectUpdateNotice = (projectId) => async (_dispatch, getState) => {
  let { pubnub } = getState().broadcast;
  const { userId, uuid } = getState().auth;

  if (!pubnub) {
    const response = await backHttpClient.get('pubnub');
    const { publishKey, subscribeKey } = response.data;
    pubnub = new PubNub({ publishKey, subscribeKey, uuid });
  }

  pubnub.publish(
    {
      channel: `broadcast-${projectId}`,
      message: {
        uid: uuidv4(),
        inviterId: userId,
        type: BROADCAST_ACTIONS.PROJECT_UPDATED,
        createdAt: new Date().toISOString(),
        projectId,
      },
    },
    (status) => {
      if (status.error) {
        console.error('Error on message publish', status.error);
      }
    }
  );
};

export default broadcastSlice.reducer;

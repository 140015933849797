import { createSlice, createSelector } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import backHttpClient from '../../app/backend-http-client';
import {
  createChannelTopics,
  createGroupTopics,
  getAllPubnubMessagingChannels,
  groupParticipantByProjectProp,
} from '../../utils/helpers';
import {
  registerGroupAudioPeers,
  stopAudioPublisher,
  stopAudioPubnubListener,
} from '../audio/audio-slice';
import {
  registerBroadcastListener,
  sendProjectUpdateNotice,
  stopBroadcastPublisher,
  stopBroadcastPubnubListener,
} from '../broadcast/broadcast-slice';
import { TOPIC_CHANNEL, TOPIC_GROUP } from '../calls/calls-constants';
import { stopCall } from '../calls/calls-slice';
import { initMessagePubNub, stopMessagesPubnubListener } from '../messages/messages-slice';
import {
  registerGroupVideoPeers,
  stopGroupVideoPublisher,
  stopGroupVideoPubnubListener,
} from '../video/group-video-slice';
import { stopVideoCallPublisher, stopVideoCallPubnubListener } from '../video/video-slice';

// Keeping general states values
export const mainSlice = createSlice({
  name: 'main',
  initialState: {
    updatedProjectInfo: {},
    updatedUserInfo: {},
    projectId: null,
  },

  reducers: {
    setUpdatedProjectInfo: (state, action) => {
      state.updatedProjectInfo = action.payload;
    },
    setProjectId: (state, action) => {
      state.projectId = action.payload;
      sessionStorage.setItem('projectId', action.payload);
    },
  },
});

export const initializeAllPubnubListeners = (pubnub, project) => (dispatch, userId) => {
  // broadcast listener
  dispatch(registerBroadcastListener(project.id));
  dispatch(mainSlice.actions.setProjectId(project.id));

  const channelTopics = createChannelTopics(project, userId);
  const groupTopics = createGroupTopics(project, userId);

  const allMessageChannels = getAllPubnubMessagingChannels(project, userId);
  // Initialize message listeners for all the channels, groups and users.

  dispatch(initMessagePubNub(pubnub, allMessageChannels));

  // Initialize Group/Channel Call Video Peers
  const groupChannelTopics = groupParticipantByProjectProp(project, 'channels');
  const groupMembers = groupParticipantByProjectProp(project, 'groups');
  dispatch(
    registerGroupVideoPeers([...channelTopics, ...groupTopics], {
      [TOPIC_CHANNEL]: groupChannelTopics,
      [TOPIC_GROUP]: groupMembers,
    })
  );
  // Initialize Group/Channel for Audio Calls
  dispatch(registerGroupAudioPeers(channelTopics));
};

export const stopAllPubnubListeners = () => (dispatch) => {
  dispatch(stopAudioPubnubListener());
  dispatch(stopMessagesPubnubListener());
  dispatch(stopBroadcastPubnubListener());
  dispatch(stopGroupVideoPubnubListener());
  dispatch(stopVideoCallPubnubListener());
};

export const stopAllPublishers = () => (dispatch) => {
  dispatch(stopCall());
  dispatch(stopAudioPublisher());
  dispatch(stopBroadcastPublisher());
  dispatch(stopGroupVideoPublisher());
  dispatch(stopVideoCallPublisher());
};

export const getUpdatedProjectInfo = (projectId) => async (dispatch) => {
  const updateProjectInfo = await backHttpClient.get(`/projects/${projectId}`);
  dispatch(mainSlice.actions.setUpdatedProjectInfo(updateProjectInfo.data.item));
};

export const resetUpdatedProjectInfo = () => (dispatch) => {
  dispatch(mainSlice.actions.setUpdatedProjectInfo({}));
};

export const createAdhocGroup = (projectId, payload) => async (dispatch) => {
  try {
    await backHttpClient.patch(`/projects/${projectId}/group`, payload);
    dispatch(getUpdatedProjectInfo(projectId));
    dispatch(sendProjectUpdateNotice(projectId));
  } catch (_error) {
    toast.error("There's been an error while trying to create a new ad-hoc group.");
  }
};

export const updateAdhocGroupName =
  (projectId, payload, onSuccess, onError) => async (dispatch) => {
    try {
      await backHttpClient.patch(`/projects/${projectId}/group`, payload);
      dispatch(getUpdatedProjectInfo(projectId));
      dispatch(sendProjectUpdateNotice(projectId));
      onSuccess();
    } catch (_error) {
      onError();
      toast.error("There's been an error while trying to update the ad-hoc group name.");
    }
  };

export const selectIsSpeakerActive = createSelector(
  (state) => state,
  (state) => {
    return (
      !!state.calls.session ||
      (state.audio.isInAudioRoom && !state.audio.isAudioMuted) ||
      (!state.audio.isInAudioRoom && !state.groupVideoCall.isAudioMuted)
    );
  }
);

export default mainSlice.reducer;

import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SpeakerBg from '../images/speaker-bg.png';
import SpeakerOverlay from '../images/speaker-overlay.png';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';

function Speaker() {
  const [audioLevelByStreamIds, setAudioLevelByStreamIds] = useState({});
  const { session, subscriberStream } = useSelector((state) => state.audio);
  const subscribers = Object.values(subscriberStream).map(
    (userStream) => session?.getSubscribersForStream(userStream)[0]
  );
  // When a subscriber leaves, we get undefined on session?.getSubscribersForStream(userStream)[0]
  // so we filter out all undefined values
  const activeSubscribers = subscribers.filter((sub) => sub);
  const activeSubsIds = activeSubscribers.map((subsInstance) => subsInstance.streamId);

  const subsAudioLevelUpdateRef = useRef(null);
  const subsAudioLevelUpdateFn = (audioEvent, subscriber) => {
    setAudioLevelByStreamIds((audioByStreamIds) => ({
      ...audioByStreamIds,
      [subscriber.streamId]: subscriber.stream.hasAudio ? audioEvent.audioLevel : 0,
    }));
  };
  subsAudioLevelUpdateRef.current = debounce(subsAudioLevelUpdateFn, 500, {
    leading: true,
    trailing: true,
  });

  useEffect(() => {
    activeSubscribers.forEach((subscriber) => {
      subscriber?.on('audioLevelUpdated', (audioEvent) =>
        subsAudioLevelUpdateRef.current(audioEvent, subscriber)
      );
    });

    return () => {
      subsAudioLevelUpdateRef.current?.cancel();
      activeSubscribers.forEach((subscriber) => {
        subscriber?.off('audioLevelUpdated');
      });
    };
  }, [activeSubscribers]);

  const isSpeakerActive = () => {
    const activeSpeakerIds = [];
    for (const [audioStreamId, audioLevel] of Object.entries(audioLevelByStreamIds)) {
      if (activeSubsIds.includes(audioStreamId) && audioLevel > 0) {
        activeSpeakerIds.push(audioStreamId);
      }
    }
    return activeSpeakerIds.length;
  };

  return (
    <Container>
      <div className="wrap">
        <div className="wrap-overlay"></div>
        <div className="speaker-anim-wrap">
          <div className={`speaker-anim  ${isSpeakerActive() && 'active'}`}></div>
        </div>
      </div>
    </Container>
  );
}

export default Speaker;

export const Container = styled.div`
  height: 262px;
  display: flex;
  align-items: center;
  .wrap {
    position: relative;
    width: 220px;
    height: 220px;
    background-image: url(${SpeakerBg});
    margin: 0 auto;
    background-size: cover;
  }

  .speaker-anim-wrap {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 220px;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .speaker-anim {
    background: rgba(255, 2555, 2555, 0.15);
    width: 110px;
    height: 110px;
    border-radius: 110px;
    box-shadow: 0px 0 30px rgba(255, 2555, 2555, 0.5);
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .active {
    width: 20px;
    height: 20px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    animation-duration: 1s;
    animation-name: speakerAmin;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  .wrap-overlay {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 220px;
    height: 220px;
    background-image: url(${SpeakerOverlay});
    margin: 0 auto;
    background-size: cover;
  }

  @keyframes speakerAmin {
    0 {
      width: 0;
      height: 0;
    }
    20% {
      width: 200px;
      height: 200px;
    }
    40% {
      width: 20px;
      height: 20px;
    }
    80% {
      width: 110px;
      height: 110px;
    }
    100% {
      width: 10px;
      height: 10px;
    }
  }
`;

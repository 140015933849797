import { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import backHttpClient from '../../app/backend-http-client';
import { PrimaryButton, InputLabel, TextInput } from '../ui/inputs';
import { ErrorText } from '../ui/texts';
import { ErrorIcon } from '../../features/ui/iconography.js';
import getPasswordValidationErrors from './getPasswordValidationErrors';
import OffsetBlackLogo from '../../images/offset_black_logo.svg';
import { toast } from 'react-toastify';

export default function ResetOrConfirmPage() {
  const location = useLocation();
  const tokenUid = location.search.split('?t=')[1] || 'none';

  const [password, setPassword] = useState('');
  const handlePasswordChange = (event) => setPassword(event.target.value);
  const passwordErrors = getPasswordValidationErrors(password);

  const [passwordConfirmed, setPasswordConfirmed] = useState('');
  const handlePasswordConfirmedChange = (event) => setPasswordConfirmed(event.target.value);

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (passwordErrors.length > 0) {
      toast.error('There are pending recommended security issues on password.', {
        theme: 'light',
      });
      setPasswordConfirmed('');
      return;
    }

    if (password !== passwordConfirmed) {
      toast.error('Not done! Passwords must match, for your own safety.', {
        theme: 'light',
      });
      setPasswordConfirmed('');
      return;
    }

    setLoading(true);
    setErrorMessage('');

    try {
      const payload = {
        confirmationTokenUid: tokenUid,
        password: password,
      };
      await backHttpClient.patch('/auth/reset-password', payload);
      toast.success('Password saved! Redirecting to login page.', {
        theme: 'light',
      });
      history.push('/login');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Unknown error while reseting password, please contact support team.');
        console.error('Error on reset password.', error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Main>
      <img src={OffsetBlackLogo} height="52" alt="Offset" />
      <ConfirmForm onSubmit={handleSubmit}>
        <header>
          <h2>Setting password</h2>
        </header>
        {errorMessage && (
          <p>
            <ErrorIcon /> <ErrorText>{errorMessage}</ErrorText>
          </p>
        )}
        <InputLabel>
          <span>New password:</span>
          <TextInput
            value={password}
            onChange={handlePasswordChange}
            type="password"
            disabled={isLoading}
            autoComplete="off"
            minLength="8"
            maxLength="50"
            required
          />
        </InputLabel>
        {password && passwordErrors.length > 0 && (
          <ul>
            {passwordErrors.map((error) => (
              <li key={error}>
                <ErrorText>{error}</ErrorText>
              </li>
            ))}
          </ul>
        )}
        <InputLabel>
          <span>Confirm new password:</span>
          <TextInput
            value={passwordConfirmed}
            onChange={handlePasswordConfirmedChange}
            type="password"
            disabled={isLoading}
            autoComplete="off"
            minLength="8"
            maxLength="50"
            required
          />
        </InputLabel>
        {isLoading && <p>Saving...</p>}
        <PrimaryButton type="submit" disabled={isLoading}>
          Save
        </PrimaryButton>
      </ConfirmForm>
    </Main>
  );
}

const Main = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 30px;
  }
`;

const ConfirmForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 580px;
  box-sizing: border-box;
  padding: 30px;
  background: #ffffff;
  border-radius: 6px;

  ${InputLabel} {
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 40px;

    input {
      font-size: 16px;
      box-sizing: border-box;
    }
  }

  ${PrimaryButton} {
    width: 100%;
  }
`;

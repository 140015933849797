import { useState } from 'react';
import Popover from 'react-popover';
import {
  StylingSidebarHeader,
  ProjectBrand,
  ProjectTitle,
  ProjectSettingsIcon,
  MainSidebarBranding,
} from './styles';

import MainOptionsBody from './MainOptionsBody';
import { BroadcastControls } from '../BroadcastControls';

import UserProfileForm from '../../ui/forms/UserProfileForm';
import { SideDrawer } from '../../ui/drawer';

function SideBarControls({
  project,
  children,
  canBroadcast,
  closeMainOptions,
  toggleMainOptions,
  isMainOptionsOpen,
}) {
  const [isProfileFormOpen, setIsProfileFormOpen] = useState(false);
  return (
    <>
      <StylingSidebarHeader>
        <Popover
          className="tip-right"
          place="below"
          isOpen={isMainOptionsOpen}
          onOuterAction={closeMainOptions}
          body={
            <MainOptionsBody
              close={closeMainOptions}
              id={project && project.id}
              openProfileForm={() => setIsProfileFormOpen(true)}
            />
          }
        >
          <MainSidebarBranding>
            <ProjectBrand />
            <ProjectTitle>
              <strong>{project.name}</strong> <br />
              {project.companyName || 'Offset Productions'}
            </ProjectTitle>
            <BroadcastControls project={project} canBroadcast={canBroadcast} />
            <ProjectSettingsIcon role="button" onClick={toggleMainOptions}>
              <i className="fa fa-cog"></i>
            </ProjectSettingsIcon>
          </MainSidebarBranding>
        </Popover>
        {children}
      </StylingSidebarHeader>
      {/* UserProfileDrawer */}
      <SideDrawer
        open={isProfileFormOpen}
        placement="right"
        width={600}
        onClose={() => setIsProfileFormOpen(false)}
      >
        {isProfileFormOpen && (
          <UserProfileForm project={project} close={() => setIsProfileFormOpen(false)} />
        )}
      </SideDrawer>
    </>
  );
}

export default SideBarControls;

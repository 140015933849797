import styled from 'styled-components';
import { MessageContent, MessageTime } from './MessagesList';
import DocumentIcon from '../../images/DocumentIcon.svg';
import AttachmentDownloadButton from './AttachmentDownloadButton';

function AttachmentsList({ messages = [], users }) {
  return messages.length ? (
    messages.map((message) => {
      const user = users.find((u) => u.id === message.authorId);
      return (
        <AttachmentWrapper key={message.uid}>
          <AttachmentUrl>
            <AttachmentPreviewLink>
              <AttachmentPreview
                src={
                  message.attachment?.type?.includes('image')
                    ? message.attachment.url
                    : DocumentIcon
                }
                alt={message.attachment.name}
              />
            </AttachmentPreviewLink>

            <MessageContent>
              <AttachmentNameWrap>
                <AttachmentName>{message.attachment.name}</AttachmentName>{' '}
                <MessageTime>
                  {message.createdAt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </MessageTime>
              </AttachmentNameWrap>
              <MessageText>By {user.name}</MessageText>
            </MessageContent>
          </AttachmentUrl>
          <AttachmentDownloadButton
            attachmentUrl={message.attachment.url}
            attachmentName={message.attachment.name}
          />
        </AttachmentWrapper>
      );
    })
  ) : (
    <p style={{ margin: '2rem auto' }}>No Attachments Found</p>
  );
}

export default AttachmentsList;

const AttachmentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AttachmentNameWrap = styled.div`
  display: flex;
  align-items: center;

  time {
    margin-left: 8px;
  }
`;

export const AttachmentName = styled.strong`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
`;

export const AttachmentUrl = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  margin-right: auto;
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  img {
    flex-shrink: 0;
  }

  }
`;

export const MessageText = styled.span`
  display: block;
  color: rgba(255, 255, 255, 0.75);
`;

export const AttachmentPreview = styled.img`
  width: 70px;
  height: auto;
  border-radius: 4px;
  margin-top: 2px;
  opacity: 0.9;
`;

export const AttachmentPreviewLink = styled.div`
  cursor: pointer;
  &:hover {
    ${AttachmentPreview} {
      opacity: 1;
      box-shadow: 0 0 15px rgba(0, 0, 0, 35%);
    }
  }
`;

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { TOPIC_CHANNEL } from '../calls/calls-constants';
import { SideBarMessage } from './project-main-sidebar/styles';
import {
  ChannelsNavLink,
  ChannelItemLeft,
  ChannelItemRight,
  ChannelIndicatorWrapper,
  ChannelIndicator,
  InputLabel,
  TextInput,
  ChannelIcon,
} from '../ui/inputs';
import { Modal } from '../ui/modal';
import { AnchorLink } from '../ui/texts';
import useChannelCallHelpers from '../../hooks/useChannelCallHelpers.js';
import { makeTopicIdForChannels } from '../messages/selected-chat-utils';

// unreadMessagesCount
export function Channels({
  activateTopicFn,
  isItemActive,
  project,
  unreadMessagesCount,
  openFileSendingPrompt,
}) {
  const { userId } = useSelector((state) => state.auth);
  const { updatedProjectInfo } = useSelector((state) => state.main);
  const combinedProject = { ...project, ...updatedProjectInfo };
  const user = combinedProject.users?.find((thisUser) => thisUser.id === userId);
  const { audioCallChannels, isInAudioRoom } = useSelector((state) => state.audio);

  const { groupCallState } = useSelector((state) => state.groupVideoCall);
  const getVideoCallParticipantsCount = (channelId) => {
    return groupCallState[
      `video-call-${TOPIC_CHANNEL}-${makeTopicIdForChannels(project.id, channelId)}`
    ]?.callParticipants;
  };

  // channels
  const channels = (user && user.Channels) || [];

  const participatingChannels = channels.filter((c) => c.projectId === project.id);

  const [isChannelAddOpen, setIsChannelAddOpen] = useState(false);
  const closeChannelAdd = () => setIsChannelAddOpen(false);

  const [channelFilter, setChannelFilter] = useState('');
  const handleChannelFilterChange = (event) => setChannelFilter(event.target.value.trim());
  const filteredChannels = channels.filter((it) =>
    it.name.toLowerCase().includes(channelFilter.toLowerCase())
  );

  const { handleChannelClick, handleChannelSelectionFn } = useChannelCallHelpers({
    projectId: project.id,
    activateTopicFn,
    isChannelAddOpen,
    closeChannelAdd,
    openFileSendingPrompt,
  });

  return (
    <>
      <AddChannelModal
        isChannelAddOpen={isChannelAddOpen}
        closeChannelAdd={closeChannelAdd}
        channelFilter={channelFilter}
        handleChannelFilterChange={handleChannelFilterChange}
        filteredChannels={filteredChannels}
        handleChannelSelectionFn={handleChannelSelectionFn}
      />
      <>
        {participatingChannels.length === 0 && (
          <SideBarMessage>Not in any channels.</SideBarMessage>
        )}
        {participatingChannels.map((channel, _index) => (
          <ChannelsNavLink
            key={channel.id}
            to="#"
            onClick={(event) => handleChannelClick(event, channel.id)}
            $isActive={isItemActive(TOPIC_CHANNEL, channel.id)}
          >
            <ChannelIcon>
              <span>CH</span>
            </ChannelIcon>

            <ChannelItemLeft
              unread={
                unreadMessagesCount &&
                unreadMessagesCount[
                  `${TOPIC_CHANNEL}-${makeTopicIdForChannels(project.id, channel.id)}`
                ] > 0
              }
            >
              {channel.name.toLowerCase().replace(' ', '_')}
            </ChannelItemLeft>

            <ChannelItemRight>
              {/* Ongoing group call indicator */}
              {/* Show indicator when there is an ongoing call in that channel and the participants are greater than 0 */}
              {/* Don't show the indicator for me when I'm the only one but show it to the other participants in the channel */}
              <ChannelIndicatorWrapper>
                {(audioCallChannels[channel.id]?.callParticipants > 0 &&
                  !(audioCallChannels[channel.id]?.callParticipants === 1 && isInAudioRoom)) ||
                  (getVideoCallParticipantsCount(channel.id) > 0 && <ChannelIndicator />)}
              </ChannelIndicatorWrapper>
            </ChannelItemRight>
          </ChannelsNavLink>
        ))}
      </>
    </>
  );
}

function AddChannelModal({
  isChannelAddOpen,
  closeChannelAdd,
  channelFilter,
  handleChannelFilterChange,
  filteredChannels,
  handleChannelSelectionFn,
}) {
  return (
    <Modal isOpen={isChannelAddOpen} onRequestClose={closeChannelAdd}>
      <InputLabel>
        <span>Channel:</span>
        <TextInput
          type="search"
          value={channelFilter}
          onChange={handleChannelFilterChange}
          placeholder="Type to filter search..."
          autoComplete="off"
        />
      </InputLabel>
      {filteredChannels.length > 0 ? (
        <p>Click to select the channel to join:</p>
      ) : (
        <p>No channels found by this criteria.</p>
      )}
      <ul style={{ listStyle: 'none' }}>
        {filteredChannels.map((channel) => (
          <li key={channel.id}>
            <AnchorLink to="#" onClick={handleChannelSelectionFn(channel)}>
              #{channel.name.toLowerCase().replace(' ', '_')}
            </AnchorLink>{' '}
            {
              <small>
                <em>(Participating)</em>
              </small>
            }
          </li>
        ))}
      </ul>
    </Modal>
  );
}

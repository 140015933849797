import styled from 'styled-components';
import OffsetLogoPicture from '../../../images/offset_logo_picture_gold.svg';
import NoiseBgHelper from '../../../images/noise_bg_helper.png';
import SpeakerBgHelper from '../../../images/speaker_bg_helper.png';
import { PrimaryButton } from '../../ui/inputs';

export const DeclineButton = styled(PrimaryButton)`
  flex: 1;
  background: #ff2222;
  margin: 0 10px;
`;

export const AcceptButton = styled(PrimaryButton)`
  flex: 1;
  background: #319730;
  margin: 0 10px;
`;

export const IncomingCallModalRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const IncomingCallModalMessage = styled.p`
  text-align: center;
  font-size: 18px;
  margin: 10px 0 0;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
`;

export const IncomingCallModalActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
`;

export const VideoCallActionCircleButton = styled.button`
  display: block;
  width: 40px;
  height: 40px;
  font-size: 16px;
  background: #333333;
  color: #fff;
  border: none;
  cursor: ${(props) => (!props.disabled ? 'pointer' : 'inherit')};
  text-align: center;
  border-radius: 100%;

  &:hover {
    i {
      color: ${(props) => (!props.disabled ? '#fff' : 'rgba(255, 255, 255, 0.5)')};
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.active {
    color: #fff;
  }

  i {
    color: rgba(255, 255, 255, 0.8);
  }
`;

export const VideoCallActionSquareButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  display: block;
  flex: 0 0 100%;
  max-width: 52px;
  height: 52px;
  color: #333333;
  font-weight: bold;
  font-size: 16px;
  margin: 0 1px;
  cursor: ${(props) => (!props.disabled ? 'pointer' : 'inherit')};
  border: none;
  outline: none;
  text-align: center;
  border-radius: 6px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(128, 128, 128, 0.2) 100%),
    #badcd4;
  background-blend-mode: overlay, overlay, normal;

  box-shadow: ${(props) =>
    props.$isActive
      ? 'inset 0px 0px 2px 1px rgba(0, 0, 0, 0.6), inset 0px 2px 2px rgba(255, 255, 255, 0.4), inset 0px -2px 2px rgba(0, 0, 0, 0.2);'
      : '0px 4px 4px rgba(0, 0, 0, 0.5), inset 0px 2px 2px rgba(255, 255, 255, 0.5), inset 0px -2px 2px rgba(0, 0, 0, 0.25);'};

  border-radius: 8px;

  &:hover,
  &.active {
    box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.6), inset 0px 2px 2px rgba(255, 255, 255, 0.4),
      inset 0px -2px 2px rgba(0, 0, 0, 0.2);
  }

  &.orange {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(128, 128, 128, 0.2) 100%),
      #ecb875;
  }

  &.yellow {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(128, 128, 128, 0.2) 100%),
      #f3dc99;
  }

  &.green {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(128, 128, 128, 0.2) 100%),
      #b7cd88;
  }

  &.red {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(128, 128, 128, 0.2) 100%),
      #d15858;
  }

  i {
    color: #333333;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.on:after {
    display: block;
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    margin-left: -4px;
    width: 8px;
    height: 8px;
    background: radial-gradient(50% 50% at 50% 50%, #e6a1cf 37.61%, #cc3d9c 86.52%);
    border: 1px solid #660044;
    box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.2), 0px 0px 12px rgba(255, 51, 187, 0.75);
    border-radius: 5px;
  }
`;

export const VideoCallActionSelector = styled.div`
  flex: 1;
  min-width: 0;
`;

export const ConfigButtonWrap = styled.div`
  position: absolute;
  bottom: 15px;
  left: 15px;
  z-index: 1;
  display: flex;
`;

export const ConfigButtonShell = styled.div`
  margin-right: 10px;
  padding: 2px;
  box-shadow: 0px 1px 0px rgb(255 255 255 / 20%);
  border-radius: 10px;
  position: relative;
  background: #1a1a1a;
  box-sizing: border-box;
`;

export const ConfigButton = styled(VideoCallActionSquareButton)`
  display: flex;
  flex: 0 0 36px;
  width: 36px;
  height: 36px;
  margin: 0;

  img {
    width: 16px;
  }
`;

export const GroupName = styled.div`
  display: flex;
  flex: 1;
  min-width: 0;
  align-items: center;
  justify-content: center;
  height: 52px;
  font-size: 16px;
  margin: 0 1px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(128, 128, 128, 0.2) 100%),
    #1a1a1a;
  background-blend-mode: overlay, overlay, normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5), inset 0px 2px 2px rgba(255, 255, 255, 0.5),
    inset 0px -2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 5px;
    cursor: pointer;
  }
`;

export const MinimizedCallActions = styled.div`
  position: relative;
  display: flex;
  padding: 2px;
  flex: 1;
  width: 100%;
  height: 56px;
  margin-top: 28px;
  background: #1a1a1a;
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
`;

export const RemoteVideo = styled.div`
  z-index: -1;
  width: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  aspect-ratio: 4 / 3;
  overflow: hidden;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  #remote-video-stage {
    position: relative;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
  }
`;

export const LocalVideo = styled.div`
  width: 160px;
  aspect-ratio: 4 / 3;
  position: absolute;
  bottom: 20px;
  right: 20px;

  #local-video-minimized {
    width: 100px;
  }

  .OT_widget-container {
    border-radius: 4px;
  }
`;

export const MiniVideoCall = styled.div`
  display: ${(props) => (props.hidden ? 'none' : 'inherit')};
  height: ${(props) => (props.hidden ? '0px' : '100%')};
  width: 100%;
  background: #000000;
  padding: 2px;
  box-sizing: border-box;

  #remote-video-minimized {
    width: 100% !important;
    height: 100% !important;
  }
  #local-video-minimized {
    top: 60px;
    left: 20px;
  }
  .call-modal-header {
    z-index: 10;
    position: absolute;
    top: 0px;
    width: 100%;
    padding: 10px 5px;
    background: #00000033;
    box-sizing: border-box;
    left: 0px;

    p {
      color: #ffffff;
    }
  }
  .call-controls {
    position: absolute;
    z-index: 10;
    left: 0px;
    bottom: 0px;
    width: 100%;
    padding: 5px 5px;
    background: #00000033;
    box-sizing: border-box;
    z-index: 2;
  }
`;

export const NoiseBgWrapHelper = styled.div`
  background-image: url(${NoiseBgHelper});
  background-color: #333333;
  background-blend-mode: overlay;
  box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(255, 255, 255, 0.5),
    inset 0px 1.5px 0.5px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: rgba(255, 255, 255, 75%);
`;

export const StylingSidebarHeader = styled.div`
  background-image: url(${NoiseBgHelper});
  background-color: #333333;
  background-blend-mode: overlay;
  box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(255, 255, 255, 0.5),
    inset 0px 1.5px 0.5px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: rgba(255, 255, 255, 75%);

  height: 64px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 15px;
`;

export const ProjectBrand = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: none;
  background-image: url(${OffsetLogoPicture});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const ProjectTitle = styled.h1`
  margin: 0 auto 0 0;
  font-weight: normal;
  font-size: 16px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
`;

export const ProjectOptionsIcon = styled.i`
  display: block;
`;

export const ProjectSettingsIcon = styled.div`
  display: flex;
  padding: 2px;
  width: 28px;
  height: 28px;
  background: #1a1a1a;
  box-shadow: 0px 1px 0px rgb(255 255 255 / 20%);
  border-radius: 5px;
  box-sizing: border-box;
  margin-left: 5px;

  i {
    display: flex;
    flex: 1;
    min-width: 0;
    align-items: center;
    justify-content: center;
    height: 24px;
    font-size: 12px;
    margin: 0 1px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(128, 128, 128, 0.2) 100%),
      #1a1a1a;
    background-blend-mode: overlay, overlay, normal;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5), inset 0px 2px 2px rgba(255, 255, 255, 0.25),
      inset 0px -2px 2px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    border-radius: 3px;

    &:hover {
      box-shadow: inset 0px 0px 2px 1px rgb(0 0 0 / 60%), inset 0px 2px 2px rgb(255 255 255 / 20%),
        inset 0px -2px 2px rgb(0 0 0 / 20%);
    }
  }
`;

export const MainSidebarBranding = styled.header`
  padding: 0 15px;
  display: flex;
  align-items: center;
  color: #aeaeae;
  flex: 1;

  ${ProjectBrand} {
    margin-right: 10px;
  }
`;

export const MobileTriggerIcon = styled.div`
  font-size: 20px;
`;

export const ActionButtonGroup = styled.div`
  display: flex;
  padding: 0;
  margin-left: auto;
  color: #fff;
  border: none;
  background: none;
`;

export const ActionCircleButton = styled.button`
  display: block;
  margin-left: auto;
  width: 40px;
  height: 40px;
  font-size: 16px;
  background: #333333;
  color: #fff;
  border: none;
  cursor: ${(props) => (!props.disabled ? 'pointer' : 'inherit')};
  text-align: center;

  &:first-child {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    padding-left: 12px;
  }

  &:last-child {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    padding-right: 12px;
  }

  i {
    color: rgba(255, 255, 255, 0.5);
  }

  &:hover {
    i {
      color: ${(props) => (!props.disabled ? '#fff' : 'rgba(255, 255, 255, 0.5)')};
    }
  }

  .active {
    color: #fff;
  }
`;

export const SidebarBottom = styled.div`
  position: relative;
  min-height: 362px;
  background-image: url(${NoiseBgHelper});
  background-color: #333333;
  background-blend-mode: overlay;
  box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(255, 255, 255, 0.5),
    inset 0px 1.5px 0.5px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: rgba(255, 255, 255, 75%);
  margin-bottom: 15px;
  align-items: center;
  box-sizing: border-box;
  padding: 8px;
`;

export const Speaker = styled.div`
  height: 262px;
  background-image: url(${SpeakerBgHelper});
  background-position: center 35px;
  background-repeat: no-repeat;
`;

export const SideBarMessage = styled.p`
  display: flex;
  margin: 0;
  align-items: center;
  color: rgba(255, 255, 255, 75%);
  background-color: ${(props) => (props.$isActive ? 'rgba(0, 0, 0, 0.25)' : 'transparent')};
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1);
  text-decoration: none;
  height: 68px;
  padding: 0 0 0 25px;
`;

export const StylingMainSidebar = styled.div`
  box-sizing: border-box;
  position: relative;
  // background: #000000;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  max-width: 380px;
  padding: 15px;
  background: #f5ebc0;
  box-shadow: inset 0px 2px 1px rgba(255, 255, 255, 0.5), inset 0px -2px 1px rgba(0, 0, 0, 0.1);
`;

export const StylingMainSidebarInner = styled.div`
  position: relative;
  background-image: url(${NoiseBgHelper});
  background-color: #333333;
  background-blend-mode: overlay;
  box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(255, 255, 255, 0.5),
    inset 0px 1.5px 0.5px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: rgba(255, 255, 255, 75%);
  flex: 1;
`;

export const ScrollableMenuBody = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 68px);
  flex-direction: column;
  overflow-y: auto;
  margin: 0;
`;

export const StylingMainOptionsBody = styled.div`
  width: 320px;
  margin: 0 auto;
`;

export const ModalMessage = styled.p`
  margin: 10px 0 30px;
  text-align: left;
  font-size: 20px;
  font-weight: normal;
`;

export const MainOptionsItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 15px 10px;

  &:hover {
    background-color: rgba(10, 10, 10, 0.1);
  }
`;

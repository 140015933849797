import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'rc-slider';
import styled from 'styled-components';
import { RangeSlider, SliderControlButton } from './styles';
import vonageClient from '../../app/vonage-client';
import {
  BroadcastButtonWrap,
  BroadcastButton,
  BroacastMuteButtonTrigger,
  BroacastMuteButton,
} from '../ui/inputs';
import { setBroadcastVolume, toggleBroadcastMute } from './broadcast-slice';

function BroadcastVolumeControl() {
  const dispatch = useDispatch();
  const {
    session,
    subscriberStream,
    broadcastVolume: initialVolume,
  } = useSelector((state) => state.broadcast);

  const subscriber = session.getSubscribersForStream(subscriberStream)[0];
  const [broadcastVolume, setLocalBroadcastVolume] = useState(initialVolume);

  const unblockAudio = async () => {
    if (subscriber.isAudioBlocked()) {
      await vonageClient.OT.unblockAudio();
    }
  };

  const onAfterChange = (currentVolume) => {
    if (currentVolume === 0) {
      dispatch(setBroadcastVolume(currentVolume, false));
    }
    if (initialVolume === 0 && currentVolume > 0) {
      dispatch(setBroadcastVolume(currentVolume));
    }
  };

  const decreaseVolume = () => {
    const newVolume = broadcastVolume - 1;
    unblockAudio();
    subscriber.subscribeToAudio(true);
    if (broadcastVolume > 0) {
      subscriber.setAudioVolume(newVolume);
      setLocalBroadcastVolume(newVolume);
    }
    onAfterChange(newVolume);
  };
  const increaseVolume = () => {
    const newVolume = broadcastVolume + 1;
    unblockAudio();
    subscriber.subscribeToAudio(true);
    if (broadcastVolume < 100) {
      subscriber.setAudioVolume(newVolume);
      setLocalBroadcastVolume(newVolume);
    }

    onAfterChange(newVolume);
  };

  const handleVolumeControl = (currentVolume) => {
    unblockAudio();
    subscriber.subscribeToAudio(true);
    subscriber.setAudioVolume(currentVolume);
    setLocalBroadcastVolume(currentVolume);
  };

  return (
    <StyledContainer>
      <StyledBroadcastControls>
        <BroadcastButtonWrap>
          <BroadcastButton as="div" className="active">
            <span>On Set</span>
          </BroadcastButton>
          <BroacastMuteButtonTrigger onClick={() => dispatch(toggleBroadcastMute())}>
            <BroacastMuteButton className={broadcastVolume === 0 && 'active'}>
              {broadcastVolume === 0 ? (
                <i className="fa fa-volume-mute"></i>
              ) : (
                <i className="fa fa-volume-up" />
              )}
            </BroacastMuteButton>
          </BroacastMuteButtonTrigger>
        </BroadcastButtonWrap>
        <StyledSliderWrapper>
          <span className="tip"></span>
          <SliderControlButton onClick={decreaseVolume}>-</SliderControlButton>

          <Slider
            draggableTrack
            min={0}
            step={1}
            max={100}
            value={broadcastVolume}
            onChange={handleVolumeControl}
            onAfterChange={onAfterChange}
          />
          <SliderControlButton onClick={increaseVolume}>+</SliderControlButton>
        </StyledSliderWrapper>
      </StyledBroadcastControls>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  height: 60px;
  overflow: visible;
  z-index: 2;
`;

const StyledSliderWrapper = styled(RangeSlider)`
  position: relative;
  padding: 15px 10px;
  margin-top: -2px;
  color: #ffffff;
  background: #3d3d3d;
  width: 230px;
  border-radius: 4px;
  position: absolute;
  top: 100%;
  right: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 10;

  span.tip {
    position: absolute;
    right: 13px;
    top: -7px;
    display: inline-block;
    height: 14.64px;
    width: 14.64px;
    background: inherit;
    transform: rotate(45deg);
  }
`;

const StyledBroadcastControls = styled.div`
  height: 60px;
  display: flex;
  align-items: center;

  &:hover {
    ${StyledSliderWrapper} {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export default BroadcastVolumeControl;

import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { projectsActions } from '../projects-duck';
import { CardHeading, ErrorText, AnchorLink } from '../../ui/texts';
import { InputLabel, TextInput, PrimaryButton } from '../../ui/inputs';
import { ErrorIcon } from '../../ui/iconography';
import {
  StyledAddChannel,
  NewChannelForm,
  LabelColorField,
  ChannelLabel,
  LabelColorInput,
  ModalButtonsGroup,
} from './styles';
import { sendProjectUpdateNotice } from '../../broadcast/broadcast-slice';

function AddEditChannel({ projectId, onClose, channelAction, channelToEdit, ...props }) {
  const dispatch = useDispatch();
  const labelColorRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [labelColor, setLabelColor] = useState(
    channelAction === 'edit' && channelToEdit ? channelToEdit.color : '#8c8c8c'
  );
  const [channelName, setChannelName] = useState(
    channelAction === 'edit' && channelToEdit ? channelToEdit.label : ''
  );
  const { name: creator } = useSelector((state) => state.auth);

  const handleSubmit = async (e) => {
    if (isLoading) return;
    setIsLoading(true);
    e.preventDefault();
    if (!channelName || !labelColor) {
      console.error(`channelName and labelColor Required`);
      setIsLoading(false);
      return;
    }
    switch (channelAction) {
      case 'add':
        await handleChannelAdd();
        break;
      case 'edit':
        await handleChannelEdit();
        break;
    }
  };
  async function handleChannelAdd() {
    const newChannel = {
      name: channelName.trim(),
      label: channelName.trim(),
      color: labelColor,
      type: 'add-channel',
      creator,
    };

    const onSuccess = () => {
      setTimeout(cleanUpAndClose, 1000);
      setIsLoading(false);
    };
    const onError = (error) => {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Unknown error while creating channel, please contact support team.');
        console.error('Error on creating channel.', error);
      }
      setIsLoading(false);
    };
    dispatch(projectsActions.update(projectId, newChannel, onSuccess, onError));
  }
  async function handleChannelEdit() {
    const updatedChannel = {
      name: channelName.trim(),
      label: channelName.trim(),
      color: labelColor,
      channelId: channelToEdit.id,
    };

    const onSuccess = () => {
      setTimeout(cleanUpAndClose, 1000);
      setIsLoading(false);
    };
    const onError = (error) => {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Unknown error while updating channel, please contact support team.');
        console.error('Error on updating channel.', error);
      }
      setIsLoading(false);
    };
    dispatch(projectsActions.update(projectId, updatedChannel, onSuccess, onError));
  }

  function cleanUpAndClose() {
    setErrorMessage('');
    dispatch(sendProjectUpdateNotice(projectId));
    onClose();
  }
  const updateLabelColor = (e) => {
    setLabelColor(e.target.value);
  };
  return (
    <StyledAddChannel {...props}>
      <NewChannelForm onSubmit={handleSubmit}>
        <header>
          <CardHeading>
            {channelAction === 'add' ? 'Create New Channel' : 'Edit Channel'}
          </CardHeading>
        </header>
        {errorMessage && (
          <p>
            <ErrorIcon /> <ErrorText>{errorMessage}</ErrorText>
          </p>
        )}
        <InputLabel>
          <span>Name*</span>
          <TextInput
            name="label"
            placeholder="Add Channel Name"
            autoComplete="off"
            required
            onChange={(e) => setChannelName(e.target.value)}
            value={channelName}
          />
        </InputLabel>
        <br />
        <InputLabel>
          <span>Label</span>
          <LabelColorField
            style={{ cursor: 'pointer' }}
            onClick={() => labelColorRef.current.click()}
          >
            {labelColor ? (
              <ChannelLabel color={labelColor}>{channelName || 'Select Color'}</ChannelLabel>
            ) : (
              <p>Select Label Color</p>
            )}
          </LabelColorField>
          <LabelColorInput
            name="color"
            type="color"
            ref={labelColorRef}
            value={labelColor}
            onChange={updateLabelColor}
          />
        </InputLabel>
        <ModalButtonsGroup>
          <PrimaryButton type="submit" disabled={isLoading}>
            {isLoading ? 'Processing...' : channelAction === 'edit' ? 'Edit' : 'Create'}
          </PrimaryButton>

          <AnchorLink to="#" onClick={cleanUpAndClose}>
            Cancel
          </AnchorLink>
        </ModalButtonsGroup>
      </NewChannelForm>
    </StyledAddChannel>
  );
}

export default AddEditChannel;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from '../ui/modal';
import {
  IncomingCallModalRow,
  IncomingCallModalActions,
  IncomingCallModalMessage,
  AcceptButton,
  DeclineButton,
} from '../../features/main/project-main-sidebar/styles';
import AVControlsModal from '../broadcast/AVControlsModal';
import { closeGroupCallInvite } from '../video/group-video-slice';

function StartGroupCallModal({
  isOpen,
  close,
  groupName,
  handleVideoCall,
  isJoiningOngoingCall = false,
}) {
  const [isAVModalOpen, setAVModalOpen] = useState(false);
  const { isIncomingCall, camera, microphone } = useSelector((state) => state.groupVideoCall);
  const dispatch = useDispatch();

  const openAvModal = () => {
    close();

    if (camera && microphone) {
      handleVideoCall(camera, microphone, isJoiningOngoingCall);
    } else {
      setAVModalOpen(true);
    }
  };

  const handleClose = () => {
    close();
    dispatch(closeGroupCallInvite());
  };

  return (
    <>
      <Modal
        onRequestClose={handleClose}
        isOpen={isOpen || isIncomingCall}
        content={{ backgroundColor: '#000000', color: '#ffffff', width: 400 }}
        closeIcon
      >
        <IncomingCallModalRow>
          <h2>Start/Join video call</h2>
        </IncomingCallModalRow>
        <IncomingCallModalMessage>
          Are you sure you want to start/join <span>{groupName}</span> video call?
        </IncomingCallModalMessage>
        <IncomingCallModalActions>
          <DeclineButton onClick={handleClose}>Cancel</DeclineButton>
          <AcceptButton onClick={openAvModal}>Join/Start</AcceptButton>
        </IncomingCallModalActions>
      </Modal>

      {isAVModalOpen && (
        <AVControlsModal
          close={() => setAVModalOpen(false)}
          onDoneBroadcast={(selectedVideo, selectedAudio) =>
            handleVideoCall(selectedVideo, selectedAudio, isJoiningOngoingCall)
          }
          actionText="Start/Join Call"
        />
      )}
    </>
  );
}

StartGroupCallModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  groupName: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  handleVideoCall: PropTypes.func.isRequired,
};

export default StartGroupCallModal;

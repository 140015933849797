export const TOPIC_CHANNEL = 'channel';

export const TOPIC_GROUP = 'group';

export const TOPIC_USER = 'user';

export const CALL_ACTIONS = {
  CANCEL: 'cancel',
  INVITE: 'invite',
  ACCEPT: 'accept',
  REJECT: 'reject',
  HANG_UP: 'hang-up',
  JOIN_CALL: 'join',
};

export const VONAGE_INSERT_MODES = {
  REPLACE: 'replace',
  AFTER: 'after',
  BEFORE: 'before',
  APPEND: 'append',
};

export const BROADCAST_ACTIONS = {
  STARTED: 'STARTED',
  ENDED: 'ENDED',
  PROJECT_UPDATED: 'PROJECT_UPDATED',
};

export const AUDIO_LEVEL_THRESHOLD = 0.01;
export const AUDIO_CALL_ACTIONS = {
  JOIN_CALL: 'join-call',
  LEAVE_CALL: 'leave-call',
  CHECK_CHANNEL_AUDIO_CALL_STATUS: 'check-audio-call-status', // user dispatches this when they freshly log into the app to get ongoing audio call status
};

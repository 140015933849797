import { useSelector } from 'react-redux';
import { TOPIC_CHANNEL, TOPIC_GROUP, TOPIC_USER } from '../calls/calls-constants';

export function useSelectedChatName(shouldGetGroupNumber = true) {
  const chatChannel = useSelector((state) => state.messages.channel) || '';

  const [topic = '', ...restOfChatChannel] = chatChannel.split('-');
  const entityId = restOfChatChannel.join('-');
  const entity = useSelector(getEntitySelector(topic, entityId));
  const users = useSelector((state) => {
    const project = state.projects.items.find((p) => p.id === Number(restOfChatChannel[0]));
    if (!project) return [];
    const combinedProject = { ...project, ...state.main.updatedProjectInfo };
    return combinedProject.users;
  });
  return getEntityName(topic, entity, users, shouldGetGroupNumber);
}

export const makeTopicIdForUsersFn = (projectId, selfUserId) => (id) => {
  return `${projectId}-${id <= selfUserId ? `${id}-${selfUserId}` : `${selfUserId}-${id}`}`;
};

export const makeTopicIdForGroups = (projectId, groupId) => `${projectId}-${groupId}`;

export const makeTopicIdForChannels = (projectId, channelId) => `${projectId}-${channelId}`;

function findUserDifferentThanSelf(selfUserId, ids) {
  const anotherUserId = ids.find((id) => parseInt(id, 10) !== parseInt(selfUserId, 10));
  const isSameId = ids.every((id) => parseInt(id, 10) === parseInt(selfUserId, 10));
  return isSameId ? parseInt(selfUserId, 10) : parseInt(anotherUserId, 10);
}

function getEntitySelector(topic, entityId) {
  if (topic === TOPIC_CHANNEL) {
    return (state) => {
      const [projectId, channelId] = entityId.split('-');
      const project = state.projects.items.find((p) => p.id === Number(projectId));
      if (!project) {
        return null;
      }
      const combinedProject = { ...project, ...state.main.updatedProjectInfo };
      const channel = combinedProject.channels.find((c) => c.id === Number(channelId));
      return channel;
    };
  }

  if (topic === TOPIC_GROUP) {
    return (state) => {
      const [projectId, groupId] = entityId.split('-');
      const project = state.projects.items.find((p) => p.id === Number(projectId));
      if (!project) {
        return null;
      }

      const combinedProject = { ...project, ...state.main.updatedProjectInfo };
      const group = combinedProject.groups.find((c) => c.id === Number(groupId));
      return group;
    };
  }

  if (topic === TOPIC_USER) {
    const [projectId, ...userIds] = entityId.split('-');
    return (state) => {
      const myId = state.auth.userId;
      const id = findUserDifferentThanSelf(myId, userIds);
      const project = state.projects.items.find((p) => p.id === Number(projectId));
      if (!project) {
        return null;
      }
      const combinedProject = { ...project, ...state.main.updatedProjectInfo };
      return combinedProject.users.find((u) => u.id === id);
    };
  }

  return () => null;
}

function getEntityName(topic, entity, users, shouldGetGroupNumber) {
  if (!entity) {
    return '';
  }

  if (topic === TOPIC_CHANNEL) {
    return `#${entity.name.toLowerCase().replace(' ', '_')}`;
  }

  if (topic === TOPIC_GROUP) {
    if (entity.name) return entity.name;
    const names = entity.participantsIds
      .map((id) => users.find((u) => u.id === id))
      .filter((it) => it)
      .map((user) => user.profile[0]?.name ?? user.name);
    return shouldGetGroupNumber ? `(${names.length}) ${names.join(', ')}` : `${names.join(', ')}`;
  }

  if (topic === TOPIC_USER) {
    return entity?.profile[0]?.name ? entity?.profile[0]?.name : entity.name || 'Unknown person';
  }

  return '';
}

import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePubNub } from 'pubnub-react';
import {
  setVideoCallPubnubInstance,
  setVideoCallPubnubListener,
  videoCallListener,
} from '../features/video/video-slice';

function useInitializeVideoCall(project) {
  const pubnub = usePubNub();
  const dispatch = useDispatch();
  const listener = useRef(null);
  const { updatedProjectInfo } = useSelector((state) => state.main);
  const { userId } = useSelector((state) => state.auth);

  listener.current = videoCallListener(dispatch, userId);

  useEffect(() => {
    if (project) {
      const combinedProject = { ...project, ...updatedProjectInfo };
      if (!combinedProject.users) return;
      const { users } = combinedProject;
      const userList = users
        .filter((user) => (user.id !== userId ? user.id : null))
        .map((user) => user.id);

      const channels = userList.map(
        (peerId) => `video-call-${project.id}-${[userId, peerId].sort((a, b) => a - b).join('-')}`
      );

      pubnub.addListener(listener.current);
      pubnub.subscribe({ channels, withPresence: true });
      dispatch(setVideoCallPubnubListener(listener.current));

      dispatch(setVideoCallPubnubInstance(pubnub));
    }
  }, [pubnub, dispatch, project, updatedProjectInfo, userId]);

  return {
    pubnub,
    listener: listener.current,
  };
}
export default useInitializeVideoCall;

import { createSlice } from '@reduxjs/toolkit';
import PubNub from 'pubnub';
import backHttpClient from '../../app/backend-http-client';
import vonageClient from '../../app/vonage-client';
import { v4 as uuidv4 } from 'uuid';
import { CALL_ACTIONS, VONAGE_INSERT_MODES } from '../calls/calls-constants';
import { toast } from 'react-toastify';

export const groupCallSlice = createSlice({
  name: 'groupVideoCall',
  initialState: {
    camera: null,
    microphone: null,
    isInGroupVideoCall: false,
    invitedUser: null,
    isMicMuted: true,
    isVideoMuted: true,
    isAudioMuted: true,
    session: null,
    sessionId: '',
    subscriberStream: {},
    publisherToken: '',
    publisher: null,
    registeredPeers: [],
    pubSub: null,
    isIncomingCall: false,
    invitingUser: null,
    invitingSessionId: null,
    channel: '',
    groupName: '',
    activeGroupCallMembers: {},
    videoCallStreamIds: [],
    listener: null,
    isReady: false,
    shouldDisplayMiniVideo: false,
    subscribers: {},
    groupCallState: {},
    displayScreen: 'large', // values are large or minimized
  },
  reducers: {
    setVideoSessionCredentials: (state, action) => {
      state.apiKey = action.payload.apiKey;
      state.sessionId = action.payload.sessionId;

      if (action.payload.session) {
        state.session = action.payload.session;
      }
    },
    setVideoSession: (state, action) => {
      state.session = action.payload;
    },
    setVideoPublisherToken: (state, action) => {
      state.publisherToken = action.payload;
    },
    setIsInGroupVideoCall: (state, action) => {
      state.isInGroupVideoCall = action.payload;
    },
    setIsMicMuted: (state, action) => {
      state.isMicMuted = action.payload;
    },
    setIsVideoMuted: (state, action) => {
      state.isVideoMuted = action.payload;
    },

    setIsAudioMuted: (state, action) => {
      state.isAudioMuted = action.payload;
    },
    setVideoPublisher: (state, action) => {
      state.publisher = action.payload;
    },
    setSubscriberStream: (state, action) => {
      state.subscriberStream = { ...state.subscriberStream, ...action.payload };
    },

    setUserMedia: (state, action) => {
      state.camera = action.payload.camera;
      state.microphone = action.payload.microphone;
    },
    setPubSub: (state, action) => {
      state.pubSub = action.payload;
    },
    setIsIncomingCall: (state, action) => {
      state.isIncomingCall = action.payload;
    },
    setIncomingCaller: (state, action) => {
      state.invitingUser = action.payload;
    },
    setIncomingSessionId: (state, action) => {
      state.invitingSessionId = action.payload;
    },
    setInvitedCaller: (state, action) => {
      state.invitedUser = action.payload;
    },

    setActiveGroupCallChannel: (state, action) => {
      state.channel = action.payload;
    },

    setActiveGroupCallName: (state, action) => {
      state.groupName = action.payload;
    },

    setActiveGroupCallMembers: (state, action) => {
      state.activeGroupCallMembers = { ...state.activeGroupCallMembers, ...action.payload };
    },

    setVideoCallStreamIds: (state, action) => {
      state.videoCallStreamIds = [...state.videoCallStreamIds, action.payload];
    },
    removeVideoCallStreams: (state, action) => {
      const { [action.payload]: valueToRemove, ...remainingSubcribers } = state.subscriberStream;
      state.subscriberStream = remainingSubcribers;
    },

    clearAllData: (state, _action) => {
      state.videoCallStreamIds = [];
      state.subscriberStream = {};
      state.subscribers = {};
    },

    setVideoEventListener: (state, action) => {
      state.listener = action.payload;
    },

    setReady: (state, action) => {
      state.isReady = action.payload;
    },
    setShouldDisplayMiniVideo: (state, action) => {
      state.shouldDisplayMiniVideo = action.payload;
    },

    setSubscribers: (state, action) => {
      state.subscribers = { ...state.subscribers, ...action.payload };
    },

    setGroupCallState: (state, action) => {
      state.groupCallState = {
        ...state.groupCallState,
        ...action.payload,
      };
    },

    setVideoDisplayScreen: (state, action) => {
      state.displayScreen = action.payload;
    },
  },
});

// start groupcall using saved channel sessionId
export const startGroupVideoCallPublisher =
  ({
    projectId,
    inviteeId,
    groupName,
    camera,
    microphone,
    isAcceptingInvite,
    shouldShowIncomingCallModal,
    shouldSendInvite,
    shouldPublishAudio,
  }) =>
  async (dispatch, getState) => {
    if (!camera && !microphone) {
      console.error(
        'Tried to start video call without devices, so dispatch is being ignored.',
        camera,
        microphone
      );
      return;
    }

    dispatch(groupCallSlice.actions.setUserMedia({ camera, microphone }));
    dispatch(groupCallSlice.actions.setIsInGroupVideoCall(true));
    sessionStorage.setItem('isInGroupVideoCall', true);

    if (groupName) {
      dispatch(groupCallSlice.actions.setActiveGroupCallName(groupName));
    }

    // We get the activeChat channel from the channel which is set when an adhoc group or channel has been activated
    // when a call is going to be placed. The call button is on the header of the chat page so we can be sure of the channel
    // from which the user is sending a call invitation
    const { channel: activeMessageChannel } = getState().messages; // active channel is in the format channel-${projectId}-${channelId}
    const { activeChannelId: activeAudioCallChannelId } = getState().audio;

    const activeChannel = activeAudioCallChannelId
      ? `channel-${projectId}-${activeAudioCallChannelId}`
      : activeMessageChannel;

    const {
      session,
      publisher,
      publisherToken,
      sessionId,
      channel: invitedChannel,
      isIncomingCall,
    } = getState().groupVideoCall;

    if (isIncomingCall) {
      dispatch(groupCallSlice.actions.setIsIncomingCall(false));
    }

    let groupCallChannel = isAcceptingInvite ? invitedChannel : `video-call-${activeChannel}`;
    const activeChannelId = activeChannel.substr(activeChannel.lastIndexOf('-') + 1);
    const inviteChannelId = invitedChannel.substr(invitedChannel.lastIndexOf('-') + 1);
    let channelIdToUse = isAcceptingInvite ? inviteChannelId : activeChannelId;

    // When a user is joining an ongoing call after they just logged in or
    // hard refreshed, even though they will be accepting an invite but the
    // channelIdToUse will be empty so we revert to the activeChannelId we get from
    // the messagesSlice
    if (isAcceptingInvite && !groupCallChannel) {
      groupCallChannel = `video-call-${activeChannel}`;
    }
    if (isAcceptingInvite && !channelIdToUse) {
      channelIdToUse = activeChannelId;
    }
    sessionStorage.setItem('groupVideoChannel', groupCallChannel);

    // check if the channel is an adhoc group channel
    const isAdhocGroupCall = groupCallChannel.includes('group');

    if (!publisherToken || !publisher) {
      await recreateVideoDataAsPublisher(
        projectId,
        channelIdToUse,
        inviteeId,
        shouldPublishAudio,
        isAdhocGroupCall,
        isAcceptingInvite
      )(dispatch, getState);
      dispatch(
        startGroupVideoCallPublisher({
          projectId,
          inviteeId,
          groupName,
          camera,
          microphone,
          isAcceptingInvite,
          shouldShowIncomingCallModal,
          shouldSendInvite,
          shouldPublishAudio,
        })
      );
      return;
    }

    session.on('streamCreated', (event) => {
      let alreadySubscribed = false;
      // before reconnecting check whether there's already a subscriber with same connection Id
      const subscribers = session.getSubscribersForStream(event.stream);
      for (const currentSubscriber of subscribers) {
        if (
          currentSubscriber.stream.connection.connectionId === event.stream.connection.connectionId
        ) {
          alreadySubscribed = true;
        }
      }

      if (!alreadySubscribed) {
        const { displayScreen: currentDisplayScreen } = getState().groupVideoCall;
        const subscriberOptions = {
          subscribeToVideo: true,
          subscribeToAudio: true,
          width: '100%',
          height: '100%',
          fitMode: 'contain',
          style: { buttonDisplayMode: 'off', audioLevelDisplayMode: 'off' },
          insertMode: VONAGE_INSERT_MODES.APPEND,
        };

        dispatch(groupCallSlice.actions.setVideoCallStreamIds(event.stream.id));
        dispatch(
          groupCallSlice.actions.setSubscriberStream({ [event.stream.streamId]: event.stream })
        );

        // Determine whether to display the incoming stream from other subscribers on the large displayScreen
        // or the minimized displayScreen.
        const subscriber = session.subscribe(
          event.stream,
          currentDisplayScreen === 'large'
            ? `remote-video-${event.stream.streamId}`
            : `remote-min-video-${event.stream.streamId}`,
          subscriberOptions
        );
        // Dispatch this to attach listeners to individual subscribers inside the GroupCallModal
        dispatch(groupCallSlice.actions.setSubscribers({ [event.stream.streamId]: subscriber }));

        // set audio muted
        dispatch(groupCallSlice.actions.setIsAudioMuted(false));
      }
    });

    session.on('streamDestroyed', (event) => {
      dispatch(groupCallSlice.actions.removeVideoCallStreams(event.stream.id));
      const { groupCallState, pubSub } = getState().groupVideoCall;
      // actions to update the channel meta data and the local group call state
      postStreamPublishActions({
        dispatch,
        pubSub,
        groupCallState,
        activeChannel: groupCallChannel,
        activeSessionId: sessionId,
        actionType: 'leave',
        isAdhocGroupCall,
      });
    });

    await vonageClient.connectSession(session, publisherToken);

    if (camera && microphone) {
      try {
        await Promise.all([
          publisher.setVideoSource(camera.deviceId),
          publisher.setAudioSource(microphone.deviceId),
        ]);
      } catch (_error) {
        await stopGroupVideoPublisher()(dispatch, getState);
        dispatch(
          startGroupVideoCallPublisher({
            projectId,
            inviteeId,
            groupName,
            camera,
            microphone,
            isAcceptingInvite,
            shouldShowIncomingCallModal,
            shouldSendInvite,
            shouldPublishAudio,
          })
        );
        return;
      }
    } else {
      console.log('camera and microphone permission is missing');
    }

    // Add the publisher to the session
    session.publish(publisher, async (error) => {
      if (error) {
        console.error('Error on publishing stream.');
      } else {
        console.log('publishing in video call');
        // Update the channel group state
        const { userId, name } = getState().auth;
        const { groupCallState, pubSub } = getState().groupVideoCall;

        postStreamPublishActions({
          dispatch,
          pubSub,
          userId,
          groupCallState,
          activeChannel: groupCallChannel,
          activeSessionId: sessionId,
          actionType: 'join',
        });

        // After publishing, now send a call invite to others.
        // Invite others to the call. When the invitation is sent...
        // 1. They may get an incoming call modal to join.
        // 2. If they are in the channel where the call originated, the video is displayed on the bottom left.
        // 3. If they are not in the same channel, the session Id is cached for them and an ongoing call indicator
        // is displayed on the channel for them to join when they are ready.

        if (shouldSendInvite) {
          pubSub.publish(
            {
              channel: groupCallChannel,
              message: {
                uid: uuidv4(),
                type: CALL_ACTIONS.INVITE,
                inviterId: userId,
                inviterName: name,
                createdAt: new Date().toISOString(),
                sessionId,
                projectId,
                groupName,
                shouldShowIncomingCallModal,
              },
            },
            (status) => {
              if (status.error) {
                console.error('Error on message publish', status.error);
                toast.error('Unknown error: could not publish message.', {
                  theme: 'light',
                });
              }
            }
          );
        }

        // Send a message the backend will use to sync the channel meta data
        pubSub.publish(
          {
            channel: groupCallChannel,
            message: {
              uid: uuidv4(),
              type: CALL_ACTIONS.JOIN_CALL,
              inviterId: userId,
              inviterName: name,
              createdAt: new Date().toISOString(),
              sessionId,
              projectId,
              activity: 'call',
              activityType: 'video',
            },
          },
          (status) => {
            if (status.error) {
              console.error('Error on message publish', status.error);
              toast.error('Unknown error: could not publish message.', {
                theme: 'light',
              });
            }
          }
        );
      }
    });

    // set the video session after attaching all the handlers.
    dispatch(groupCallSlice.actions.setVideoSession(session));
  };

const recreateVideoDataAsPublisher =
  (projectId, channelId, inviteeId, shouldPublishAudio, isAdhocGroupCall, isAcceptingInvite) =>
  async (dispatch, getState) => {
    // always stop the existing publisher when you are recreating data as a publisher.
    await stopGroupVideoPublisher()(dispatch, getState);

    const { session, displayScreen, invitingSessionId, groupCallState } = getState().groupVideoCall;
    const { channel: messagingChannel } = getState().messages;

    dispatch(groupCallSlice.actions.setIsInGroupVideoCall(true));
    sessionStorage.setItem('isInGroupVideoCall', true);

    // The messaging channel because the button to join a session is visible after accessing the
    // Chat box which in turn sets the appropriate channel name to be used for the call
    const acceptingInviteSessionId =
      invitingSessionId || groupCallState[`video-call-${messagingChannel}`]?.activeCallSessionId;

    let response;
    if (isAdhocGroupCall) {
      if (isAcceptingInvite) {
        response = await backHttpClient.post(
          `/projects/${projectId}/videocall/accept/${acceptingInviteSessionId}`
        );
      } else {
        response = await backHttpClient.post(
          `/projects/${projectId}/videocall/invite/${inviteeId}`
        );
      }
    } else {
      response = await backHttpClient.post(
        `/projects/${projectId}/channel/${channelId}/groupcall`,
        {
          role: 'publisher',
          type: 'video',
        }
      );
    }

    const { sessionId, token, apiKey } = response.data;
    const publisherSessionId = sessionId || acceptingInviteSessionId;
    if (session) {
      session.disconnect();
    }

    const updatedSession = vonageClient.initSession(apiKey, publisherSessionId);

    const publisher = vonageClient.OT.initPublisher(
      `${displayScreen === 'large' ? 'group-local-video' : 'group-min-local-video'}`,
      {
        insertMode: VONAGE_INSERT_MODES.APPEND,
        width: '100%',
        height: '100%',
        fitMode: 'contain',
        style: { buttonDisplayMode: 'off', audioLevelDisplayMode: 'off' },
        publishAudio: shouldPublishAudio, // true, // to be changed based on audioCall state.
        publishVideo: true,
      },
      (error) => {
        if (error) {
          console.log('Sorry stream publishing failed. show a toast to the publisher');
        } else {
          console.log('Publisher successfully initialized');
        }
      }
    );

    let activeChannel;
    if (isAdhocGroupCall) {
      activeChannel = `video-call-group-${projectId}-${channelId}`;
    } else {
      activeChannel = `video-call-channel-${projectId}-${channelId}`;
    }
    sessionStorage.setItem('groupVideoChannel', activeChannel);

    publisher.on('streamDestroyed', () => {
      const { groupCallState, pubSub } = getState().groupVideoCall;
      postStreamPublishActions({
        dispatch,
        pubSub,
        groupCallState,
        activeChannel,
        activeSessionId: publisherSessionId,
        actionType: 'leave',
      });

      dispatch(stopGroupVideoPublisher());
    });

    dispatch(
      groupCallSlice.actions.setVideoSessionCredentials({
        apiKey,
        sessionId: publisherSessionId,
        session: updatedSession,
      })
    );

    dispatch(groupCallSlice.actions.setIsMicMuted(false));
    dispatch(groupCallSlice.actions.setIsVideoMuted(false));
    dispatch(groupCallSlice.actions.setVideoPublisherToken(token));
    dispatch(groupCallSlice.actions.setVideoPublisher(publisher));

    // Set the person being called
    if (inviteeId) dispatch(groupCallSlice.actions.setInvitedCaller(inviteeId));
  };

// Group Call invitation handler
async function inviteHandler(dispatch, getState, event) {
  const { isInAudioRoom, activeChannelId: activeAudioCallChannelId } = getState().audio;
  const { userId } = getState().auth;
  const { isInGroupVideoCall, publisher, activeGroupCallMembers } = getState().groupVideoCall;

  // Update the GroupCall name and channel if they are not already in one.
  if (!isInGroupVideoCall) {
    dispatch(groupCallSlice.actions.setActiveGroupCallName(event.message.groupName));
    dispatch(groupCallSlice.actions.setActiveGroupCallChannel(event.channel));
    sessionStorage.setItem('groupVideoChannel', event.channel);

    // setting the invitationSessionID for an adhocGroupCall
    dispatch(groupCallSlice.actions.setIncomingSessionId(event.message.sessionId));

    if (event.message.shouldShowIncomingCallModal) {
      // For adhoc group call, check the currently authenticated user is part of the channel
      // before you show the invite modal
      const incomingAdhocGroupCallId = event.channel.substr(event.channel.lastIndexOf('-') + 1);
      const isAdhocGroupCall = event.channel.includes('group');
      const userIsGroupMember =
        activeGroupCallMembers.group[incomingAdhocGroupCallId]?.includes(userId);

      if (isAdhocGroupCall && !userIsGroupMember) {
        return;
      }
      dispatch(
        groupCallSlice.actions.setIncomingCaller({
          name: event.message.inviterName || 'Anonymous',
          id: event.message.inviterId,
        })
      );
      dispatch(groupCallSlice.actions.setIsIncomingCall(true));
    }
  }
  // When I receive an invite and I'm in that audio call, the incoming stream should be displayed on my UI
  // check whether the user is already in the same audio room (channel from invite === currently active channel) and the user is not
  // in an existing group call. or maybe just check whether the user is in a group call
  const currentGroupCallChannel = `video-call-channel-${event.message.projectId}-${activeAudioCallChannelId}`;
  if (
    isInAudioRoom &&
    event.channel === currentGroupCallChannel &&
    !isInGroupVideoCall &&
    !publisher
  ) {
    console.log('publishing to the user in the same channel');
    dispatch(groupCallSlice.actions.setVideoDisplayScreen('minimized'));
    dispatch(groupCallSlice.actions.setIsInGroupVideoCall(true));
    sessionStorage.setItem('isInGroupVideoCall', true);
    dispatch(joinGroupCallAsSubscriber(event.message.projectId, activeAudioCallChannelId));
  }
}

export const joinGroupCallAsSubscriber = (projectId, channelId) => async (dispatch, getState) => {
  console.log('you are in the same channel so receive the invite');
  const { session } = getState().groupVideoCall;
  if (session) session.disconnect();
  dispatch(groupCallSlice.actions.setVideoDisplayScreen('minimized'));
  dispatch(groupCallSlice.actions.setIsInGroupVideoCall(true));
  sessionStorage.setItem('isInGroupVideoCall', true);

  const response = await backHttpClient.post(
    `/projects/${projectId}/channel/${channelId}/groupcall`,
    {
      role: 'subscriber',
      type: 'video',
    }
  );

  const { token, apiKey, sessionId } = response.data;

  const newSession = vonageClient.initSession(apiKey, sessionId);

  newSession.on('streamCreated', (streamEvent) => {
    let alreadySubscribed = false;
    // before reconnecting check whether there's already a subscriber with same connection Id
    const subscribers = newSession.getSubscribersForStream(streamEvent.stream);
    for (const currentSubscriber of subscribers) {
      if (
        currentSubscriber.stream.connection.connectionId ===
        streamEvent.stream.connection.connectionId
      ) {
        alreadySubscribed = true;
      }
    }

    if (!alreadySubscribed) {
      const { displayScreen: currentDisplayScreen, publisher } = getState().groupVideoCall;

      // Subscribe to new streams if we don't have an existing publisher.
      // This prevents duplicate streams when this user then joins the existing stream as a publisher
      if (!publisher) {
        const subscriberOptions = {
          subscribeToVideo: true,
          subscribeToAudio: true,
          width: '100%',
          height: '100%',
          fitMode: 'contain',
          style: { buttonDisplayMode: 'off', audioLevelDisplayMode: 'off' },
          insertMode: VONAGE_INSERT_MODES.APPEND,
        };

        dispatch(groupCallSlice.actions.setVideoCallStreamIds(streamEvent.stream.id));
        dispatch(
          groupCallSlice.actions.setSubscriberStream({
            [streamEvent.stream.streamId]: streamEvent.stream,
          })
        );

        const subscriber = newSession.subscribe(
          streamEvent.stream,
          currentDisplayScreen === 'large'
            ? `remote-video-${streamEvent.stream.streamId}`
            : `remote-min-video-${streamEvent.stream.streamId}`,
          subscriberOptions
        );

        dispatch(
          groupCallSlice.actions.setSubscribers({ [streamEvent.stream.streamId]: subscriber })
        );

        // update audio mute state
        dispatch(groupCallSlice.actions.setIsAudioMuted(false));
      }
    }
  });

  newSession.on('streamDestroyed', function streamDestroyed(streamEvent) {
    dispatch(groupCallSlice.actions.removeVideoCallStreams(streamEvent.stream.id));

    const {
      groupCallState,
      pubSub,
      publisher: activePublisher,
      subscriberStream,
    } = getState().groupVideoCall;

    // if stream is destroyed and there's no publisher, check the number of call participants and remove the group call ui
    console.log('joinGroupCallAsSubscriber stream destroyed');
    if (!activePublisher && Object.keys(subscriberStream).length === 0) {
      dispatch(groupCallSlice.actions.setIsInGroupVideoCall(false));
      sessionStorage.setItem('isInGroupVideoCall', false);
    }

    // actions to update the channel meta data and the local group call state
    postStreamPublishActions({
      dispatch,
      pubSub,
      groupCallState,
      activeChannel: `video-call-channel-${projectId}-${channelId}`,
      activeSessionId: sessionId,
      actionType: 'leave',
    });
  });

  dispatch(
    groupCallSlice.actions.setVideoSessionCredentials({
      apiKey,
      sessionId,
      session: newSession,
    })
  );

  await vonageClient.connectSession(newSession, token);

  dispatch(groupCallSlice.actions.setIsMicMuted(true));
  dispatch(groupCallSlice.actions.setIsVideoMuted(true));
};

// Actions to perform after publishing your stream to the session.
const postStreamPublishActions = async ({
  dispatch,
  groupCallState,
  activeChannel,
  activeSessionId,
  actionType,
}) => {
  let callParticipants;
  const isInGroupCall = groupCallState[activeChannel]?.callParticipants <= 1;

  if (actionType === 'join') {
    callParticipants = groupCallState[activeChannel]?.callParticipants + 1;
  }

  if (actionType === 'leave') {
    callParticipants =
      groupCallState[activeChannel]?.callParticipants <= 0
        ? 0
        : groupCallState[activeChannel]?.callParticipants - 1;
  }

  const currentCallStateData = {
    isInGroupCall,
    callParticipants,
    activeCallSessionId: activeSessionId,
  };

  dispatch(
    groupCallSlice.actions.setGroupCallState({
      [activeChannel]: currentCallStateData,
    })
  );
};

// Create listeners when the user starts the application to be able to get group level
// incoming call notifications
export const registerGroupVideoPeers =
  (groupChannelTopics, groupMembers) => async (dispatch, getState) => {
    const { userId, uuid } = getState().auth;

    // Subscribe to group call channels. From the Channels and Group List
    const channels = groupChannelTopics.map((topic) => `video-call-${topic}`);

    // Set the initial indicator for when there is an ongoing video call for a channel
    const initialGroupCallState = channels.reduce((usersByChannel, channelName) => {
      usersByChannel[channelName] = {
        isInGroupCall: false,
        callParticipants: 0,
        activeCallSessionId: '',
      };
      return usersByChannel;
    }, {});

    dispatch(groupCallSlice.actions.setGroupCallState(initialGroupCallState));

    dispatch(groupCallSlice.actions.setIsInGroupVideoCall(false));
    sessionStorage.setItem('isInGroupVideoCall', false);

    let { pubSub } = getState().groupVideoCall;
    const response = await backHttpClient.get('pubnub');
    const { publishKey, subscribeKey } = response.data;
    if (!pubSub) {
      pubSub = new PubNub({ publishKey, subscribeKey, uuid });
      dispatch(groupCallSlice.actions.setPubSub(pubSub));
    }

    const listener = {
      status: async (event) => {
        if (event.category === 'PNConnectedCategory') {
          dispatch(groupCallSlice.actions.setReady(true));

          // Get the membership object. This is where we had set the latest group call status so that those who join later will get notified.
          const metaDataBatchRequest = channels.map((channel) =>
            pubSub.objects.getChannelMetadata({ channel })
          );

          try {
            const results = await Promise.allSettled(metaDataBatchRequest);
            // get the resolved promises and set the updated group channel properties.
            const resolvedResults = results.filter((result) => result.status === 'fulfilled');
            if (resolvedResults.length) {
              resolvedResults.forEach((channelMetaData) => {
                dispatch(
                  groupCallSlice.actions.setGroupCallState({
                    [channelMetaData.value.data.custom?.channel]: JSON.parse(
                      channelMetaData.value.data.custom.groupCallState
                    ),
                  })
                );
              });
            }
          } catch (error) {
            console.log({ error });
          }
        }
      },
      message: (event) => {
        if (!event.message.uid) {
          throw new Error('Missing message identifier.');
        }

        if (event.message.type === 'UPDATE_PARTICIPANT_COUNT') {
          dispatch(
            groupCallSlice.actions.setGroupCallState({
              [event.channel]: {
                isInGroupCall: true,
                callParticipants: event.message.updatedParticipantCount,
                activeCallSessionId: event.message.sessionId,
              },
            })
          );
        }

        if (!event.message.inviterId || event.message.inviterId === userId) {
          return 'ignoring request';
        }

        if (event.message.type === CALL_ACTIONS.INVITE) {
          return inviteHandler(dispatch, getState, event);
        }
      },
    };

    // set the ids of all members belonging to a group or channel. This is used to dynamically display
    // the remote stream for that user
    dispatch(groupCallSlice.actions.setActiveGroupCallMembers(groupMembers));

    pubSub.addListener(listener);
    dispatch(groupCallSlice.actions.setVideoEventListener(listener));
    pubSub.subscribe({ channels });
  };

export const toggleVideoCallMic = () => (dispatch, getState) => {
  const { isMicMuted, publisher } = getState().groupVideoCall;
  if (!publisher) {
    console.warn('Unable to toggle mic when not in audio room');
  }
  const newMicState = !isMicMuted;
  publisher.publishAudio(!newMicState);
  dispatch(groupCallSlice.actions.setIsMicMuted(newMicState));
};
export const toggleVideoCallVideo = () => (dispatch, getState) => {
  const { isVideoMuted, publisher } = getState().groupVideoCall;
  if (!publisher) {
    console.warn('Unable to toggle mic when not in audio room');
    alert('Turn on your video instead');
    return;
  }
  const newVideoState = !isVideoMuted;
  publisher.publishVideo(!newVideoState);
  dispatch(groupCallSlice.actions.setIsVideoMuted(newVideoState));
};

export const toggleVideoCallAudio = () => (dispatch, getState) => {
  const { isAudioMuted, session, subscriberStream } = getState().groupVideoCall;

  if (!session) {
    console.warn('Unable to toggle mic when not in audio room');
  }

  const muteAllStreams = !isAudioMuted;
  dispatch(groupCallSlice.actions.setIsAudioMuted(muteAllStreams));

  const subscribers = Object.values(subscriberStream).map(
    (userStream) => session.getSubscribersForStream(userStream)[0]
  );
  subscribers.forEach((subscriber) => {
    subscriber.subscribeToAudio(muteAllStreams);

    if (muteAllStreams) {
      subscriber.setAudioVolume(0);
    } else {
      subscriber.setAudioVolume(80);
    }
  });
};

export const resetGroupCallData = () => async (dispatch) => {
  dispatch(groupCallSlice.actions.setVideoPublisher(null));
  dispatch(groupCallSlice.actions.setIsInGroupVideoCall(false));
  dispatch(groupCallSlice.actions.setVideoSession(null));
  dispatch(
    groupCallSlice.actions.setVideoSessionCredentials({ apiKey: '', sessionId: '', session: null })
  );

  dispatch(groupCallSlice.actions.setIsMicMuted(true));
  dispatch(groupCallSlice.actions.setIsVideoMuted(true));
  dispatch(groupCallSlice.actions.setIsAudioMuted(true));

  dispatch(groupCallSlice.actions.clearAllData());
};
// Stop video call publisher
export const stopGroupVideoPublisher = () => async (dispatch, getState) => {
  const { groupVideoCall } = getState();
  const { session, publisher, subscriberStream } = groupVideoCall;
  if (!session) {
    console.warn('Tried to stop video call but with no enough resources, ignoring it.');
    return;
  }
  if (publisher) {
    publisher.publishAudio(false);
    publisher.publishVideo(false);
    session.unpublish(publisher);
  }

  // subscribers from subscriberStream
  const subscribers = Object.values(subscriberStream).map(
    (userStream) => session.getSubscribersForStream(userStream)[0]
  );
  subscribers.forEach((subscriber) => {
    if (subscriber) {
      try {
        subscriber.subscribeToAudio(false);
        subscriber.subscribeToVideo(false);
        session.unsubscribe(subscriber);
      } catch (error) {
        console.log(error);
        console.log('error unsubscribing');
      }
    }
  });
  session.disconnect();
  dispatch(resetGroupCallData());
};

// Cancel invite to call (Caller)
export const endVideoCall = (groupPubnubChannel) => async (dispatch, getState) => {
  const { userId, name } = getState().auth;
  const { pubSub, channel, groupCallState, invitingSessionId } = getState().groupVideoCall;

  let activeVideoChannel = '';
  if (groupPubnubChannel) {
    activeVideoChannel = `video-call-${groupPubnubChannel}`;
  }

  const activeChannel = activeVideoChannel || channel;
  const sessionId = groupCallState?.[activeChannel]?.activeCallSessionId || invitingSessionId;

  if (activeChannel) {
    pubSub.publish(
      {
        channel: activeChannel,
        message: {
          uid: uuidv4(),
          type: CALL_ACTIONS.HANG_UP,
          inviterId: userId,
          inviterName: name,
          createdAt: new Date().toISOString(),
          sessionId,
          activity: 'call',
          activityType: 'video',
        },
      },
      (status) => {
        if (status.error) {
          console.error('Error on message publish', status.error);
          toast.error('Unknown error: could not publish message.', {
            theme: 'light',
          });
        }
      }
    );
  }
  dispatch(stopGroupVideoPublisher());
};

export const closeGroupCallInvite = () => (dispatch) => {
  dispatch(groupCallSlice.actions.setIsInGroupVideoCall(false));
  dispatch(groupCallSlice.actions.setIsIncomingCall(false));
};

export const displayMiniVideo = (shouldDisplay) => (dispatch) => {
  dispatch(groupCallSlice.actions.setShouldDisplayMiniVideo(shouldDisplay));
};

// mode === large or minimized
export const setScreenToDisplay = (mode) => (dispatch) => {
  dispatch(groupCallSlice.actions.setVideoDisplayScreen(mode));
};

export const stopGroupVideoPubnubListener = () => (dispatch, getState) => {
  const { pubSub, listener } = getState().groupVideoCall;
  if (pubSub) {
    pubSub.removeListener(listener);
    pubSub.unsubscribeAll();
    dispatch(groupCallSlice.actions.setVideoEventListener(null));
    dispatch(groupCallSlice.actions.setPubSub(null));
  }
};

export default groupCallSlice.reducer;

import { useEffect, useState } from 'react';
import get from 'lodash.get';
import styled from 'styled-components';
import { Modal } from '../ui/modal';
import { InputLabel, PrimaryButton, Select } from '../ui/inputs';
import { AnchorLink, ErrorText } from '../ui/texts';
import { ErrorIcon } from '../ui/iconography';

export default function AVControlsModal(props) {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoDevices, setVideoDevices] = useState([]);
  const findVideoDevice = (idOrLabel) =>
    videoDevices.find((it) => it.deviceId === idOrLabel || it.label === idOrLabel) || null;
  const handleCameraChange = (event) => {
    setSelectedVideo(findVideoDevice(event.target.value));
  };

  const [selectedAudio, setSelectedAudio] = useState(null);
  const [audioDevices, setAudioDevices] = useState([]);
  const findAudioDevice = (idOrLabel) =>
    audioDevices.find((it) => it.deviceId === idOrLabel || it.label === idOrLabel);
  const handleMicrophoneChange = (event) => {
    setSelectedAudio(findAudioDevice(event.target.value));
  };

  const [error, setError] = useState('');

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        stream.getTracks().forEach((t) => t.stop());

        return navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            const allowedDevices = devices.filter((it) => !!it.deviceId && !!it.label);
            if (allowedDevices.length === 0) {
              setError(
                "You didn't give permanent permission to access your input devices or simply forgot to plug a microphone/camera."
              );
            }

            const foundVideoDevices = allowedDevices.filter((it) => it.kind === 'videoinput');
            if (foundVideoDevices.length > 0) {
              setVideoDevices(foundVideoDevices);
            }

            const foundAudioDevices = allowedDevices.filter((it) => it.kind === 'audioinput');
            if (foundAudioDevices.length > 0) {
              setAudioDevices(foundAudioDevices);
            }

            const defaultVideo =
              foundVideoDevices.find((it) => it.deviceId === 'default') || foundVideoDevices[0];
            if (defaultVideo) {
              setSelectedVideo(defaultVideo);
            }

            const defaultAudio =
              foundAudioDevices.find((it) => it.deviceId === 'default') || foundAudioDevices[0];
            if (defaultAudio) {
              setSelectedAudio(defaultAudio);
            }
          })
          .catch((error) => {
            setError('Unknown error while enumerating user devices, please contact support.');
            console.error('Error on enumerate handling', error);
          });
      })
      .catch((error) => {
        console.error('Error on get media handler', error);
        setError('No devices permissions: allow it in your browser settings and retry.');
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!selectedAudio && !selectedVideo) {
      setError('At least one media input is required for broadcasting.');
      return;
    }

    props.onDoneBroadcast(selectedVideo, selectedAudio);
    props.close();
  };

  const handleCancel = (event) => {
    event.preventDefault();
    props.close();
  };

  return (
    <Modal onRequestClose={props.close} isOpen>
      <form onSubmit={handleSubmit}>
        <p>Select your video and audio devices:</p>
        {error && (
          <p>
            <ErrorIcon /> <ErrorText>{error}</ErrorText>
          </p>
        )}
        <InputLabel>
          <span>Camera</span>
          <Select value={get(selectedVideo, 'deviceId', '')} onChange={handleCameraChange}>
            <option value="">Disabled</option>
            {videoDevices.map((device) => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label}
              </option>
            ))}
          </Select>
        </InputLabel>
        <InputLabel>
          <span>Microphone</span>
          <Select value={get(selectedAudio, 'deviceId', '')} onChange={handleMicrophoneChange}>
            <option value="">Disabled</option>
            {audioDevices.map((device) => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label}
              </option>
            ))}
          </Select>
        </InputLabel>
        <br />
        <BottomButtons>
          <AnchorLink to="#" onClick={handleCancel}>
            Cancel
          </AnchorLink>{' '}
          <PrimaryButton type="submit">{props.actionText || 'Broadcast'}</PrimaryButton>
        </BottomButtons>
      </form>
    </Modal>
  );
}

const BottomButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${AnchorLink} {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

import styled from 'styled-components';
import { INITIAL_RATIO } from '../broadcast/VideoSettings';
import NoiseBgHelper from '../../images/noise_bg_helper.png';

export const CallModalHeaderControls = styled.div`
  display: flex;
  margin-left: auto;
  i {
    font-size: 20px;
  }
  button {
    border: none;
    background: none;
    color: inherit;
    height: 20px;
    margin: 0 0 0 10px;
    padding: 0;
    cursor: pointer;
  }
`;
export const CallModalHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  z-index: 10;
`;
export const VideoCallActionButtonGroup = styled.div`
  z-index: 2;
  display: flex;
  padding: 0;
  margin: auto;
  color: #fff;
  border: none;
  background: none;
  justify-content: center;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
`;

export const CallAvatar = styled.img`
  background-color: #ffffff;
  height: 150px;
  width: 150px;
  border-radius: 100%;
  margin-bottom: 10px;
`;

export const AspectRatioUIWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const DraggableChild = styled.div.attrs(({ position, height, width }) => ({
  style: {
    transform: `translate(${position.x}, ${position.y})`,
    height: `${height}px`,
    width: `${width}px`,
    border: width <= 0 ? '' : '1px solid white',
  },
}))`
  box-sizing: border-box;
  cursor: move;
  box-shadow: 0 0 0 1600px rgba(0, 0, 0, 0.5);

  &.react-draggable-dragging {
    transition: border 0.25s ease;
    border-color: red !important;
  }
`;

export const OtBroadcastingUIWrapper = styled.div.attrs((props) => ({
  style: {
    filter: `contrast(${props.$contrast}) brightness(${props.$brightness})`,
  },
}))`
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;

  .OT_widget-container {
    position: relative;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000000;
    }
  }
`;
export const StyledOTBroadcast = styled.div`
  width: 100%;
`;

export const VideoCover = styled.div.attrs((props) => ({
  style: {
    background:
      props.$temperature > 0
        ? `rgba(0,0,255,${Math.abs(props.$temperature) / 400}`
        : `rgba(255,0,0,${Math.abs(props.$temperature) / 500}`,
  },
}))`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20;
`;

export const BroadcastOT = styled.div`
  box-sizing: border-box;
  z-index: 1;
  width: 100%;
  max-width: -webkit-fill-available;
  max-height: -webkit-fill-available;
  position: relative;
  aspect-ratio: ${INITIAL_RATIO};

  &.ratio-unset .react-draggable {
    display: none;
  }

  /* safari */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      height: 100%;
    }
  }
`;

export const ConfigButton = styled.button`
  display: block;
  flex-shrink: 0;
  margin-top: -75px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: -65px;
  margin-right: auto;
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 20px;
  background: #fff;
  color: #000;
  border: none;
  cursor: pointer;
  border-radius: 100%;
  z-index: 1;
`;

export const CallButton = styled.button`
  width: auto;
  margin-left: 20px;
  height: 64px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 2px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  border: none;
  cursor: pointer;
  background: none;

  i {
  }
`;
export const BroadcastingWrapper = styled.div`
  display: ${(props) => (props.isBroadcasting ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.$fullHeight ? '100%' : '50vh')};

  .OT_video-element {
    background: rgb(0, 0, 0);
  }

  &,
  .OT_widget-container {
    background: rgb(70, 70, 70);
    max-height: calc(100vh - 65px);
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 15px;
    background-image: url(${NoiseBgHelper});
    background-color: #333333;
    background-blend-mode: overlay;
    box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.2), inset 0px 1.5px 0.5px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: rgba(255, 255, 255, 75%);
  }
`;

export const HeadingText = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  max-width: 300px;
`;

export const ConferenceCollaper = styled.div`
  cursor: pointer;
  font-size: 14px;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  i {
    display: flex;
    height: 10px;
    align-items: center;
    justify-content: center;

    &:first-child {
      transform: rotate(180deg);
    }
  }
`;

export const BroadcastChatWrap = styled.div`
  display: block;
  height: 100%;
`;

export const StyledBroadcastHeader = styled.header`
  z-index: 2;
  height: 64px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  padding: 0;
  justify-content: space-between;

  background-image: url(${NoiseBgHelper});
  background-color: #333333;
  background-blend-mode: overlay;
  box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.35),
    inset 0px 1.5px 0.5px rgba(0, 0, 0, 0.5);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: rgba(255, 255, 255, 75%);

  &.chat-collapsed {
    border-radius: 4px;
    box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.2), inset 0px 1.5px 0.5px rgba(0, 0, 0, 0.5);
  }
`;

export const BroadcastSection = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledViewFiles = styled.button`
  background: #000;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  margin-right: 10px;
  cursor: pointer;
  border: none;
`;

// Channel Member View Modals

export const MembersList = styled.ul`
  list-style: none;
  margin: 2rem 0;
  padding: 0;
  li {
    display: grid;
    grid-template-columns: 1fr 3fr 3fr 1fr;
    padding: 1rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid #c4c4c4;
    }

    .userInfo {
      display: flex;
      align-items: center;
      text-align: left;

      .status {
        display: block;
        padding: 5px;
        background-color: #c13c3c;
        border-radius: 50%;
        margin-right: 5px;
      }
      .online {
        background-color: #3a9224;
      }
    }
  }
`;

export const GroupedAvatar = styled.div`
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;

  &:last-child {
    margin-right: 15px;
  }

  .user-avatar {
    border: 1px solid rgba(255, 255, 255, 0.25);
    height: 20px;
    width: 20px;
  }
  .user-avatar:not(:first-child) {
    margin-left: -10px;
  }
`;

export const StyledNoConnection = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #000000;
`;

import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { RangeSlider, SliderControlButton } from './styles';

export default function LutsControls({ selectedLuts, updateLuts, lutsValues }) {
  let selectedLutsValue =
    selectedLuts !== 'temperature'
      ? Number(lutsValues[selectedLuts].value.split('%')[0])
      : lutsValues[selectedLuts].value;

  const currentValue = () => {
    if (lutsValues[selectedLuts].value === '') {
      return (lutsValues[selectedLuts].min + lutsValues[selectedLuts].max) / 2;
    } else {
      return selectedLutsValue;
    }
  };

  const handleOnChange = (value) => {
    updateLuts((luts) => ({
      ...luts,
      [selectedLuts]: {
        ...luts[selectedLuts],
        value: selectedLuts !== 'temperature' ? `${value}%` : value,
      },
    }));
  };

  const increaseValue = () => {
    selectedLutsValue = selectedLutsValue + 1;
    handleOnChange(selectedLutsValue);
  };

  const decreaseValue = () => {
    selectedLutsValue = selectedLutsValue - 1;
    handleOnChange(selectedLutsValue);
  };

  return (
    <RangeSlider>
      <SliderControlButton onClick={decreaseValue}>-</SliderControlButton>
      <Slider
        value={currentValue()}
        onChange={handleOnChange}
        min={lutsValues[selectedLuts].min}
        max={lutsValues[selectedLuts].max}
        step={0.1}
        draggableTrack
      />
      <SliderControlButton onClick={increaseValue}>+</SliderControlButton>
    </RangeSlider>
  );
}

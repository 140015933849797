import styled from 'styled-components';

export const SliderControlButton = styled.button`
  margin: 0 10px;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  color: #fff;
`;

export const RangeSlider = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 320px;
`;

import { useDispatch, useSelector } from 'react-redux';
import { useSelectedChatName } from '../features/messages/selected-chat-utils';
import { startGroupVideoCallPublisher } from '../features/video/group-video-slice';

function useGroupVideoCallHelpers({ projectId, requestPeerId, setShouldDisplayVideo }) {
  const dispatch = useDispatch();
  const selectedChatName = useSelectedChatName();

  const { isIncomingCall: isIncomingGroupCallInvite, session: groupCallSession } = useSelector(
    (state) => state.groupVideoCall
  );

  const handleGroupVideoCall = (camera, microphone, isJoiningOngoingCall = false) => {
    // starting a call from the join/start call modal
    if (!isIncomingGroupCallInvite && !groupCallSession && !isJoiningOngoingCall) {
      dispatch(
        startGroupVideoCallPublisher({
          projectId,
          inviteeId: requestPeerId,
          groupName: selectedChatName,
          camera,
          microphone,
          isAcceptingInvite: false,
          shouldShowIncomingCallModal: true,
          shouldSendInvite: true,
          shouldPublishAudio: true,
        })
      );
    }
    if (isIncomingGroupCallInvite || isJoiningOngoingCall) {
      dispatch(
        startGroupVideoCallPublisher({
          projectId,
          inviteeId: null,
          groupName: null,
          camera,
          microphone,
          isAcceptingInvite: true,
          shouldShowIncomingCallModal: false,
          shouldSendInvite: false,
          shouldPublishAudio: true,
        })
      );
    }
    setShouldDisplayVideo(true);
  };

  return {
    handleGroupVideoCall,
  };
}

export default useGroupVideoCallHelpers;

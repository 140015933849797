import Drawer from 'rc-drawer';
import 'rc-drawer/assets/index.css';
import styled from 'styled-components';
import { CloseIcon } from '../ui/iconography';

export function SideDrawer({ style = {}, children, ...props }) {
  return (
    <Drawer {...props} handler={false} level={null} maskClosable>
      <ModalCloseWrapper role="button" onClick={props.onClose}>
        <CloseIcon />
      </ModalCloseWrapper>
      {children}
    </Drawer>
  );
}

const ModalCloseWrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  width: 20px;
  height: 20px;
  background-size: cover;
  cursor: pointer;
`;

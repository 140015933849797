import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import backHttpClient from '../../app/backend-http-client';
import { setToken } from './auth-slice';
import OffsetBlackLogo from '../../images/offset_black_logo.svg';
import { PrimaryButton, InputLabel, TextInput } from '../ui/inputs';
import { AnchorLink, ErrorText } from '../ui/texts';
import { ErrorIcon } from '../ui/iconography';

export default function LoginPage() {
  const { token, expiresIn } = useSelector((state) => state.auth);
  const isAuthenticated = !!token && expiresIn > Date.now();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const handleEmailChange = (event) => setEmail(event.target.value.trim());

  const [password, setPassword] = useState('');
  const handlePasswordChange = (event) => setPassword(event.target.value);

  const [isLogging, setLogging] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  if (isAuthenticated) {
    return <Redirect to={{ pathname: '/projects', state: { from: 'login' } }} />;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLogging(true);
    setErrorMessage('');

    try {
      await new Promise((resolve) => setTimeout(resolve, 900));
      const response = await backHttpClient.post('/auth/token', {
        email,
        password,
      });
      dispatch(setToken(response.data));
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Unknown error while logging in, please contact support team.');
        console.error('Error on singing in.', error);
      }
    } finally {
      setLogging(false);
      setPassword('');
    }
  };

  return (
    <Main>
      <AsideFancySection>
        <img src={OffsetBlackLogo} height="42" alt="Offset" />
      </AsideFancySection>
      <LoginSection>
        <h2>Welcome Back</h2>
        {errorMessage && (
          <p>
            <ErrorIcon /> <ErrorText>{errorMessage}</ErrorText>
          </p>
        )}
        <LoginForm onSubmit={handleSubmit}>
          <InputLabel>
            E-mail
            <TextInput
              name="email"
              type="email"
              minLength="3"
              value={email}
              onChange={handleEmailChange}
              disabled={isLogging}
              required
            />
          </InputLabel>
          {isLogging ? (
            <p>Signing in...</p>
          ) : (
            <InputLabel>
              Password
              <TextInput
                name="password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                minLength="3"
                required
              />
            </InputLabel>
          )}
          <ButtonsGroup>
            <PrimaryButton type="submit" disabled={isLogging} fullWidth>
              Login
            </PrimaryButton>
            <AnchorLink to="/forgot-password">forgot password</AnchorLink>
          </ButtonsGroup>
        </LoginForm>
      </LoginSection>
    </Main>
  );
}

const AsideFancySection = styled.aside`
  background: #9d8c72;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginSection = styled.section`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10%;
  box-sizing: border-box;

  ${InputLabel} {
    padding-bottom: 30px;
  }
`;

const Main = styled.main`
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;

    ${AsideFancySection} {
      background: none;
      width: 100%;
      height: auto;
    }

    ${LoginSection} {
      width: 100%;
    }
  }
`;

const LoginForm = styled.form`
  width: 100%;
`;

const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${PrimaryButton} {
    margin-bottom: 13px;
  }
`;

import { makeReducerAssets } from 'resource-toolkit';
import backHttpClient from '../../app/backend-http-client';

const resource = makeReducerAssets({
  name: 'groups',
  idKey: 'id',
  gateway: {
    fetchMany: async () => {
      return (await backHttpClient.get('/groups/')).data.items;
    },
    create: async (payload) => {
      return (await backHttpClient.post('/groups/', payload)).data.item;
    },
  },
});

export const groupsActions = {
  ...resource.actionThunks,
  appendGroupParticipations:
    (groupId, userIds = []) =>
    async (dispatch) => {
      dispatch(resource.plainActions.setUpdating(groupId));
      try {
        await Promise.allSettled(
          userIds.map((userId) => backHttpClient.post(`/groups/${groupId}/participant/${userId}`))
        );
      } finally {
        dispatch(resource.plainActions.setUpdated(groupId, { id: groupId }));
        dispatch(groupsActions.readAll());
      }
    },
};

export default resource.reducer;

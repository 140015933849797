import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import AttachmentsList from './AttachmentsList';
import NoiseBgHelper from '../../images/noise_bg_helper.png';
import AttachmentViewModal from './AttachmentViewModal';
import {
  toggleChatViewTypePerChannel,
  resetPreviewAttachment,
  setPreviewAttachment,
  toggleAttachmentPreview,
} from './messages-slice';
import { StyledBroadcastHeader } from '../main/styles';
import { useSelectedChatName } from './selected-chat-utils';
import { ChannelIcon } from '../ui/inputs';
import { ChannelIconUsers } from '../main/Users';
import { ChannelIconGroup } from '../main/Groups';
import AttachmentDownloadButton from './AttachmentDownloadButton';
import DocumentIcon from '../../images/DocumentIcon.svg';

function MessagesList(props) {
  const {
    channel: activeChannel,
    isLoadingMessage,
    items: rawMessageItems,
    chatViewTypeIsFilesByChannel,
    isOpenAttachmentPreviewModal,
    previewAttachment,
  } = useSelector((state) => state.messages);
  const isActive = !!activeChannel;

  const dispatch = useDispatch();
  const selectedChatName = useSelectedChatName(false);

  if (!isActive) {
    return <NoList $collapsed={props.$collapsed}>Select a chat on the left to join.</NoList>;
  }

  if (isLoadingMessage) {
    return <NoList $collapsed={props.$collapsed}>Loading Messages... ⚡️ </NoList>;
  }

  const messages = rawMessageItems?.[activeChannel]?.map((message) => ({
    ...message,
    createdAt: new Date(message.createdAt),
  }));

  if (!isLoadingMessage && !messages?.length) {
    return (
      <NoList $collapsed={props.$collapsed}>
        No messages yet. Send something to interact with the crew! 😉
      </NoList>
    );
  }

  const attachmentMessages = messages.filter((singleMessage) => singleMessage.attachment?.url);
  const MessagesDisplay = () =>
    messages.map((message) => (
      <MessageListItem key={message.uid} {...message} project={props.project} />
    ));

  const AttachmentHeadingText = ({ children }) => {
    let icon;
    if (activeChannel.includes('user')) {
      icon = (
        <ChannelIconUsers>
          <span>
            <i className="fa fa-user"></i>
          </span>
        </ChannelIconUsers>
      );
    }
    if (activeChannel.includes('channel')) {
      icon = (
        <ChannelIcon>
          <span>CH</span>
        </ChannelIcon>
      );
    }
    if (activeChannel.includes('group')) {
      icon = (
        <ChannelIconGroup>
          <span>
            <i className="fa fa-users"></i>
          </span>
        </ChannelIconGroup>
      );
    }

    return (
      <StyledHeadingText>
        {icon}
        <div className="children">{children}</div>
      </StyledHeadingText>
    );
  };

  const closeAttachmentViewModal = () => {
    dispatch(toggleChatViewTypePerChannel(activeChannel));
  };

  const closePreviewModal = () => {
    dispatch(resetPreviewAttachment());
    dispatch(toggleAttachmentPreview());
  };

  return (
    <>
      <StylingMessagesList {...props}>
        <MessagesDisplay />
      </StylingMessagesList>
      <AttachmentViewModal
        isOpen={chatViewTypeIsFilesByChannel[activeChannel]}
        close={closeAttachmentViewModal}
      >
        <AttachmentHeader>
          <AttachmentHeadingText>
            {selectedChatName.replace(/,/g, '/')} <br />
            <span className="title">Attachments</span>
          </AttachmentHeadingText>
          <button onClick={closeAttachmentViewModal}>
            <i className="far fa-times-circle" />
          </button>
        </AttachmentHeader>
        <AttachmentListWrapper>
          <AttachmentsList users={props.project.users} messages={attachmentMessages} />
        </AttachmentListWrapper>
      </AttachmentViewModal>

      {isOpenAttachmentPreviewModal && (
        <AttachmentViewModal isOpen={isOpenAttachmentPreviewModal} close={closePreviewModal}>
          <div style={{ position: 'relative' }}>
            <AttachmentHeader>
              <AttachmentHeadingText>
                <span>
                  {previewAttachment.name} &nbsp; {previewAttachment.sentAt} <br />
                  <span className="title">By {previewAttachment.author}</span>
                </span>
              </AttachmentHeadingText>
              <button onClick={closePreviewModal}>
                <i className="far fa-times-circle" />
              </button>
            </AttachmentHeader>
            <img
              width="100%"
              height="auto"
              src={previewAttachment.url}
              style={{ maxHeight: '480px' }}
            />
            <StyledAttachmentDownloadButton>
              <AttachmentDownloadButton
                attachmentUrl={previewAttachment.url}
                attachmentName={previewAttachment.name}
              />
            </StyledAttachmentDownloadButton>
          </div>
        </AttachmentViewModal>
      )}
    </>
  );
}

const AttachmentPreview = ({ messageAttachment, text, author, sentAt }) => {
  const dispatch = useDispatch();

  const attachmentLink = messageAttachment?.type?.includes('image')
    ? messageAttachment.url
    : DocumentIcon;

  const previewImage = () => {
    dispatch(setPreviewAttachment({ ...messageAttachment, url: attachmentLink, author, sentAt }));
    dispatch(toggleAttachmentPreview());
  };

  return (
    <>
      <ImagePreview
        src={attachmentLink}
        alt={messageAttachment.name || 'File Attachment'}
        onClick={previewImage}
      />
      <AttachmentDownloadButton
        attachmentUrl={messageAttachment.url}
        attachmentName={messageAttachment.name}
      />
    </>
  );
};

function MessageListItem(props) {
  const ref = useRef();

  const { users = [] } = props.project;

  const usersWithImage = users.map((u) => {
    if (!u.profile || !u.profile[0]?.profilePictureUrl) {
      return {
        ...u,
        picture: `https://ui-avatars.com/api/?name=${encodeURIComponent(
          u?.profile[0]?.name ?? u.name
        )}`,
      };
    }
    return {
      ...u,
      picture: u.profile[0].profilePictureUrl,
    };
  });
  const { authorId, text, createdAt, attachment } = props;

  const author = {
    id: -1,
    name: 'Unknown',
    picture: '',
    ...(usersWithImage.find((u) => u.id === authorId) || {}),
  };

  useEffect(() => {
    const scroller = setTimeout(
      () => ref.current && ref.current.scrollIntoView({ behavior: 'smooth' }),
      500
    );
    return () => clearTimeout(scroller);
  }, []);

  const sentAt = createdAt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  return (
    <StylingMessagesListItem ref={ref}>
      <AuthorPicture
        src={author.picture}
        width="40"
        height="40"
        alt={`Picture of ${author.name}`}
      />
      <MessageContent>
        <MessageNameWrap>
          <AuthorName>{author?.profile?.[0]?.name ?? author.name}</AuthorName>{' '}
          <MessageTime datetime={createdAt.toISOString()}>{sentAt}</MessageTime>
        </MessageNameWrap>
        <MessageText>
          {attachment?.url ? (
            <StyledAttachmentPreview
              text={text}
              messageAttachment={attachment}
              author={author?.profile?.[0]?.name ?? author.name}
              sentAt={sentAt}
            />
          ) : (
            text
          )}
        </MessageText>
      </MessageContent>
    </StylingMessagesListItem>
  );
}

export default MessagesList;

const StyledAttachmentPreview = styled(AttachmentPreview)`
  position: relative;
`;

const StylingMessagesList = styled.ul`
  display: ${(props) => (props.$collapsed ? 'none' : 'flex')};
  flex-shrink: ${(props) => (props.$collapsed ? 'initial' : '0')};
  flex-grow: ${(props) => (props.$collapsed ? 'initial' : '1')};
  flex-direction: column;
  background-color: #fff;
  margin: 0;
  list-style: none;
  padding: 15px 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: ${(props) => (props.$fullHeight ? '100%' : 'auto')};
  flex: 1;
  background-image: url(${NoiseBgHelper});
  background-blend-mode: overlay;
  background-color: #262626;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 75%);

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const NoList = styled.div`
  display: ${(props) => (props.$collapsed ? 'none' : 'flex')};
  background-color: #fff;
  margin: 0;
  padding: 0 20px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-image: url(${NoiseBgHelper});
  background-blend-mode: overlay;
  background-color: #262626;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 75%);

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const AuthorPicture = styled.img`
  border-radius: 100%;
  user-select: none;
`;

export const AuthorName = styled.strong`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  cursor: pointer;
`;

export const MessageTime = styled.time`
  font-size: 12px;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.25);
`;

const MessageText = styled.span`
  position: relative;
  display: block;
  color: rgba(255, 255, 255, 0.75);

  a {
    color: rgba(255, 255, 255, 0.75);
  }
  button {
    position: absolute;
    left: 130px;
    bottom: 10px;

    i {
      color: #333;
      background: #f5ebc0;
      border-radius: 50%;
      padding: 3px;
      box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(255, 255, 255, 0.5),
        inset 0px 1.5px 0.5px rgba(0, 0, 0, 0.5);
    }
  }
`;

export const MessageContent = styled.span`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  flex: 1 1 0;
  min-width: 0;
`;

export const MessageNameWrap = styled.div`
  display: flex;
  align-items: center;
  time {
    margin-left: 8px;
  }
`;

const ImagePreview = styled.img`
  max-height: 200px;
  max-width: 200px;
  border-radius: 4px;
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }
`;

export const StylingMessagesListItem = styled.li`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;

  img {
    flex-shrink: 0;
  }
`;

const AttachmentHeader = styled(StyledBroadcastHeader)`
  font-size: 16px;
  font-weight: 400;
  padding-left: 25px;
  justify-content: initial;
  background-color: rgba(0, 0, 0, 0.25);

  button {
    margin-left: auto;
    margin-right: 25px;
    border: none;
    background: none;
    color: #ffffff;
    height: 20px;
    padding: 0;
    cursor: pointer;
    i {
      font-size: 20px;
    }
  }
  .title {
    font-size: 12px;
  }
`;

const AttachmentListWrapper = styled.div`
  overflow: auto;
  height: 100%;
`;

const StyledAttachmentDownloadButton = styled.div`
  button {
    position: absolute;
    top: 500px;
    right: 15px;
    i {
      color: #333;
      background: #f5ebc0;
      border-radius: 50%;
      padding: 8px;
      box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(255, 255, 255, 0.5),
        inset 0px 1.5px 0.5px rgba(0, 0, 0, 0.5);
    }
  }
`;

const StyledHeadingText = styled.div`
  display: flex;
  .children {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

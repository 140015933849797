import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../ui/modal';
import { ModalMessageDisabled } from '../projects/project-detail/styles';
import { ModalButtonsGroup, PrimaryButton } from '../ui/inputs';

function FileSendingNotificationModal({ isOpen, handleClose }) {
  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <ModalMessageDisabled>We are still processing the file you uploaded</ModalMessageDisabled>
      <ModalButtonsGroup>
        <PrimaryButton type="button" onClick={handleClose}>
          Continue
        </PrimaryButton>
      </ModalButtonsGroup>
    </Modal>
  );
}

FileSendingNotificationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default FileSendingNotificationModal;

import { makeReducerAssets } from 'resource-toolkit';
import backHttpClient from '../../app/backend-http-client';

const resource = makeReducerAssets({
  name: 'channels',
  idKey: 'id',
  gateway: {
    fetchMany: async () => {
      return (await backHttpClient.get('/channels/')).data.items;
    },
  },
});

export const channelsActions = resource.actionThunks;

export default resource.reducer;

import { TOPIC_USER } from '../calls/calls-constants';
import {
  ChannelsNavLink,
  ChannelItemLeft,
  ChannelIcon,
  ChannelIndicatorWrapper,
  ChannelIndicator,
} from '../ui/inputs';
import { useSelector } from 'react-redux';
import { makeTopicIdForUsersFn } from '../messages/selected-chat-utils';

import styled from 'styled-components';
export function Users({
  activateTopicFn,
  isItemActive,
  project,
  unreadMessagesCount,
  openFileSendingPrompt,
}) {
  const { updatedProjectInfo } = useSelector((state) => state.main);
  const { isSendingMessage } = useSelector((state) => state.messages);
  const { users = [] } = { ...project, ...updatedProjectInfo };
  const { userId } = useSelector((state) => state.auth);
  const { invitedUser, isWaitingForCall } = useSelector((state) => state.videoCall);
  const isInCall = useSelector((state) => !!state.calls.session);

  const handleUsersClick = (event, _userId) => {
    event.preventDefault();
    event.stopPropagation();
    if (isSendingMessage) {
      openFileSendingPrompt();
      return;
    }
    activateTopicFn(TOPIC_USER, _userId)();
  };

  return users.map((user) => {
    return (
      <ChannelsNavLink
        key={user.id}
        to="#"
        onClick={(event) => handleUsersClick(event, user.id)}
        $isActive={isItemActive(TOPIC_USER, user.id)}
      >
        <ChannelIconUsers>
          <span>
            <i className="fa fa-user"></i>
          </span>
        </ChannelIconUsers>

        <ChannelItemLeft
          unread={
            unreadMessagesCount &&
            unreadMessagesCount[
              `${TOPIC_USER}-${makeTopicIdForUsersFn(project.id, user.id)(userId)}`
            ] > 0
          }
        >
          {user?.profile[0]?.name ?? user.name} {user.id === userId && <em>(You)</em>}
        </ChannelItemLeft>

        <ChannelIndicatorWrapper>
          {isWaitingForCall && invitedUser === user.id && (
            <ChannelIndicator className="white ringing" />
          )}
          {isWaitingForCall && <i className="fal fa-xmark" />}
          {isInCall && <ChannelIndicator />}
        </ChannelIndicatorWrapper>
      </ChannelsNavLink>
    );
  });
}
export const ChannelIconUsers = styled(ChannelIcon)`
  span {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(128, 128, 128, 0.2) 100%),
      #f5ebc0;
  }
`;
